export enum BtnType {
  normal,
  addCart,
  buyNow,
  sure,
  book,
  booked,
  offState,
  offStorage,
  exceeds,
}
