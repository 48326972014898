<template>
  <div class="goods-item-card" :class="{ gap: gap }">
    <div
      v-if="!isStop"
      class="goods-item-container"
      :class="{ 'box-container': props.box, row: props.layout === 'Horizontal' }"
      :style="{ backgroundColor: props.dark ? bg : '#ffffff' }"
      @click.stop="onClick"
    >
      <NewkiImage
        :key="String(props.imgSrc) || useNanoid(8)"
        :src="props.imgSrc"
        :width="props.imgSize"
        :height="props.imgSize"
        :block="imgBlock"
        :radius="4"
      />

      <div class="goods-item-content">
        <slot name="marketingProgram"></slot>
        <div class="text-content">
          <van-text-ellipsis
            class="goods-item-name"
            :style="{ color: props.dark ? textAssist : textDefault }"
            :content="goodsName"
            :rows="nameLine"
          />
          <slot name="spec">
            <van-text-ellipsis v-if="goodsSpec" class="goods-item-spec" :content="goodsSpec" rows="1" />
          </slot>
        </div>
        <div class="goods-price-container" :class="{ 'align-top': showMoreDiscount }">
          <div class="price-box" :class="{ 'price-box-horizontal': layout === 'Horizontal' }" v-if="showAllPrice">
            <div class="price-content" :class="{ 'price-discount-content': showMoreDiscount || tagList.length }">
              <VipPrice data-tips="品友价" v-if="showVipPrice" :price="vipPrice" />
              <NewkiPrice
                data-tips="红色正常价"
                :price="price || 0"
                :price-size="17"
                :currey-size="17"
                :bold="true"
                v-else
              />
              <div data-tips="原价" class="price-origin-box" v-if="showRetailPrice">
                <span class="price-origin">
                  {{ vipRetailPrice && hasPromotion ? t('common.vipPrice') : t('promotion.originPrice') }}
                </span>
                <NewkiPrice
                  :price="vipRetailPrice && hasPromotion ? vipPrice : retailPrice"
                  :price-size="13"
                  :currey-size="13"
                  :color="textAssist"
                />
              </div>
            </div>
          </div>
          <slot name="action"></slot>
        </div>
        <NewkiBetterPriceTag data-tips="获取更优价" v-if="showMoreDiscount && !onlyPrice" />
        <div v-if="tagList.length" class="tag-container">
          <NewkiTag v-for="(item, index) in tagList" class="tag-item" :key="index" :text="item" type="promotion" />
        </div>
      </div>
      <slot name="goodsExt"></slot>
    </div>
    <div
      v-else
      class="goods-item-container"
      :class="{ 'box-container': props.box, row: props.layout === 'Horizontal' }"
      :style="{ backgroundColor: props.dark ? bg : '#ffffff' }"
    >
      <NewkiImage :src="props.imgSrc" :width="props.imgSize" :height="props.imgSize" :block="imgBlock" :radius="4" />
      <div class="goods-item-content">
        <slot name="marketingProgram"></slot>
        <div class="text-content">
          <van-text-ellipsis
            class="goods-item-name"
            :style="{ color: props.dark ? textAssist : textDefault }"
            :content="goodsName"
            :rows="nameLine"
          />
          <slot name="spec">
            <van-text-ellipsis v-if="goodsSpec" class="goods-item-spec" :content="goodsSpec" rows="1" />
          </slot>
        </div>
        <div class="goods-price-container" :class="{ 'align-top': showMoreDiscount }">
          <div class="price-box" :class="{ 'price-box-horizontal': layout === 'Horizontal' }" v-if="showAllPrice">
            <div class="price-content" :class="{ 'price-discount-content': showMoreDiscount || tagList.length }">
              <VipPrice data-tips="品友价" v-if="showVipPrice" :price="vipPrice" />
              <NewkiPrice
                data-tips="红色正常价"
                :price="price || 0"
                :price-size="17"
                :currey-size="17"
                :bold="true"
                v-else
              />
              <div data-tips="原价" class="price-origin-box" v-if="showRetailPrice">
                <span class="price-origin">
                  {{ vipRetailPrice && hasPromotion ? t('common.vipPrice') : t('promotion.originPrice') }}
                </span>
                <NewkiPrice
                  :price="vipRetailPrice && hasPromotion ? vipPrice : retailPrice"
                  :price-size="13"
                  :currey-size="13"
                  :color="textAssist"
                />
              </div>
            </div>
          </div>
          <slot name="action"></slot>
        </div>
        <NewkiBetterPriceTag data-tips="获取更优价" v-if="showMoreDiscount && !onlyPrice" />
        <div v-if="tagList.length" class="tag-container">
          <NewkiTag v-for="(item, index) in tagList" class="tag-item" :key="index" :text="item" type="promotion" />
        </div>
      </div>
      <slot name="goodsExt"></slot>
    </div>
    <slot name="ext"></slot>
  </div>
</template>

<script setup lang="ts">
// todo 待優化 @click.stop部分
import NewkiBetterPriceTag from '@/components/NewkiBetterPriceTag.vue'
import { computed } from 'vue'
import { useColorsStore } from '~/store/colors'
import { useMemberStore } from '~/store/member'

const props = defineProps({
  gap: {
    type: Boolean,
    required: false,
    default: true,
  },
  // 商品圖片
  imgSrc: {
    required: true,
  },
  imgSize: {
    type: Number,
    required: false,
    default: 80,
  },
  imgBlock: {
    type: Boolean,
    required: false,
    default: false,
  },
  // 商品名稱
  goodsName: {
    required: true,
  },
  // 商品規格
  goodsSpec: {
    required: false,
    default: '',
  },
  // 購買價
  price: {
    type: [Number, String],
    required: false,
    default: 0,
  },
  // 零售價
  retailPrice: {
    type: [Number, String],
    required: false,
    default: 0,
  },
  // 會員價
  vipPrice: {
    type: [Number, String],
    required: false,
    default: 0,
  },
  // 主題模式
  dark: {
    type: Boolean,
    required: false,
    default: false,
  },
  // 優惠標籤
  tagList: {
    type: Array,
    required: false,
    default: () => [],
  },
  box: {
    type: Boolean,
    required: false,
    default: false,
  },
  showOriginPrice: {
    type: Boolean,
    required: false,
    default: false,
  },
  showVipPriceTag: {
    type: Boolean,
    required: false,
    default: true,
  },
  showVipRetailPrice: {
    type: Boolean,
    required: false,
    default: false,
  },
  nameLine: {
    type: Number,
    required: false,
    default: 2,
  },
  // 是否加購會員，用於訂單確認頁臨時會員顯示
  isAddVip: {
    type: Boolean,
    required: false,
    default: false,
  },
  // 是否顯示價格
  showAllPrice: {
    type: Boolean,
    required: false,
    default: true,
  },
  // 排版模式
  layout: {
    type: String,
    required: false,
    default: 'Vertical', // Vertical、Horizontal
  },
  onlyPrice: {
    type: Boolean,
    required: false,
    default: false,
  },
  isStop: {
    type: Boolean,
    required: false,
    default: false,
  },
})
const emit = defineEmits(['onClick'])
const { textAssist, textDefault, bg } = storeToRefs(useColorsStore())
const { memberInfo } = storeToRefs(useMemberStore())
const isVip = computed(() => {
  return memberInfo.value.payMember || props.isAddVip
})

// 顯示會員價
const showVipPrice = computed(() => {
  // 存在會員價,是會員
  return props.vipPrice && isVip.value && props.showVipPriceTag && props.vipPrice !== props.retailPrice
})

// 普通會員，有會員價顯示【獲取更優價】
const showMoreDiscount = computed(() => {
  return !isVip.value && props.vipPrice && props.vipPrice !== props.retailPrice
})

// 顯示原價
const showRetailPrice = computed(() => {
  return props.price !== props.retailPrice && props.showOriginPrice
})

const vipRetailPrice = computed(() => {
  return props.showVipRetailPrice && props.vipPrice && isVip.value
})

// 是否有促销
const hasPromotion = computed(() => {
  if (props.vipPrice && props.vipPrice !== props.retailPrice) {
    return props.price !== props.vipPrice
  } else {
    return props.price !== props.retailPrice
  }
})

const { t } = useI18n()

function onClick() {
  emit('onClick')
}
</script>

<style scoped lang="scss">
// 最外层容器
.goods-item-card {
  width: 100%;

  & + .goods-item-card {
    margin-top: 24px;
  }

  .box-container {
    box-shadow: $shadow;
    border-radius: 6px;
    padding: 13px 10px;
    border: 1px solid transparent;
  }

  .goods-item-container {
    display: flex;
    box-sizing: border-box;
    position: relative;
    @include cursor();

    &.row {
      @include flex(column, flex-start, flex-start);

      .van-image {
        width: 100%;
        height: auto;
      }

      .goods-item-content {
        @include flex(column, flex-start, flex-start);
        gap: 4px;
        width: 100%;
        margin: 8px 0 0 0;

        .text-content {
          width: 100%;
          min-height: 54px;
        }

        .vip-price-box {
          display: flex;
        }

        .goods-price-container {
          width: 100%;
          margin-top: 4px;
        }
      }
    }

    .van-image {
      flex-shrink: 0;
    }

    .goods-item-content {
      margin-left: 8px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      flex-grow: 1;

      .goods-item-name {
        @extend .text-14;
        color: $text-color-default;
        @include line(2);
      }

      .goods-item-spec {
        margin-top: 4px;
        color: $text-color-assist;
        @extend .text-tips;
      }

      .text-content {
        margin-bottom: 4px;
      }

      .goods-price-container {
        // display: flex;
        // justify-content: space-between;
        // align-items: center;
        @include flex(row, flex-end, flex-start);

        .price-box {
          @include flex(column, center, flex-start);
          flex: 1;
          min-height: 25px;

          .price-content {
            @include flex(row, flex-start, center);
            flex-wrap: wrap;
            width: 100%;
            gap: 8px;

            &.price-box-horizontal {
              .price-content {
                flex-wrap: wrap;
              }
            }

            .price-origin-box {
              @include flex(row, flex-start, center);
              flex: 1;
              margin: 0;
              .price-origin {
                min-height: 18px;
                padding-right: 4px;
                font-size: $text-sm;
                color: $text-color-assist;
              }
              .newki-price-container {
                @include flex(row, flex-start, center);
                min-height: 18px;
              }
            }
          }
        }
      }

      .align-top {
        align-items: flex-start;
      }
    }
  }

  .tag-container {
    display: flex;
    flex-wrap: wrap;
    margin-top: 8px;
    gap: 8px 10px;
    .tag-item {
      // margin-top: 8px;
      // margin-right: 10px;
    }
  }
}

.discount-tag-container {
  margin-top: 6px;
  width: fit-content;
}
</style>
