import { $axios } from '../index'

/**
 * @description: 家品历
 */
export const getFamilyHistory = async () => {
  return $axios()
    .get('/goods/app/postContent/familyHistory')
    .then((res: any) => {
      if (res.success) {
        return res.data
      }
    })
    .catch(() => {
      return null
    })
}

/**
 * @description: 為你推薦
 */
export const getRecommendYou = async () => {
  return $axios()
    .get('/search/app/goods/sku/recommendYou')
    .then((res: any) => {
      if (res.success) {
        return res.data
      }
    })
    .catch(() => {
      return null
    })
}
