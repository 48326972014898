/**
 * 異步加載js文件
 *
 * @param src
 * @param id - 用來檢查是否已經加載過
 * @param delay - 如果異步加載的文件還有異步，可以手動指定延遲
 * @returns {Promise<unknown>|Promise<void>}
 */
export const fetchScript = (src: string, id: string, delay: number = 0) => {
  if (typeof window === 'undefined') {
    return Promise.resolve()
  }

  if (document.querySelector(`#${id}`)) {
    return Promise.resolve()
  }

  return new Promise(function (resolve, reject) {
    const script = document.createElement('script')
    script.type = 'text/javascript'
    script.src = src
    script.id = id
    // @ts-ignore
    script.crossorigin = 'anonymous'
    script.addEventListener('load', () => setTimeout(resolve, delay), false)
    script.addEventListener('error', () => reject(new Error('fetch script error')), false)
    document.getElementsByTagName('head')[0].appendChild(script)
  })
}
