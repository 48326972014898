import { ref, watch, nextTick } from 'vue'
import { useSystemStore } from '~/store/system'
import { useMemberStore } from '~/store/member'
import { useCurrentSelectedAreaCodeStore } from '~/store/currentSelectedAreaCode'

export const usePhoneValidator = () => {
  const { areaCodeMap } = storeToRefs(useSystemStore())
  const { areaCode } = storeToRefs(useMemberStore())
  const { t } = useI18n()

  const defaultAreaCode: any = ref(areaCode.value)

  const { isUserSelected } = storeToRefs(useCurrentSelectedAreaCodeStore())

  /*
    測試數據
    853 69000049 89876543 澳門 
    852 61008613 香港 43504350
    86  中國 15015957222
  */

  // 將 areaCodeMap 轉換為 areaArray
  const areaArray: any = ref([])

  // 校驗通過的 code
  const okAreaCode: any = ref('')

  const sortByDefault = () => {
    // 1、重置數據
    areaArray.value = []

    // 2、areaCodeMap 轉數組
    Object.entries(areaCodeMap.value).forEach(([key, value]) => {
      areaArray.value.push({
        code: key,
        ...value,
      })
    })

    // 3、數組排序 sort：isDefault > 853、852 > 86
    areaArray.value.sort((a: any, b: any) => {
      if (a.isDefault === '1') {
        return b.isDefault === '1' ? 0 : -1
      }
      if (b.isDefault === '1') {
        return 1
      }
      return Number(b.code) - Number(a.code)
    })
  }

  const sortBySelectedCode = () => {
    // 1、重置數據
    areaArray.value = []

    // 2、areaCodeMap 轉數組
    Object.entries(areaCodeMap.value).forEach(([key, value]) => {
      areaArray.value.push({
        code: key,
        ...value,
      })
    })

    // 3、數組排序 sort： code > isDefault > 853、852 > 86
    areaArray.value.sort((a: any, b: any) => {
      // 首先比较 code，如果与 primaryCode 相等则排在前面
      if (Number(a.code) === Number(defaultAreaCode.value)) return -1
      if (Number(b.code) === Number(defaultAreaCode.value)) return 1
      // 如果 code 不相等，则比较它们的值
      if (Number(a.code) < Number(b.code)) return -1
      if (Number(a.code) > Number(b.code)) return 1

      // 如果 code 相等，则比较 isDefault（注意 isDefault 是字符串，需要转换为数字比较）
      return Number(b.isDefault) - Number(a.isDefault)
    })
  }

  // 驗證函數
  const validatorPhoneNumber = (val: any, rule: any): Promise<{ flag: boolean; okAreaCode: string }> => {
    isUserSelected.value ? sortBySelectedCode() : sortByDefault()

    return new Promise((resolve, reject) => {
      if (!val) {
        rule.message = t('login.inputPhoneNumber')
        return resolve({
          flag: false,
          okAreaCode: '',
        })
      }

      //3、按順序檢測，只要有一個 true 那就 return true
      const flag = areaArray.value.some((item: any, index: number, arr: any) => {
        const pattern: RegExp = new RegExp(item.rule)
        if (pattern.test(val)) {
          console.log(item.name + ':' + item.code)
          okAreaCode.value = item.code
        }
        return pattern.test(val)
      })
      if (!flag) {
        rule.message = t('login.inputRightPhoneNumber')
      }
      return resolve({
        flag: flag,
        okAreaCode: okAreaCode.value,
      })
    })
  }

  watch(
    areaCode,
    async val => {
      if (isUserSelected.value) {
        defaultAreaCode.value = val
      }
      await nextTick()
      isUserSelected.value ? sortBySelectedCode() : sortByDefault()
    },
    { immediate: true }
  )

  return {
    areaArray,
    okAreaCode,
    defaultAreaCode,
    validatorPhoneNumber,
  }
}
