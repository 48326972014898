import { $axios } from '../index'

/**
 * @description: 獲取文章檔案內容
 */
export const apiGetArticle = (data: any) => {
  return $axios()
    .post(`:support/app/articleContent/queryList`, Object.assign(data, { status: ['09'] }))
    .then((res: any) => {
      return res.data
    })
    .catch(() => {
      return null
    })
}
