import { $axios } from '../index'
import { useLocalStorage } from '@vueuse/core'
import { encryption } from '~/composables/encryption'
import { cleanLoginInfo } from '~/composables/cleanLoginInfo'
import { routerPush } from '~/composables/routerPush'
import { setLoginInfo } from '~/composables/setLoginInfo'
import { useSetMemberInfo } from '~/composables/useSetMemberInfo'

/**
 * 登錄
 *
 * @param {Object} params
 * @param {string} params.areaCode 區號
 * @param {string} params.phone 手機號
 * @param {string} params.code 驗證碼
 * @param {string} params.password 密碼
 * @param {number} params.siteId 站點ID
 */
export const login = (params: object) => {
  const fromInviteeCode = useCookie('newki-url-query-ni').value || ''
  const utm = useCookie('newki-url-query-nu').value || ''
  const obj: any = { ...params, fromInviteeCode, utm }
  if (obj.password) {
    obj.password = encryption(obj.password)
  }
  return $axios()
    .post(`:mall/app/user/login`, obj)
    .then((res: any) => {
      if (res.success) {
        useLocalStorage('login-error', 0).value = 0
        return res.data
      } else {
        showFailToast(res.msg)
        useLocalStorage('login-error', res.data.num).value = res.data.num
        return null
      }
    })
    .catch(() => {
      return null
    })
}

/**
 * 找回密碼
 *
 * @param {Object} params
 * @param {string} params.areaCode 區號
 * @param {string} params.phone 手機號
 * @param {string} params.code 驗證碼
 * @param {string} params.password 密碼
 */

export const findPwd = (params: any) => {
  params.password = encryption(params.password)
  return $axios()
    .post(`:mall/app/user/findPwd`, params)
    .then((res: any) => {
      return res.data
    })
    .catch(() => {
      return null
    })
}

/**
 * @description: 獲取圖形驗證碼
 */
export const apiGetGraphicCode = () => {
  return $axios()
    .get(`:mall/app/user/graphicCode`)
    .then((res: any) => {
      return res.data
    })
    .catch(() => {
      return null
    })
}
/**
 * @description: 驗證圖形驗證碼
 */
export const apiCheckGraphicCode = (code: string, imageKey: string) => {
  return $axios()
    .formPost(`:mall/app/user/checkCode`, { code, imageKey })
    .then((res: any) => {
      return res.data
    })
    .catch(() => {
      return null
    })
}

/**
 * @description: 登出
 */
export const apiLogout = (token: string, refreshToken: string) => {
  return $axios()
    .post(`:mall/app/user/logout`, { token, refreshToken })
    .then((res: any) => {
      return res.data
    })
    .catch(() => {
      return null
    })
}

/**
 * @description: 设置密码
 */
export const apiSetPwd = (password: string, code?: string, oldPassword?: string) => {
  const pwd = encryption(password)
  let oldPwd = ''
  if (oldPassword) {
    oldPwd = encryption(oldPassword)
  }
  return $axios()
    .post(`:mall/app/user/setPwd`, { password: pwd, code, oldPassword: oldPwd })
    .then((res: any) => {
      if (res.success) {
        useLocalStorage('set-pwd-error', 0).value = 0
        return 'success'
      } else {
        showFailToast(res.msg)
        useLocalStorage('set-pwd-error', res.data.verifyErrorNum).value = res.data.verifyErrorNum
        return null
      }
    })
    .catch(() => {
      return null
    })
}

/**
 * @description: 刷新token
 */
export const refreshToken = () => {
  const token = useCookie('refresh_token').value
  return $axios()
  .formPost(`:mall/app/user/refresh`, { token })
    .then((res: any) => {
      if (res.success) {
        setLoginInfo(res.data)
        useSetMemberInfo()
        return true
      } else {
        cleanLoginInfo()
        return false
      }
    })
    .catch(() => {
      cleanLoginInfo()
      return false
    })
}

/**
 * @description: code微信授權換回來的code
 */
export const apiWechatLogin = (code: string) => {
  return $axios()
    .get(`:mall/app/user/wxLogin`, { params: { code, state: 1 } })
    .then((res: any) => {
      return res
    })
    .catch(() => {
      return null
    })
}

/**
 * @description: 获取 token 状态
 */
export const getTokenStatus = () => {
  return $axios()
    .post(`:mall/app/user/checkToken`)
    .then((res: any) => {
      return res.data
    })
    .catch(() => {
      return false
    })
}
