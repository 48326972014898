export enum Article {
  privacyAgreement = 'F001', // 隱私協議
  registerAgreement = 'F002', // 註冊協議
  priceExplain = 'F003', // 價格說明
  integralRule = 'F004', // 積分規則
  vipOpen = 'F005', // 付費會員開通
  vipRegisterAgreement = 'F006', // 付費會員用戶協議
  payGuide = 'F007', // 支付幫助
  transferGuide = 'F008', // 轉賬支付幫助
  newki = 'F009', // 關於新奇站
  vipOrderDiscount = 'F010', // 付費會員本單優惠
  vipDiscountAmount = 'F011', // 付費會員累計已省
  payServiceAgreement = 'F012', // 支付服務說明
}
