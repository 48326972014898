import debounce from 'lodash/debounce'

const _routerRefresh = async () => {
  const router = useRouter()
  const route = useRoute()
  const state = window.history.state
  const name = analyzeRouteName('index')

  if (state) {
    await router.go(0)
  } else {
    await router.push({
      name,
      query: {
        ni: route.query.ni,
        nu: route.query.nu,
      },
    })
  }
}

export const routerRefresh = debounce(_routerRefresh, 300, { leading: true })
