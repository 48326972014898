export const useScrollStatus = defineStore('scrollStatus', () => {
  // 是否正在滾動
  const isScrolling: Ref<Boolean> = ref(false)

  // 向上滾動
  const scrollUp: Ref<Boolean> = ref(false)

  // 向下滾動
  const scrollDown: Ref<Boolean> = ref(false)

  // 設置方向
  const scrollDirection: Ref<'Up' | 'Down' | null> = ref(null)

  function setScrollDirection(dire: 'Up' | 'Down' | null) {
    scrollDirection.value = dire
  }

  function setScroll(type: 'Up' | 'Down', flag: boolean) {
    if (type === 'Up') {
      scrollUp.value = flag
    } else {
      scrollDown.value = flag
    }
  }

  function setIsScrolling(flag: boolean) {
    isScrolling.value = flag
  }

  return {
    isScrolling,
    scrollUp,
    scrollDown,
    scrollDirection,
    setIsScrolling,
    setScroll,
    setScrollDirection,
  }
})
