import revive_payload_client_PVjsV7otPU from "/root/workspace/f2f-e3plus-newki-front_NY1p/node_modules/.pnpm/nuxt@3.9.0_@parcel+watcher@2.3.0_@types+node@18.19.3_encoding@0.1.13_eslint@8.56.0_optionator_nglnnwgy6aqtw6vujthrwese54/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_U9QRgQ3W4W from "/root/workspace/f2f-e3plus-newki-front_NY1p/node_modules/.pnpm/nuxt@3.9.0_@parcel+watcher@2.3.0_@types+node@18.19.3_encoding@0.1.13_eslint@8.56.0_optionator_nglnnwgy6aqtw6vujthrwese54/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_viHO8xEgMy from "/root/workspace/f2f-e3plus-newki-front_NY1p/node_modules/.pnpm/nuxt@3.9.0_@parcel+watcher@2.3.0_@types+node@18.19.3_encoding@0.1.13_eslint@8.56.0_optionator_nglnnwgy6aqtw6vujthrwese54/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_6zDcnqlbK6 from "/root/workspace/f2f-e3plus-newki-front_NY1p/node_modules/.pnpm/nuxt@3.9.0_@parcel+watcher@2.3.0_@types+node@18.19.3_encoding@0.1.13_eslint@8.56.0_optionator_nglnnwgy6aqtw6vujthrwese54/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_byTPwu33Gr from "/root/workspace/f2f-e3plus-newki-front_NY1p/node_modules/.pnpm/nuxt@3.9.0_@parcel+watcher@2.3.0_@types+node@18.19.3_encoding@0.1.13_eslint@8.56.0_optionator_nglnnwgy6aqtw6vujthrwese54/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import plugin_vue3_aYx2EQ9I1A from "/root/workspace/f2f-e3plus-newki-front_NY1p/node_modules/.pnpm/@pinia+nuxt@0.4.11_rollup@4.9.1_typescript@4.9.5_vue@3.3.13_typescript@4.9.5_/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/root/workspace/f2f-e3plus-newki-front_NY1p/.nuxt/components.plugin.mjs";
import prefetch_client_aVObSzEUBZ from "/root/workspace/f2f-e3plus-newki-front_NY1p/node_modules/.pnpm/nuxt@3.9.0_@parcel+watcher@2.3.0_@types+node@18.19.3_encoding@0.1.13_eslint@8.56.0_optionator_nglnnwgy6aqtw6vujthrwese54/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_djcSByVfc5 from "/root/workspace/f2f-e3plus-newki-front_NY1p/node_modules/.pnpm/@nuxtjs+device@3.1.1_rollup@4.9.1/node_modules/@nuxtjs/device/dist/runtime/plugin.mjs";
import composition_mE5AAcmlPX from "/root/workspace/f2f-e3plus-newki-front_NY1p/node_modules/.pnpm/@nuxtjs+i18n@8.0.0-beta.10_rollup@4.9.1_vue-router@4.2.5_vue@3.3.13_typescript@4.9.5___vue@3.3.13_typescript@4.9.5_/node_modules/@nuxtjs/i18n/dist/runtime/plugins/composition.mjs";
import i18n_LvdsGgGKt7 from "/root/workspace/f2f-e3plus-newki-front_NY1p/node_modules/.pnpm/@nuxtjs+i18n@8.0.0-beta.10_rollup@4.9.1_vue-router@4.2.5_vue@3.3.13_typescript@4.9.5___vue@3.3.13_typescript@4.9.5_/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import vant_lazyload_plugin_W13KNvxip6 from "/root/workspace/f2f-e3plus-newki-front_NY1p/.nuxt/vant-lazyload.plugin.mjs";
import chunk_reload_client_KsR5NyiaoA from "/root/workspace/f2f-e3plus-newki-front_NY1p/node_modules/.pnpm/nuxt@3.9.0_@parcel+watcher@2.3.0_@types+node@18.19.3_encoding@0.1.13_eslint@8.56.0_optionator_nglnnwgy6aqtw6vujthrwese54/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import analyze_client_MuxFcIkShO from "/root/workspace/f2f-e3plus-newki-front_NY1p/plugins/analyze.client.ts";
import error_ldt3PQauZ0 from "/root/workspace/f2f-e3plus-newki-front_NY1p/plugins/error.ts";
import i18n_client_gXssPVzz4q from "/root/workspace/f2f-e3plus-newki-front_NY1p/plugins/i18n.client.ts";
import im_client_8ex9Cq7J7y from "/root/workspace/f2f-e3plus-newki-front_NY1p/plugins/im.client.ts";
import jsBridge_client_Fg9jXwfMBP from "/root/workspace/f2f-e3plus-newki-front_NY1p/plugins/jsBridge.client.ts";
import route_client_8A0ENhmQRG from "/root/workspace/f2f-e3plus-newki-front_NY1p/plugins/route.client.ts";
export default [
  revive_payload_client_PVjsV7otPU,
  unhead_U9QRgQ3W4W,
  router_viHO8xEgMy,
  payload_client_6zDcnqlbK6,
  check_outdated_build_client_byTPwu33Gr,
  plugin_vue3_aYx2EQ9I1A,
  components_plugin_KR1HBZs4kY,
  prefetch_client_aVObSzEUBZ,
  plugin_djcSByVfc5,
  composition_mE5AAcmlPX,
  i18n_LvdsGgGKt7,
  vant_lazyload_plugin_W13KNvxip6,
  chunk_reload_client_KsR5NyiaoA,
  analyze_client_MuxFcIkShO,
  error_ldt3PQauZ0,
  i18n_client_gXssPVzz4q,
  im_client_8ex9Cq7J7y,
  jsBridge_client_Fg9jXwfMBP,
  route_client_8A0ENhmQRG
]