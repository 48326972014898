import { onMounted, onBeforeUnmount } from 'vue'
import { useBroswerStore } from '~/store/broswer'
import { useSystemStore } from '~/store/system'
import { getPlatForm } from '~/utils/getPlatForm'

export const useResizeEvent = () => {
  const { setClientWidth } = useBroswerStore()
  const { setPC } = useSystemStore()
  // 宽度改变
  const handleWidth = () => {
    // window.innerWidth 包含滾動條，document.documentElement.clientWidth 不包含滾動條
    const clientWidth = document.documentElement.clientWidth || window.innerWidth
    setClientWidth(clientWidth)
  }

  // 处理文字
  const handleFontSizeCalc = () => {
    useComputedFontSize()
    console.log('useComputedFontSize')
    if (getPlatForm() === 'PC') {
      setPC(true)
    } else {
      setPC(false)
    }
  }

  // 節流處理
  const debounceWidth = useDebounceFn(handleWidth, 300)

  // 節流處理
  const debounceFontSizeCalc = useDebounceFn(handleFontSizeCalc, 300)

  onMounted(() => {
    handleWidth()
    window.addEventListener('resize', debounceWidth)
    window.addEventListener('resize', debounceFontSizeCalc)
  })

  onBeforeUnmount(() => {
    window.removeEventListener('resize', debounceWidth)
    window.removeEventListener('resize', debounceFontSizeCalc)
  })
}
