import { io } from 'socket.io-client'
import { useMemberStore } from '~/store/member'
import { useImStore } from '~/store/im'

export default defineNuxtPlugin(() => {
  let imSocketIo: any = null

  function init() {
    console.info('socket init')
    const { memberInfo } = useMemberStore()
    const { setNewMessage, setUnRead, addUnRead } = useImStore()
    const uuid = useLocalStorage('nk-chat', '')
    const code = memberInfo.memberId ? memberInfo.memberId : uuid.value
    const socketUrl = useRuntimeConfig().public.imUrl
    imSocketIo = io(`${socketUrl}`, {
      transports: ['websocket'],
      query: {
        code,
      },
    })

    // 鏈接狀態
    imSocketIo.on('connect', () => {
      console.info('socket auto connect')
    })

    // 失去鏈接
    imSocketIo.on('disconnect', () => {
      console.info('socket auto disconnect')
    })

    // 收到消息
    imSocketIo.on('msg', (data: any) => {
      console.info('socket receive msg')
      setNewMessage(data)
      addUnRead()
    })

    // 未讀信息
    imSocketIo.on('visitor-un-read', (data: any) => {
      console.log(`socket unRead ${data}`)
      setUnRead(+data)
    })
  }

  function disconnect() {
    imSocketIo?.disconnect()
  }

  function send(data: any) {
    console.info('socket send msg')
    imSocketIo.emit('msg', data, (res: any) => {})
  }

  return {
    provide: {
      im: {
        init,
        disconnect,
        send,
      },
    },
  }
})
