<template>
  <div class="discount-tag-container" @click.stop.prevent="getMoreDiscount">
    <div class="text-container">{{ t('promotion.moreDiscount') }}</div>
  </div>
</template>

<script setup lang="ts">
/**
 * 获取更优价，先通过getBetterPrice获取页面档案内容，在获取活动档案内容，表现形式为弹窗，所有配置code均与运营约定好
 */
import { useGetBetterPrice } from '~/store/getBetterPrice'

const { t } = useI18n()
const { setGetMoreFlag, setPageCode } = useGetBetterPrice()

function getMoreDiscount() {
  setGetMoreFlag(true)
  setPageCode('getBetterPrice')
}
</script>

<style scoped lang="scss">
.discount-tag-container {
  display: flex;
  align-items: center;
  width: fit-content;
  height: 24px;
  padding: 0 6px 0 10px;
  background: $vip-gray;
  border-radius: 999px;

  .text-container {
    line-height: $lineNone;
    background: $vip-bg;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 14px;
    font-weight: 700;
    font-style: italic;
    padding-right: 4px;
  }
}
</style>
