import debounce from 'lodash/debounce'

const _routerBack = async () => {
  const state = window.history.state
  const router = useRouter()
  const route = useRoute()

  if (state && state.back) {
    await router.back()
  } else {
    // 無路由記錄返回首頁
    const name = analyzeRouteName('index')
    await router.push({
      name,
      query: {
        ni: route.query.ni,
        nu: route.query.nu,
      },
    })
  }
}
export const routerBack = debounce(_routerBack, 300, { leading: true })
