import { useMemberStore } from '@/store/member'
import { useImStore } from '~/store/im'

export const cleanLoginInfo = () => {
  const { $im }: any = useNuxtApp()
  const { setUnRead } = useImStore()
  $im.disconnect()
  useCookie('token').value = ''
  useCookie('refresh_token').value = ''
  useCookie('nbi').value = ''
  useCookie('newki-url-query-ni').value = ''
  useLocalStorage('newki-site-change', '').value = ''
  useLocalStorage('weChatLoginState', '0').value = '1'
  useLocalStorage('nk-popup', '{}').value = ''
  useLocalStorage('set-pwd-error', 0).value = 0
  setUnRead(0)
  const { resetMemberInfo } = useMemberStore()
  resetMemberInfo()
}
