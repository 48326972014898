<template>
  <van-field
    class="input-field"
    v-model="password"
    :type="showPwd ? 'input' : 'password'"
    :placeholder="t('login.pwdVerify')"
    :rules="[{ required: true, validator: validatorPassword }]"
  >
    <template #extra>
      <div class="extra-content">
        <NewkiSvg :name="showPwd ? 'eyes-open' : 'eyes-close'" color="#64748B" @click="changePwdDisplay" />
      </div>
    </template>
  </van-field>
  <van-field
    class="input-field"
    v-model="surePassword"
    :type="showSurePwd ? 'input' : 'password'"
    :placeholder="t('login.setPwdAgain')"
    :rules="[{ validator: validatorInput }]"
  >
    <template #extra>
      <div class="extra-content">
        <NewkiSvg :name="showSurePwd ? 'eyes-open' : 'eyes-close'" color="#64748B" @click="changeSurePwdDisplay" />
      </div>
    </template>
  </van-field>
</template>

<script setup lang="ts">
const { t } = useI18n()
const emit = defineEmits(['setPwd'])
let password = ref('')
let surePassword = ref('')
let showPwd = ref(false)
let showSurePwd = ref(false)

const changePwdDisplay = () => {
  showPwd.value = !showPwd.value
}
const changeSurePwdDisplay = () => {
  showSurePwd.value = !showSurePwd.value
}

const validatorPassword = (val: any, rule: any) => {
  let reg = new RegExp(/(?!\d+$)(?!^[a-zA-Z]+$)(?!^[_#@]+$).{8,20}/)
  if (!val) {
    rule.message = t('login.pwdVerify')
    return false
  }

  if (val.length > 20) {
    rule.message = t('login.pwdVerify')
    return false
  }

  if (!reg.test(val)) {
    rule.message = t('login.pwdVerify')
    return false
  }

  // 验证通过将密码传递给父组件
  emit('setPwd', password.value)
  return true
}
const validatorInput = (val: any, rule: any) => {
  if (!val) {
    rule.message = t('login.setPwdError')
    return false
  }

  if (val !== password.value) {
    rule.message = t('login.setPwdError')
    return false
  }

  // 验证通过将密码传递给父组件
  emit('setPwd', password.value)
  return true
}

defineExpose({
  password,
})
</script>

<style scoped lang="scss">
.input-field {
  border: 1px solid $border-color;
  border-radius: 8px;
  margin-top: 26px;
  overflow: unset;

  .van-field__value {
    overflow: unset;
  }
  :deep(.van-field__error-message) {
    position: absolute;
    bottom: -36px;
    left: -10px;
  }
}

.extra-content {
  display: flex;
  align-items: center;

  .divider {
    width: 2px;
    height: 16px;
    margin: 0 10px;
    background-color: $border-color;
  }
  .find-pwd {
    color: $primary;

    &:hover {
      cursor: pointer;
    }
  }
}
</style>
