<template>
  <div @click.stop="toggleCollect()">
    <NewkiSvg :size="16" :key="mallSkuId" name="star-border" :filled="true" v-if="!collectStatus" />
    <NewkiSvg :size="16" :key="mallSkuId + '2'" name="star" :filled="true" v-if="collectStatus" />
    <login-dialog ref="loginDialog" @callBack="onClose" />
  </div>
</template>

<script lang="ts" setup>
import LoginDialog from '~/components/LoginDialog.vue'

const loginDialog = ref<InstanceType<typeof LoginDialog> | null>(null)

import { useCollectStore } from '~/store/collect'

const { addCollect, deleteCollect } = useCollectStore()
const { t } = useI18n()

const loginStatusP: any = ref([])

const props = defineProps({
  mallSkuId: {
    type: String,
    required: true,
  },
  isCollected: {
    type: Boolean,
    required: true,
  },
})

const onClose = () => {
  loginStatusP.value[0](true)
}

const showLoginDialog = async () => {
  loginDialog.value?.showModal()
  let p = new Promise((resolve, reject) => {
    loginStatusP.value[0] = resolve
    loginStatusP.value[1] = reject
  })
  return p
}

// 内部 data，可更改
const collectStatus = ref(props.isCollected)

const toggleCollect = async (isCollected: boolean = collectStatus.value) => {
  let isLogin = useIsLogin()

  // 已登錄
  if (isLogin) {
    isCollected ? remove() : add()
    return false
  }
  // 未登錄
  let flag = await showLoginDialog()
  if (flag) {
    let _isLogin = useIsLogin()
    if (_isLogin) {
      add()
    }
  }
}

async function add(skuId: string = props.mallSkuId) {
  const res = await addCollect([
    {
      mallSkuId: skuId,
    },
  ])

  if (res) {
    showToast(t('collect.success'))
    collectStatus.value = true
  }
}

async function remove(skuId: string = props.mallSkuId) {
  const res = await deleteCollect([skuId])

  console.log(res)

  showToast(t('collect.deleSuccess'))
  collectStatus.value = false
}
</script>

<style scoped></style>
