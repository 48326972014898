// 獲取更優價活動

export const useGetBetterPrice = defineStore('getBetterPrice', () => {
  const getMoreFlag = ref<any>(false)
  const showPopUp = ref<boolean>(false)
  const pageCode = ref('getBetterPrice')

  function setGetMoreFlag(data: any) {
    getMoreFlag.value = data
  }

  function setShowPopUp(flag: boolean) {
    showPopUp.value = flag
  }
  function setPageCode(code: string) {
    pageCode.value = code
  }

  return {
    getMoreFlag,
    showPopUp,
    setGetMoreFlag,
    setShowPopUp,
    pageCode,
    setPageCode,
  }
})
