import { $axios } from '../index'
import { showFailToast } from 'vant'
/**
 * @description: 獲取可用活動
 */
export const apiGetActivity = () => {
  return $axios()
    .post(`:goods/app/activity/getAvailable`)
    .then((res: any) => {
      return res.data
    })
    .catch(() => {
      return null
    })
}
/**
 * @description: 獲取活動詳情
 */
export const apiGetActivityDetail = (code: string) => {
  return $axios()
    .post(`:goods/app/activity/${code}`)
    .then((res: any) => {
      return res.data
    })
    .catch(() => {
      return null
    })
}
/**
 * @description: 獲取活動會員信息
 */
export const apiGetActivityMemberInfo = (activityId: string) => {
  return $axios()
    .post(`:goods/app/activity/getCurrentActivityMemberInfo`, { activityId })
    .then((res: any) => {
      return res.data
    })
    .catch(() => {
      return null
    })
}
/**
 * @description: 獲取當前活動會員兌換記錄
 */
export const apiGetActivityMemberLog = (activityId: string, pageNum = 1, pageSize = 20) => {
  return $axios()
    .post(`:goods/app/activity/getCurrentActivityMemberLog?pageNum=${pageNum}&pageSize=${pageSize}`, { activityId })
    .then((res: any) => {
      return res.data
    })
    .catch(() => {
      return null
    })
}
/**
 * @description: 獲取當前活動會員邀請記錄
 */
export const apiGetActivityMemberInvite = (activityId: string, pageNum = 1, pageSize = 20) => {
  return $axios()
    .post(`:goods/app/activity/getCurrentActivityMemberInviteInfo?pageNum=${pageNum}&pageSize=${pageSize}`, {
      activityId,
    })
    .then((res: any) => {
      return res.data
    })
    .catch(() => {
      return null
    })
}
/**
 * @description: 兌換
 */
export const apiActivityExchange = (activityId: string) => {
  return $axios()
    .post(`:goods/app/activity/exchange`, { activityId })
    .then((res: any) => {
      if (res.success) {
        return res.data
      } else {
        if (res.code !== 'mall.goods.500005') {
          showFailToast(res.msg)
          return null
        } else {
          return {
            state: 'fail',
            msg: res.msg,
          }
        }
      }
    })
    .catch(() => {
      return null
    })
}

/**
 * @description: 批量兌換
 */
export const apiActivityExchangeBatch = (activityId: string, hitGiftIds: []) => {
  return $axios()
    .post(`:goods/app/activity/give`, { activityId, hitGiftIds })
    .then((res: any) => {
      if (res.success) {
        return 'success'
      } else {
        if (res.code !== 'mall.goods.500005') {
          showFailToast(res.msg)
          return null
        } else {
          return 'fail'
        }
      }
    })
    .catch(() => {
      return null
    })
}

/**
 * @description: 獲取活動記錄
 */
export const apiGetActivityLog = (params: any) => {
  return $axios()
    .post(`:goods/app/activity/queryLogList`, params)
    .then((res: any) => {
      return res.data
    })
    .catch(() => {
      return null
    })
}
