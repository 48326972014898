export default defineNuxtPlugin(nuxtApp => {
  // const log = console.log
  const log = (data: any) => {}
  nuxtApp.hook('app:error', error => {
    log('app:error')
    log(error)
    // handle error, e.g. report to a service
  })
  // nuxtApp.hook('vue:error', (error, instance, info) => {
  //   log('vue:error')
  //   log(error)
  //   // handle error, e.g. report to a service
  // })
  // nuxtApp.hook('app:created', error => {
  //   log('app:created')
  //   log(error)
  //   // handle error, e.g. report to a service
  // })
  // nuxtApp.hook('app:beforeMount', error => {
  //   log('app:beforeMount')
  //   log(error)
  //   // handle error, e.g. report to a service
  // })
  // nuxtApp.hook('app:mounted', error => {
  //   log('app:mounted')
  //   log(error)
  //   // handle error, e.g. report to a service
  // })
})
