import { $axios } from '../index'
import { useMemberStore } from '~/store/member'

/**
 * @description: 獲取會員信息
 */
export const apiGetMemberInfo = () => {
  return $axios()
    .get(`:member/app/memberInfo/get`)
    .then((res: any) => {
      const { setMemberInfo } = useMemberStore()
      if (res.data) {
        setMemberInfo(res.data)
      }
    })
    .catch(() => {
      return null
    })
}
/**
 * @description: 獲取會員信息
 */
export const apiGetVipInfo = () => {
  return $axios()
    .get(`:mall/app/user/center`)
    .then((res: any) => {
      return res.data
    })
    .catch(() => {
      return null
    })
}
/**
 * @description: 獲取我的積分
 */
export const apiGetIntegral = () => {
  return $axios()
    .post(`:mall/app/userIntegral/get`)
    .then((res: any) => {
      return res.data
    })
    .catch(() => {
      return null
    })
}

/**
 * @description: 我的積分明細查詢
 * @param {string} pageNum 頁數
 * @param {string} pageSize 每頁記錄數
 */
export const apiGetIntegralDetail = (pageNum: string, pageSize: string = '10') => {
  return $axios()
    .post(`:mall/app/userIntegral/queryLog?pageNum=${pageNum}&pageSize=${pageSize}`)
    .then((res: any) => {
      return res.data
    })
    .catch(() => {
      return null
    })
}

/**
 * @description: 獲取會員可購買等級商品信息
 */
export const apiGetGetCanbuyLevelInfo = () => {
  return $axios()
    .get(`:member/app/memberLevelCoupon/getCanbuyLevelInfo`)
    .then((res: any) => {
      return res.data
    })
    .catch(() => {
      return null
    })
}

/**
 * @description: 单独购买会员
 * @param {string} pageNum 頁數
 * @param {string} pageSize 每頁記錄數
 */
export const apiBuyVip = (levelId: string | number) => {
  return $axios()
    .post(`:mall/app/buy/buyVip`, { levelId: levelId })
    .then((res: any) => {
      return res.data
    })
    .catch(() => {
      return null
    })
}

/**
 * @description: 获取购买会员订单状态
 * @param {string} pageNum 頁數
 * @param {string} pageSize 每頁記錄數
 */
export const apiCanBuyLevel = () => {
  return $axios()
    .get(`:mall/app/vipPackage/canBuyLevel`)
    .then((res: any) => {
      return res.data
    })
    .catch(() => {
      return null
    })
}

/**
 * @description: 設置會員的郵箱
 */
export const apiSetMemberEmail = (email: string) => {
  return $axios()
    .post(`:member/app/memberInfo/email?email=${email}`)
    .then((res: any) => {
      return res
    })
    .catch(() => {
      return null
    })
}
