import { $axios } from '../index'
/**
 * @description: 獲取訂單列表
 * @param pageNum{number} :当前页码
 * @param data{Object} : 分類參數
 */
export const apiGetOrderList = (pageNum: number = 1, data = {}) => {
  return $axios()
    .post(`:mall/app/bill/query?pageNum=${pageNum}&pageSize=10`, data)
    .then((res: any) => {
      return res.data
    })
    .catch(() => {
      return null
    })
}

/**
 * @description: 獲取訂單列表（搜索）
 * @param pageNum{number} :当前页码
 * @param data{Object} : 分類參數
 */
export const apiSearchOrderList = (pageNum: number = 1, data = {}) => {
  return $axios()
    .post(`:mall/app/bill/query?pageNum=${pageNum}&pageSize=10`, data)
    .then((res: any) => {
      return res.data
    })
    .catch(() => {
      return null
    })
}

/**
 * @description: 取消訂單
 * @param billId{number} :billId
 * @param cancelReason{string} :取消原因
 */
export const apiCancelOrder = (billId: number | string, cancelReason: string) => {
  return $axios()
    .post(`:mall/app/bill/cancel`, { billId, cancelReason })
    .then((res: any) => {
      return res.data
    })
    .catch(() => {
      return null
    })
}
/**
 * @description: 取消訂單原因
 */
export const apiGetCancelList = () => {
  return $axios()
    .get(`:mall/app/bill/cancelReason`)
    .then((res: any) => {
      return res.data
    })
    .catch(() => {
      return null
    })
}
/**
 * @description: 確認收貨
 */
export const apiOrderReceive = (billId: string) => {
  return $axios()
    .formPost(`:mall/app/bill/receive`, { billId })
    .then((res: any) => {
      return res.data
    })
    .catch(() => {
      return null
    })
}
/**
 * @description: 訂單詳情
 */
export const apiGetOrderDetail = (billId: string) => {
  return $axios()
    .get(`:mall/app/bill/info/${billId}`)
    .then((res: any) => {
      return res.data
    })
    .catch(() => {
      return null
    })
}

/**
 * @description: ERP 訂單詳情
 */
export const apiGetErpOrderDetail = (billId: string) => {
  return $axios()
    .get(`:mall/app/ERPBill/info/${billId}`)
    .then((res: any) => {
      return res.data
    })
    .catch(() => {
      return null
    })
}

/**
 * @description: 獲取分類的總數
 */
export const apiGetOrderTypeCount = (data: any) => {
  return $axios()
    .post(`:mall/app/bill/countBillStatusType`, data)
    .then((res: any) => {
      return res.data
    })
    .catch(() => {
      return null
    })
}

// 獲取當前訂單的售後類型
export const getAfterSaleType = async (orderId: string, skuId: string) => {
  if (skuId) {
    return getGoodAfterSaleType(orderId, skuId)
  } else {
    return getOrderAfterSaleType(orderId)
  }
}

/**
 * @description: 获取售后类型：商品級別
 */
export const getGoodAfterSaleType = async (id: string, skuId: string = '') => {
  return $axios()
    .get(':mall/app/bill/goodsAfterSaleType', { params: { billId: id, skuId: skuId } })
    .then((res: any) => {
      if (res.success) {
        return res.data
      }
    })
    .catch(() => {
      return null
    })
}

/**
 * @description: 获取售后类型：訂單級別
 */
export const getOrderAfterSaleType = async (id: string) => {
  return $axios()
    .get(':mall/app/bill/orderAfterSaleType', { params: { billId: id } })
    .then((res: any) => {
      if (res.success) {
        return res.data
      }
    })
    .catch(() => {
      return null
    })
}

/**
 * @description: 获取 ERP 订单列表
 * @param pageNum{number} :当前页码
 * @param data{Object} : 分類參數
 */
export const apiGetERPBillOrderList = (pageNum: number = 1, data = {}) => {
  return $axios()
    .post(`:mall/app/ERPBill/query?pageNum=${pageNum}&pageSize=10`, data)
    .then((res: any) => {
      return res.data
    })
    .catch(() => {
      return null
    })
}

export const apiGetERPBillOrderList2 = (pageNum: number = 1) => {
  return $axios()
    .post(`:mall/app/ERPBill/query?pageNum=${pageNum}&pageSize=10`, { memberPhone: '853,97725666' })
    .then((res: any) => {
      return res.data
    })
    .catch(() => {
      return null
    })
}

/**
 * @description: 获取可退金额
 */
export const apiGetRefundableAmount = (billId: string, skuId: string, num: string | number) => {
  return $axios()
    .get(`:mall/app/bill/refundableAmount?billId=${billId}&num=${num}&skuId=${skuId}`)
    .then((res: any) => {
      return res.data
    })
    .catch(() => {
      return null
    })
}
