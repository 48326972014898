<template>
  <van-popup v-model:show="show" teleport="body" round position="bottom" :safe-area-inset-bottom="true">
    <div class="share-title">{{ title ? title : t('sharePopup.title') }}</div>
    <div class="share-container">
      <div class="share-item" v-if="!exclude.includes('link')" @click="link">
        <img class="share-item-img" src="~/assets/images/share/link.svg" width="48" height="48" />
        <div class="share-item-title">{{ t('sharePopup.link') }}</div>
      </div>
    </div>
    <div class="division"></div>
    <div class="cancel-container" @click="show = false">{{ t('common.cancel') }}</div>
  </van-popup>
</template>

<script setup lang="ts">
import { useClipboard } from '@vueuse/core'
import { showToast } from 'vant'

const { t } = useI18n()

const show = ref(false)
const props = defineProps({
  title: {
    type: String,
    required: false,
    default: '',
  },
  shareContent: {
    type: Object,
    required: true,
    default: () => {},
  },
  exclude: {
    type: Array,
    require: false,
    default: () => [],
  },
})
const { copy } = useClipboard({ legacy: true })

function open() {
  show.value = true
}

function link() {
  try {
    copy(props.shareContent.link)
    showToast(t('common.copySuccess'))
  } catch (e) {
    showToast(t('common.copyError'))
  }
  show.value = false
}

defineExpose({
  open,
})
</script>

<style scoped lang="scss">
.share-title {
  padding: 20px 0;
  text-align: center;
  @extend .text-body;
  color: $text-color-default;
}
.share-container {
  padding: 12px;
  display: grid;
  grid-template-columns: repeat(auto-fill, 80px);
  grid-gap: 12px;
  justify-content: start;

  .share-item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &:hover {
      cursor: pointer;
    }

    .share-item-img {
      border-radius: 50%;
    }
    .share-item-title {
      margin-top: 10px;
      @extend .text-body;
      color: $text-color-default;
    }
  }
}
.division {
  width: 100%;
  height: 8px;
  background-color: $bg-color;
}
.cancel-container {
  padding: 10px 0;
  text-align: center;
  @extend .text-body;
  color: $text-color-assist;
}
</style>
