<template>
  <van-field
    ref="inputPhoneRef"
    name="areaCodeInput"
    class="input-field"
    :class="{ 'code-disabled': disabled, slim: slim }"
    v-model="mobile"
    :formatter="formatter"
    :placeholder="t('login.inputPhoneNumber')"
    :rules="[{ validator: validatorInput }]"
    :disabled="disabled"
    @update:model-value="onUpdate"
    type="tel"
  >
    <template #label>
      <div v-if="areaCodeMap[areaCode]" class="area-code" @click="openSelected">
        <div class="flag-box">
          <newki-image
            class="flag"
            :class="{ show: areaCode == code }"
            width="30"
            height="20"
            :radius="4"
            :src="item?.flag"
            v-for="(item, code) of areaCodeMap"
          />
        </div>
        <div class="code-content">
          <span class="code">{{ areaCode }}</span>
          <NewkiSvg name="chevron-down" color="#111827" />
        </div>
        <div class="divider" v-if="!slim"></div>
      </div>
    </template>
  </van-field>
  <van-action-sheet
    v-model:show="showAreaCodeSelect"
    :title="t('login.chooseAreaCode')"
    teleport="body"
    :closeable="false"
    :cancel-text="t('common.cancel')"
  >
    <van-radio-group v-model="areaCode">
      <van-cell-group inset>
        <van-cell v-for="(value, key) in areaCodeMap" clickable @click="selectAreaCode(String(key))" :key="key">
          <template #title>
            <div class="area-code area-code-sheet">
              <newki-image width="30" height="20" :radius="4" :src="value.flag" />
              <div class="code-content">
                <span class="name">{{ value.name }}</span>
                <span class="code">+{{ key }}</span>
              </div>
            </div>
          </template>
          <template #right-icon>
            <van-radio :name="+key" />
          </template>
        </van-cell>
      </van-cell-group>
    </van-radio-group>
  </van-action-sheet>
</template>

<script setup lang="ts">
import { inject, onMounted, watch, nextTick } from 'vue'
import { useMemberStore } from '~/store/member'
import { useSystemStore } from '~/store/system'
import type { FieldInstance } from 'vant'
import { useDebounceFn } from '@vueuse/core'
const { t } = useI18n()
const emit = defineEmits(['validPhone'])
const { mobile, areaCode } = storeToRefs(useMemberStore())
const { setAreaCode, setMobile } = useMemberStore()
const { areaCodeMap } = storeToRefs(useSystemStore())
const inputPhoneRef = ref<FieldInstance>()
const dialog = inject('dialog', false)
let showAreaCodeSelect = ref(false)

import { useCurrentSelectedAreaCodeStore } from '~/store/currentSelectedAreaCode'
const { setUserSelected } = useCurrentSelectedAreaCodeStore()
const { isUserSelected } = storeToRefs(useCurrentSelectedAreaCodeStore())

const { okAreaCode, validatorPhoneNumber } = usePhoneValidator()

const props = defineProps({
  disabled: {
    type: Boolean,
    required: false,
    default: false,
  },
  initPhone: {
    type: Boolean,
    required: false,
    default: false,
  },
  slim: {
    type: Boolean,
    required: false,
    default: false,
  },
})

function openSelected() {
  if (props.disabled) return
  showAreaCodeSelect.value = true
}

const changeDefaultAreaCode = () => {
  // 判斷areaCodeMap有沒有默認的areaCode，沒有設置areaCodeMap的第一項為areaCode
  if (!areaCodeMap.value[areaCode.value]) {
    for (let i in areaCodeMap.value) {
      setAreaCode(+i)
      break
    }
  }
}

// 手機號驗證方法
const validatorInput = async (val: any, rule: any) => {
  // 1、判斷非空
  if (!val) {
    rule.message = t('login.inputPhoneNumber')
    failValid()
    return false
  }

  let status: any = await validatorPhoneNumber(val, rule)

  // 2、判斷校驗是否通過
  if (!status.flag) {
    rule.message = t('login.inputRightPhoneNumber')
    failValid()
    return false
  }

  // 2、校驗通過後，判斷是否用戶手動選擇區號
  if (isUserSelected.value) {
    if (String(areaCode.value) === String(okAreaCode.value)) {
      passValid()
      return true
    } else {
      rule.message = t('login.inputRightPhoneNumber')
      failValid()
      return false
    }
  } else {
    if (status.flag) {
      passValid()
      setAreaCode(Number(status.okAreaCode))
      return true
    } else {
      failValid()
      rule.message = t('login.inputRightPhoneNumber')
      return false
    }
  }
}

const onUpdate = () => {
  triggerValid()
}

// 通知父組件檢驗【通過】
const passValid = () => {
  emit('validPhone', true)
}

// 通知父組件檢驗【不通過】
const failValid = () => {
  emit('validPhone', false)
}

async function selectAreaCode(key: string) {
  areaCode.value = +key
  // 關閉彈窗
  showAreaCodeSelect.value = false
  setUserSelected(true)
  setTimeout(() => {
    triggerValid()
  }, 100)
}

const triggerValid = () => {
  // 為了觸發規則校驗
  inputPhoneRef.value?.validate()
}

// 僅保留純數字
const formatter = (value: string) => {
  return value.replace(/\D/g, '')
}

watch(
  areaCodeMap,
  val => {
    changeDefaultAreaCode()
  },
  { immediate: true }
)

watch(
  () => mobile.value,
  val => {
    if (val) {
      // 如果有電話則進行校驗
      if (dialog) {
        // 如果是彈窗登錄的話，需要等tab動畫結束後進行校驗，不然會觸發不停的重繪導致UI問題
        const timer = setTimeout(() => {
          clearTimeout(timer)
          triggerValid()
        }, 500)
      } else {
        // triggerValid()
        const timer = setTimeout(() => {
          clearTimeout(timer)
          triggerValid()
        }, 500)
      }
    }
  },
  { immediate: true }
)

onMounted(() => {
  if (props.initPhone) {
    setMobile('')
  }
})
</script>

<style scoped lang="scss">
.input-field {
  border: 1px solid $border-color;
  border-radius: 8px;
  overflow: unset;

  &.slim {
    padding: 0;
    border: none;
    :deep(.van-cell__title) {
      width: 82px;
      margin-right: 0;
    }
    :deep(.van-field__error-message) {
      position: absolute;
      bottom: -24px;
      left: -92px;
    }
    // .area-code {
    //   .divider {
    //     display: none;
    //   }
    // }
  }

  .van-field__value {
    overflow: unset;
  }
  :deep(.van-field__error-message) {
    position: absolute;
    bottom: -36px;
    left: -122px;
  }
}
.area-code {
  display: flex;
  align-items: center;

  .flag-box {
    position: relative;
    z-index: 10;
    width: 30px;
    height: 20px;

    .flag {
      position: absolute;
      inset: 0;
      z-index: 1;
      opacity: 0.05;

      &.show {
        z-index: 10;
        opacity: 1;
      }
    }
  }

  .code-content {
    display: flex;
    margin-left: 6px;

    .code {
      margin-right: 4px;
    }
  }
  .divider {
    width: 2px;
    height: 16px;
    margin-left: 10px;
    background-color: $border-color;
  }
}

.area-code-sheet {
  .code {
    margin-left: 4px;
    color: $text-color-assist;
  }
}
:deep(.van-field__label) {
  flex: none !important;
}
.code-disabled {
  background-color: $bg-color;
}
</style>
