<template>
  <div class="newki-popup float-btn-container" :class="{ show: !isScrolling }">
    <template v-for="(item, index) in floatList" :key="index">
      <div v-show="showFloat(item) && !chatPopup" class="float-btn-item" @click="action(item)">
        <template v-if="item.action === PopupAction.chat || item.action === PopupAction.popchat">
          <van-badge>
            <NewkiImage v-if="item.path && !item.path?.includes('json')" :src="item.path" width="50" height="50" />
            <div v-else :id="`float-btn-animation-${index}`" class="float-btn-animation"></div>
            <template #content v-if="unRead > 0">
              <span class="badge-num">{{ Number(unRead) < 100 ? Number(unRead) : '99+' }}</span>
            </template>
          </van-badge>
        </template>
        <template v-else>
          <NewkiImage v-if="item.path && !item.path?.includes('json')" :src="item.path" width="50" height="50" />
          <div v-else :id="`float-btn-animation-${index}`" class="float-btn-animation"></div>
        </template>
      </div>
    </template>
  </div>
  <van-popup v-model:show="showPopUp" teleport="body" :close-on-click-overlay="false">
    <div id="newkiPopup" @click="action(popup)">
      <NewkiImage v-if="!popup.path?.includes('json')" height="360" width="375" :src="popup.path" :lazy-load="false" />
    </div>
    <div class="close-icon" @click="close">
      <van-icon name="close" color="#ffffff" size="30" />
    </div>
  </van-popup>
</template>

<script setup lang="ts">
import { ref, watch, computed } from 'vue'
import { PopupAction, PopupTime, PopupFrequency } from '~/enum/popup'
import { usePopupStore } from '~/store/popup'
import { useSystemStore } from '~/store/system'
import { useImStore } from '~/store/im'
import NewkiImage from '~/components/NewkiImage.vue'
import dayjs from 'dayjs'
import lottie from 'lottie-web'
import { useScrollStatus } from '~/store/scrollStatus'
const { isScrolling } = storeToRefs(useScrollStatus())

const imgDomain = useRuntimeConfig().public.oss
const popupList = ref<any[]>([]) // 立即彈窗列表
const popupRegisterList = ref<any[]>([]) // 註冊成功彈窗列表
const popupLoginList = ref<any[]>([]) // 登錄成功彈窗列表
const popupNeedLoginList = ref<any[]>([]) // 需要登錄彈窗列表
const popupPayList = ref<any[]>([]) // 支付成功彈窗列表
const waitPopupList = ref<any[]>([]) // 待彈出列表
const pagePopupList = ref<any[]>([]) // 應用頁面彈出列表
const popup = ref<any>({}) // 當前正在彈窗的數據
const floatList = ref<any[]>([]) // 浮窗列表

let animation: any = null
let floatAnimation: any = []
const showPopUp = ref(false)
const { popupNode } = storeToRefs(usePopupStore())
const { config, chatPopup } = storeToRefs(useSystemStore())
const { unRead } = storeToRefs(useImStore())

let isLogin = ref(false) // 是否登錄
let popupStorage = useLocalStorage('nk-popup', '{}') // 彈窗緩存，用於判斷彈窗頻率

const routeName = computed(() => {
  const route = useRoute()
  const name = route.name?.toString()
  const list = name.split('__')
  return list[list.length - 1]
})

watch(isScrolling, val => {
  // console.log('是否滾動：', val)
})

watch(
  () => useIsLogin(),
  (val, oldVal) => {
    isLogin.value = val
    if (!oldVal && val) {
      needLoginPopup()
    }
  },
  { immediate: true, deep: true }
)

watch(
  () => useRoute().name,
  () => {
    pagePopup()
    const timer = setTimeout(() => {
      clearTimeout(timer)
      showFloatAnimation()
    }, 200)
  }
)

watch(
  () => config.value,
  () => {
    init()
  },
  { deep: true, immediate: true }
)

watch(
  () => popupNode.value,
  val => {
    if (val === 'login') {
      loginPopup()
    }
    if (val === 'pay') {
      payPopup()
    }
    if (val === 'register') {
      registerPopup()
    }
  }
)

// const showWaitPopup = computed(() => {
//   return (
//     !popupList.value.length &&
//     !popupRegisterList.value.length &&
//     !popupLoginList.value.length &&
//     !popupNeedLoginList.value.length &&
//     !popupPayList.value.length
//   )
// })

async function init() {
  let data = config.value
  if (data) {
    const list = data.popupVOList || []
    for (let i = 0; i < list.length; i++) {
      const content = JSON.parse(list[i]?.content)
      const usePageNameList: any[] = []
      const usePage = JSON.parse(list[i]?.usePage || '[]')
      usePage.forEach((item: any) => {
        usePageNameList.push(item)
      })
      // 浮窗
      if (content?.float) {
        floatList.value.push({
          path: content?.float.path[0],
          action: list[i].action,
          actionDetail: list[i].actionDetail,
          isLogin: +list[i].isLogin,
          usePage: usePageNameList,
          usePageAll: +list[i].usePageAll,
          id: list[i].id,
        })
      }
      // 彈窗
      if (content?.popup) {
        if (+list[i].isLogin && !isLogin.value) {
          // 需要登錄的彈窗
          popupNeedLoginList.value.push({
            path: content?.popup.path[0],
            action: list[i].action,
            actionDetail: list[i].actionDetail,
            isLogin: +list[i].isLogin,
            popupFrequency: list[i].popupFrequency,
            popupNode: list[i].popupNode,
            usePage: usePageNameList,
            usePageAll: +list[i].usePageAll,
            id: list[i].id,
          })
        } else if (+list[i].isLogin && isLogin.value) {
          // 立即彈窗
          popupList.value.push({
            path: content?.popup.path[0],
            action: list[i].action,
            actionDetail: list[i].actionDetail,
            isLogin: +list[i].isLogin,
            popupFrequency: list[i].popupFrequency,
            popupNode: list[i].popupNode,
            usePage: usePageNameList,
            usePageAll: +list[i].usePageAll,
            id: list[i].id,
          })
        } else if (list[i].popupNode === PopupTime.login) {
          // 登錄成功彈窗
          popupLoginList.value.push({
            path: content?.popup.path[0],
            action: list[i].action,
            actionDetail: list[i].actionDetail,
            isLogin: +list[i].isLogin,
            popupFrequency: list[i].popupFrequency,
            popupNode: list[i].popupNode,
            usePage: usePageNameList,
            usePageAll: +list[i].usePageAll,
            id: list[i].id,
          })
        } else if (list[i].popupNode === PopupTime.now) {
          // 立即彈出
          popupList.value.push({
            path: content?.popup.path[0],
            action: list[i].action,
            actionDetail: list[i].actionDetail,
            isLogin: +list[i].isLogin,
            popupFrequency: list[i].popupFrequency,
            popupNode: list[i].popupNode,
            usePage: usePageNameList,
            usePageAll: +list[i].usePageAll,
            id: list[i].id,
          })
        } else if (list[i].popupNode === PopupTime.register) {
          // 註冊成功彈窗
          popupRegisterList.value.push({
            path: content?.popup.path[0],
            action: list[i].action,
            actionDetail: list[i].actionDetail,
            isLogin: +list[i].isLogin,
            popupFrequency: list[i].popupFrequency,
            popupNode: list[i].popupNode,
            usePage: usePageNameList,
            usePageAll: +list[i].usePageAll,
            id: list[i].id,
          })
        } else if (list[i].popupNode === PopupTime.pay) {
          // 支付成功彈窗
          popupPayList.value.push({
            path: content?.popup.path[0],
            action: list[i].action,
            actionDetail: list[i].actionDetail,
            isLogin: +list[i].isLogin,
            popupFrequency: list[i].popupFrequency,
            popupNode: list[i].popupNode,
            usePage: usePageNameList,
            usePageAll: +list[i].usePageAll,
            id: list[i].id,
          })
        }
      }
    }
  }
  const timer = setTimeout(() => {
    clearTimeout(timer)
    showFloatAnimation()
  }, 500)
  popupDeal()
}

function showFloat(data: any) {
  // 是否登錄
  if (data.isLogin && !isLogin.value) {
    return false
  } else if ((data.action === PopupAction.chat || data.action === PopupAction.popchat) && routeName.value === 'chat') {
    return false
  } else if (data.usePageAll) {
    return true
  } else {
    return data.usePage.includes(routeName.value)
  }
}

function showFloatAnimation() {
  const list = floatList.value
  for (let i = 0; i < list.length; i++) {
    if (list[i].path && list[i].path.includes('json')) {
      if (floatAnimation[i]) {
        continue
      }
      floatAnimation[i] = lottie.loadAnimation({
        container: document.querySelector(`#float-btn-animation-${i}`)!,
        renderer: 'svg',
        loop: true,
        autoplay: true,
        path: imgDomain + list[i].path,
      })
    }
  }
}

function action(data: any) {
  // console.log('action', data.action)
  let detail: any = {}
  try {
    detail = JSON.parse(data?.actionDetail)
  } catch (err) {
    detail = {
      actionCode: '',
      actionContentCode: '',
      actionContent: '',
    }
  }
  const params = {
    actionCode: data.action,
    actionContentCode: detail.actionContentCode,
    actionContent: detail.actionContent,
  }
  close()
  useContentAction(params)
}

function popupDeal() {
  // 立即彈出
  if (popupList.value.length) {
    const list = popupList.value
    const flag: boolean | any[] = popupHandel(list)
    if (flag !== false) {
      popupList.value = flag as any[]
      return
    }
  }
}

function needLoginPopup() {
  // 需要登錄彈出
  if (popupNeedLoginList.value.length && isLogin) {
    const list = popupNeedLoginList.value
    const flag: boolean | any[] = popupHandel(list)
    if (flag !== false) {
      popupNeedLoginList.value = flag as any[]
      return
    }
  }
}

function loginPopup() {
  // 登錄彈出
  if (popupLoginList.value.length) {
    const list = popupLoginList.value
    const flag: boolean | any[] = popupHandel(list)
    if (flag !== false) {
      popupLoginList.value = flag as any[]
      return
    }
  }
}

function registerPopup() {
  // 註冊成功彈出
  if (popupRegisterList.value.length) {
    const list = popupRegisterList.value
    const flag: boolean | any[] = popupHandel(list)
    if (flag !== false) {
      popupRegisterList.value = flag as any[]
      return
    }
  }
}

function pagePopup() {
  const popupStorageMap = JSON.parse(popupStorage.value || '{}')
  if (pagePopupList.value.length) {
    const list = pagePopupList.value
    for (let i = 0; i < list.length; i++) {
      if (list[i].usePage && (list[i].usePageAll || list[i].usePage.includes(routeName.value))) {
        // 每天首次彈窗
        if (list[i].popupFrequency === PopupFrequency.day && popupStorageMap.value[list[i].id]) {
          if (popupStorageMap.value[list[i].id].time !== dayjs().format('YYYY-MM-DD')) {
            popupStorageMap.value[list[i].id].hasShow = false
          }
        }
        if (!popupStorageMap[list[i].id] || !popupStorageMap[list[i].id].hasShow) {
          // 有彈出節點
          popup.value = list[i]
          showPopUp.value = true
          if (list[i].path.includes('json')) {
            setTimeout(
              path => {
                showAnimation(imgDomain + path)
              },
              200,
              list[i].path
            )
          }
          pagePopupList.value.splice(i, 1)
        }
        return
      }
    }
  }
}

function waitPopup() {
  if (waitPopupList.value.length) {
    const list = waitPopupList.value
    const flag: any = popupHandel(list)
    if (flag! == false) {
      waitPopupList.value.splice(flag, 1)
      return
    }
  }
}

function payPopup() {
  // 支付成功彈出
  if (popupPayList.value.length) {
    const list = popupPayList.value
    const flag: boolean | any[] = popupHandel(list)
    if (flag !== false) {
      popupPayList.value = flag as any[]
      return
    }
  }
}

function popupHandel(list: any) {
  const popupStorageMap = JSON.parse(popupStorage.value || '{}')
  let tempList = useCloneDeep(list)
  for (let i = 0; i < list.length; i++) {
    // 每天首次彈窗
    if (list[i].popupFrequency === PopupFrequency.day && popupStorageMap[list[i].id]) {
      if (popupStorageMap[list[i].id].time !== dayjs().format('YYYY-MM-DD')) {
        popupStorageMap[list[i].id].hasShow = false
      }
    }
    if (!popupStorageMap[list[i].id] || !popupStorageMap[list[i].id].hasShow) {
      if (!list[i].usePageAll && !list[i].usePage.includes(routeName.value)) {
        // 有彈出節點
        pagePopupList.value.push(list[i])
        tempList = tempList.filter((item: any) => item.id !== list[i].id)
      } else if (showPopUp.value) {
        // 彈窗正在展示，放進待彈出列表
        waitPopupList.value.push(list[i])
        tempList = tempList.filter((item: any) => item.id !== list[i].id)
      } else {
        popup.value = list[i]
        showPopUp.value = true
        if (list[i].path && list[i].path.includes('json')) {
          setTimeout(
            path => {
              showAnimation(imgDomain + path)
            },
            200,
            list[i].path
          )
        }
        tempList = tempList.filter((item: any) => item.id !== list[i].id)
        return tempList
      }
    }
  }
  return tempList.length === list.length ? false : tempList
}

function showAnimation(path: string) {
  animation = lottie.loadAnimation({
    container: document.querySelector('#newkiPopup')!,
    renderer: 'svg',
    loop: false,
    autoplay: false,
    path: path,
  })
  animation.play()
}

function close() {
  animation && animation?.destroy()
  let popupStorageMap = JSON.parse(popupStorage.value || '{}')
  if (!popupStorageMap[popup.value.id]) {
    popupStorageMap[popup.value.id] = {}
  }
  if (popup.value.popupFrequency === PopupFrequency.ever) {
    popupStorageMap[popup.value.id].hasShow = false
    popupStorageMap[popup.value.id].time = dayjs().format('YYYY-MM-DD')
  } else if (popup.value.popupFrequency === PopupFrequency.once) {
    popupStorageMap[popup.value.id].hasShow = true
    popupStorageMap[popup.value.id].time = dayjs().format('YYYY-MM-DD')
  } else if (popup.value.popupFrequency === PopupFrequency.day) {
    popupStorageMap[popup.value.id].hasShow = true
    popupStorageMap[popup.value.id].time = dayjs().format('YYYY-MM-DD')
  }
  popupStorage.value = JSON.stringify(popupStorageMap)
  showPopUp.value = false
  const timer = setTimeout(() => {
    clearTimeout(timer)
    popupDeal()
    pagePopup()
    if (isLogin.value) {
      needLoginPopup()
    }
    if (popupNode.value === 'login') {
      loginPopup()
    }
    if (popupNode.value === 'pay') {
      payPopup()
    }
    if (popupNode.value === 'register') {
      registerPopup()
    }
    waitPopup()
  }, 500)
}
</script>

<style scoped lang="scss">
.float-btn-container {
  position: fixed;
  right: 8px;
  bottom: 200px;
  z-index: 2000;
  opacity: 0.85;
  transform: translateX(calc(50% + 8px));
  transition: transform 0.3s ease-in-out, opacity 0.15s ease;

  &.show {
    opacity: 1;
    transform: translateX(0);
  }

  .float-btn-item {
    @include cursor();
    margin-top: 16px;

    :deep(.van-badge) {
      min-width: 16px;
      height: 16px;
      line-height: 1;
    }

    .badge-num {
      padding: 0;
      display: block;
      font-size: 13px;
      transform: scale(calc(10 / 13));
      line-height: 1;
      padding: 0;
      border: none;
    }
  }
  .float-btn-animation {
    width: 50px;
    height: 50px;
  }
}
#newkiPopup {
  width: 100%;
  height: 360px;
}
.close-icon {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 30px;
}
.van-popup {
  margin: 0 auto;
  background: transparent;
  max-width: 100%;
}
</style>
