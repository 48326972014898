<template>
  <van-popup v-model:show="show" round teleport="body" :close-on-click-overlay="false" :style="{ width: '90%' }">
    <van-picker
      :title="t('site.selectLanguage')"
      :columns="languageList"
      @confirm="onConfirm"
      cancel-button-text=""
      :visible-option-num="3"
    >
      <template #cancel>
        <span></span>
      </template>
    </van-picker>
  </van-popup>
</template>

<script setup lang="ts">
import '@vant/touch-emulator'
import { useSystemStore } from '~/store/system'
import { showFailToast } from 'vant'
const { t } = useI18n()
const { changeLanguageSelect } = useSystemStore()
const show = ref(false)
const { languageList } = storeToRefs(useSystemStore())

function onConfirm(callBack: any) {
  const { selectedValues } = callBack
  // 當前站點
  const { currentSite } = storeToRefs(useSystemStore())
  // 需要先有站點信息
  if (currentSite.value) {
    changeLanguageSelect(false, selectedValues[0], currentSite.value)
  } else {
    showFailToast(t('site.selectSiteFirst'))
  }
  closeModal()
}

function closeModal() {
  show.value = false
}

function showModal() {
  show.value = true
}

defineExpose({
  showModal,
})
</script>

<style scoped></style>
