<template>
  <div class="text-container">
    <div class="text">{{ text }}</div>
  </div>
</template>

<script setup lang="ts">
import { ChatActionType } from '@/enum/chat'
import { useImStore } from '~/store/im'

const { setSpecialMessage } = useImStore()
defineProps({
  text: {
    type: String,
    required: false,
    default: '',
  },
})
</script>

<style scoped lang="scss">
.text-container {
  max-width: 100%;
  border-radius: 12px;
  background-color: #fff;
  display: flex;
  flex-direction: column;

  .text {
    font-size: 15px;
    line-height: 16px;
    color: #111827;
  }
}
</style>
