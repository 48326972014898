<template>
  <van-popup
    class="login-dialog-float login-dialog-widget"
    v-model:show="show"
    round
    teleport="body"
    :close-on-click-overlay="false"
    :lock-scroll="false"
  >
    <div class="title">
      <span>{{ active === 'setPwd' ? t('login.setPwd') : t('login.welcome') }}</span>
      <NewkiSvg name="cross" color="#64748B" :size="16" @click="cancel" v-if="showClose" />
    </div>
    <div class="login-container" :class="{ 'no-margin': isWechat }">
      <van-tabs v-model:active="active" ref="tabsRef" animated>
        <van-tab name="smsLogin">
          <SmsLogin
            v-if="active === 'smsLogin'"
            :is-dialog="true"
            @pwdLogin="pwdLogin"
            @setPwd="setTabPwd"
            @cancel="close"
          />
        </van-tab>
        <van-tab name="pwdLogin">
          <PwdLogin v-if="active === 'pwdLogin'" :is-dialog="true" @smsLogin="smsLogin" @cancel="close" />
        </van-tab>
        <van-tab name="setPwd">
          <SetPwd v-if="active === 'setPwd'" :is-dialog="true" @smsLogin="smsLogin" @cancel="close" />
        </van-tab>
      </van-tabs>
    </div>
    <ThirdLogin ref="thirdLoginRef" v-if="isWechat" />
    <NewkiLineSvg class="bottom-line" />
  </van-popup>
</template>

<script setup lang="ts">
import { computed, provide } from 'vue'
import SmsLogin from '~/components/login/SmsLogin.vue'
import PwdLogin from '~/components/login/PwdLogin.vue'
import SetPwd from '~/components/login/SetPwd.vue'
import ThirdLogin from '~/components/login/ThirdLogin.vue'
import type { TabsInstance } from 'vant'
import NewkiLineSvg from '~/components/NewkiLineSvg.vue'
import { getPlatForm } from '~/utils/getPlatForm'

// 彈框對應類型,對應顯示的內容
type loginType = 'smsLogin' | 'pwdLogin' | 'setPwd'

provide('dialog', true)

const props = defineProps({
  showClose: {
    type: Boolean,
    required: false,
    default: true,
  },
})

const tabsRef = ref<TabsInstance>()
const thirdLoginRef = ref<InstanceType<typeof ThirdLogin> | null>(null)
const { t } = useI18n()
const show = ref(false)
const emit = defineEmits(['callBack'])
const isWechat = computed(() => {
  return getPlatForm() === 'WeChat'
})

let active = ref<loginType>('smsLogin')

function showModal(name: loginType = 'smsLogin') {
  active.value = name
  show.value = true
  if (isWechat.value) {
    weChatLogin()
  }
}

function weChatLogin() {
  const timer = setTimeout(() => {
    clearTimeout(timer)
    // weChatLoginState 微信登錄狀態，默認為0，即主動登錄，授權失敗，或登出設為1，之後手動登入
    const weChatLoginState = useLocalStorage('weChatLoginState', '0').value
    if (weChatLoginState === '0') {
      thirdLoginRef.value.weChatLogin()
      show.value = false
    }
  }, 500)
}

const smsLogin = () => {
  tabsRef.value?.scrollTo('smsLogin')
}

const pwdLogin = () => {
  tabsRef.value?.scrollTo('pwdLogin')
}

const setTabPwd = () => {
  tabsRef.value?.scrollTo('setPwd')
}

const cancel = () => {
  show.value = false
  emit('callBack', true)
}

const close = () => {
  active.value = 'smsLogin'
  show.value = false
  emit('callBack', false)
}

defineExpose({
  showModal,
})
</script>

<style scoped lang="scss">
:deep(.van-tabs__wrap) {
  display: none;
}
.login-dialog-float {
  width: 90%;
}
.title {
  display: flex;
  justify-content: space-between;
  padding: 20px 16px;

  :deep(.nuxt-icon) {
    @include cursor();
  }
}
.login-container {
  padding: 0 16px;
  margin-bottom: 40px;
}

.no-margin {
  margin-bottom: 0;
}
.bottom-line {
  position: fixed;
  bottom: 0;
  width: 100%;
}
</style>
