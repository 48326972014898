// 内容组件的动作类型、跳转映射
export enum ActionType {
  INDEX = 'F001', // 首页
  CART = 'F002', // 购物车
  NEWKI = 'F003', // 家品歷
  MY = 'F004', // 個人中心
  PRODUCT_DETAIL = 'F005', // 商品详情
  POST_DETAIL = 'F006', // 帖文頁
  EXTERNAL_LINK = 'F007', // 鏈接
  PROMOTION = 'F008', // 宣傳頁
  ACTIVITY = 'F009', // 活動頁
  CHAT = 'F010', // 客服
  POPCHAT = 'F011', // 客服浮窗
}
