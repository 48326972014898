<template>
  <div v-if="props.price" class="vip-price-container">
    <div class="vip-price" :class="{ small: props.small }">
      <NewkiPrice :price="props.price" :bold="true" :color="textDefault" :price-size="15" :currey-size="15" />
    </div>
    <div class="tag" :class="{ small: props.small }">{{ t('common.vipPrice') }}</div>
  </div>
</template>

<script setup lang="ts">
import { useColorsStore } from '~/store/colors'

const { textDefault } = storeToRefs(useColorsStore())
const { t } = useI18n()
const props = defineProps({
  price: {
    type: [String, Number],
    required: true,
    default: 0,
  },
  small: {
    type: Boolean,
    required: false,
    default: false,
  },
})
</script>

<style scoped lang="scss">
.vip-price-container {
  // min-width: 130px;
  height: 25px;
  box-sizing: border-box;
  display: inline-flex;
  border-radius: 999px;
  background: $vip-gray;
  align-items: center;

  .vip-price {
    @include flex(row, flex-start, center);
    display: inline-flex;
    padding: 0 8px;
    height: 100%;
    background: $vip-bg;
    border: 1px solid $vip-border-orange;
    border-radius: 999px;
  }

  .tag {
    min-width: 50px;
    padding: 0 10px 0 3px;
    font-size: 14px;
    font-weight: 700;
    background: $vip-bg;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-style: italic;
  }

  .small {
    line-height: 1;
  }
}
</style>
