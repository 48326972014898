import { ref } from 'vue'

const log = console.log

export function useStyle(data: any) {
  const widgetStyle: any = ref({})
  const maskStyle: any = ref({})
  const nodeStyle: any = ref({})
  const imgStyle: any = ref({})
  const imgBoxStyle: any = ref({})

  const unit = 'px'

  // 组装背景图数据，类型为数组
  let bgUrl: string[] = []
  const bgItem = data?.bgItems ?? []
  bgUrl = bgItem.map((item: any) => {
    return `url(${item.serverPath})`
  })

  widgetStyle.value = {
    paddingLeft: data?.widgetStyle?.paddingLeft + unit,
    paddingTop: data?.widgetStyle?.paddingTop + unit,
    paddingRight: data?.widgetStyle?.paddingRight + unit,
    paddingBottom: data?.widgetStyle?.paddingBottom + unit,

    marginTop: data?.widgetStyle?.marginTop + unit,
    marginBottom: data?.widgetStyle?.marginBottom + unit,
    backgroundImage: bgUrl.join(','),
  }

  nodeStyle.value = {
    paddingLeft: data?.nodeStyle?.paddingLeft + unit,
    paddingTop: data?.nodeStyle?.paddingTop + unit,
    paddingRight: data?.nodeStyle?.paddingRight + unit,
    paddingBottom: data?.nodeStyle?.paddingBottom + unit,
    borderRadius: data?.nodeStyle?.borderRadius + unit,
  }

  imgStyle.value = {
    borderRadius: data?.nodeStyle?.borderRadius + unit,
  }

  imgBoxStyle.value = {
    paddingLeft: data?.nodeStyle?.paddingLeft + unit,
    paddingTop: data?.nodeStyle?.paddingTop + unit,
    paddingRight: data?.nodeStyle?.paddingRight + unit,
    paddingBottom: data?.nodeStyle?.paddingBottom + unit,
  }

  maskStyle.value = {
    left: data?.nodeStyle?.paddingLeft + unit,
    top: data?.nodeStyle?.paddingTop + unit,
    right: data?.nodeStyle?.paddingRight + unit,
    bottom: data?.nodeStyle?.paddingBottom + unit,
    borderRadius: data?.nodeStyle?.borderRadius + unit,
  }

  return { widgetStyle, nodeStyle, maskStyle, imgStyle, imgBoxStyle }
}

export function useShowNum(data: any) {
  // 控制最大显示个数
  const showNumber: Ref<number> = ref(999)

  // 显示模式
  const showMode: Ref<string> = ref('Normal') // Normal Expand

  showNumber.value = Number(data.columns) * Number(data.expandLine)
  showNumber.value === 0 ? (showMode.value = 'Normal') : (showMode.value = 'Expand')

  if (data.columns == '1') {
    showMode.value = 'Normal'
  }

  return { showNumber, showMode }
}

export function useShowMode(data: any) {
  const widgetLayout: any = ref({
    display: 'grid',
    'grid-template-columns': '1fr',
  })

  let gtc = '1fr'
  let i = 0
  while (i < Number(data.columns) - 1) {
    gtc += ' 1fr'
    i++
  }

  widgetLayout.value = {
    display: 'grid',
    'grid-template-columns': gtc,
  }

  return widgetLayout
}
