/**
 * @description: oss切圖規則處理
 * @param {String} url - 需要處理的圖片地址
 * @param {Object} options - 切圖規則，參考以下地址
 * @param {String} domain - 圖片域，默認取`useRuntimeConfig().public.oss`
 * @return {String} 返回處理后的地址
 */
/*
  文档：
    https://help.aliyun.com/document_detail/44688.html
  一些示例：
    缩放 resize：.jpeg?x-oss-process=image/resize,m_lfit,w_400,h_712
    质量变化 quality：.jpg?x-oss-process=image/resize,w_100/quality,Q_80
    格式转换 format：.png?x-oss-process=image/resize,w_400,h_712,m_lfit/quality,q_100/format,jpg
*/

import { ref } from 'vue'
import { useBroswerStore } from '~/store/broswer'

export const useOSSImage = (
  url: string,
  options: any = null,
  domain: string = useRuntimeConfig().public.oss
): string => {
  // OSS 质量系数 1 ~ 100，默认 100
  const q = ref(100)

  // 设备像素比: 1、2、3 ... 控制图片裁剪比例
  const dpr = ref(1)
  if (!import.meta.env.SSR) {
    // 最小值為 2
    // dpr.value = Math.max(2, window.devicePixelRatio)
    dpr.value = 2
  }

  // 真实的屏幕宽度
  const { appWidth, isWebpSupportOnImgTag, isWebpSupportBrowser } = storeToRefs(useBroswerStore())

  let _appWidth = 0

  if (isClient()) {
    _appWidth = 370
  } else {
    _appWidth = 370
  }

  if (!url) return ''

  // 視頻封面圖
  if (url.includes('mp4')) {
    return url + '?x-oss-process=video/snapshot,t_0,f_jpg,m_fast'
  }

  const protocolReg = /(http:\/\/|https:\/\/)/
  const OSS = '?x-oss-process=image'
  const hasProtocol: boolean = protocolReg.test(url)

  // if (hasProtocol && !url.includes(domain)) {
  //   console.log('url:', url)
  //   return url
  // }

  const hasRules: boolean = url.includes(OSS)

  // 构造默认规则，如有传入的 options 就使用 options
  options = {
    url: url,
    oss: OSS,
    dpr: dpr.value,
    q: q.value,
    w: _appWidth, // 默认参数
    h: _appWidth, // 默认参数
    m: 'lfit',
    type: 'webp',
    limit: 0,
    format: false,
    ...options,
  }

  // 如果开启了 webp，则检查是否支持
  if (options.type === 'webp') {
    isWebpSupportOnImgTag.value.isSupport || isWebpSupportBrowser.value
      ? options.type === 'webp'
      : options.type === 'NoFormat'
  }

  // 如果开启渐进式图片，则使用 jpg 格式
  if (options.interlace || options.progressive) {
    options.type = 'jpg'
  }

  if (url.includes('.webp') && hasProtocol) {
    return url
  } else if (url.includes('.webp') && !hasProtocol) {
    return domain + url
  } else if (hasProtocol && hasRules) {
    return url
  } else if (hasProtocol && !hasRules) {
    return url + addRules(url, false, options)
  } else {
    return domain + url + addRules(url, false, options)
  }
}

// 创建规则
const addRules = (url: string, state: boolean = false, options: any): string => {
  // if (import.meta.env.DEV) {
  //   console.log('所需的全部参数', options)
  // }

  // 非oss的url
  if (!url.includes('aliyuncs')) return ''

  // 删除测试用的 newkiId
  delete options.newkiId

  const _resize = resizeRules(options)

  const _quality = qualityRules(options)

  const _format = formatRules(options)

  const _interlace = _interlaceRules(options)

  const imgUrl = (state ? '&' : options.oss) + _resize + _quality + _interlace + _format

  // if (import.meta.env.DEV) {
  //   console.log('OSS Image Url:', imgUrl)
  // }

  return imgUrl
}

// 图片裁剪
const resizeRules = (options: any) => {
  type resizeOption = {
    w?: string | number
    h?: string | number
    l?: string | number
    s?: string | number
    Q?: string | number
    m?: 'lfit' | 'mfit' | 'fill' | 'pad' | 'fixed'
    limit?: 0 | 1
  }

  let url = ''

  // resize 裁剪：
  const resizeRule: resizeOption = {
    w: Math.ceil(Number(options.w) * options.dpr),
    // h: Math.ceil(Number(options.h) * options.dpr),
    l: options.l,
    s: options.s,
    m: options.m,
    limit: options.limit,
  }

  // console.log('resizeRule:', resizeRule)

  // resize 参数：
  for (const rule in resizeRule) {
    if (resizeRule[rule as keyof resizeOption] || rule === 'w') {
      const width = resizeRule[rule as keyof resizeOption] == 0 ? 370 : resizeRule[rule as keyof resizeOption]
      url += `,${rule}_${width}`
    }
  }
  return `/resize${url}`
}

// 质量变换
const qualityRules = (options: any) => {
  type qualityOption = {
    q?: string | number
    Q?: string | number
  }

  let url = ''

  // quality 质量：
  const qualityRule: qualityOption = {
    q: options.q,
    Q: options.Q,
  }

  // quality 参数：
  for (const rule in qualityRule) {
    if (qualityRule[rule as keyof qualityOption]) {
      url += `,${rule}_${qualityRule[rule as keyof qualityOption]}`
    }
  }
  return `/quality${url}`
}

// 格式变换：当前单独对 png 转成 jpg
const formatRules = (options: any) => {
  // 无需转格式
  // if (!options.format) return ''

  let type: 'jpg' | 'png' | 'webp' | 'bmp' | 'gif' | 'tiff' | 'heic' | 'avif' | 'NoFormat' = 'jpg'
  type = options.type

  const url = type === 'NoFormat' ? '' : `/format,${type}`

  return url
}

// 漸進顯示
const _interlaceRules = (options: any): string => {
  if (options.interlace || options.progressive) {
    return '/interlace,1'
  }
  return ''
}
