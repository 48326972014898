<template>
  <svg
    width="56.000008"
    height="56.000015"
    viewBox="0 0 56 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <defs />
    <ellipse id="Ellipse 187" cx="28.000000" cy="28.000008" rx="28.000004" ry="28.000008" fill="#FFFFFF" />
    <ellipse
      id="Ellipse 187"
      cx="28.000000"
      cy="28.000008"
      rx="27.000004"
      ry="27.000008"
      stroke="#00AEEB"
      stroke-width="2.000000"
    />
    <mask
      id="mask1394_978"
      mask-type="alpha"
      maskUnits="userSpaceOnUse"
      x="2.153809"
      y="2.077148"
      width="52.000000"
      height="52.000000"
    >
      <circle id="Ellipse 185" cx="28.153809" cy="28.077148" r="26.000000" fill="#FFFFFF" />
    </mask>
    <g mask="url(#mask1394_978)">
      <path
        id="Rectangle 494"
        d="M22.4699 50.3444L34.3241 50.3444C34.8952 50.3444 35.2657 49.7421 35.0082 49.2323L34.587 48.3986C34.4566 48.1405 34.1921 47.9777 33.9029 47.9777L22.8911 47.9777C22.6019 47.9777 22.3373 48.1405 22.2069 48.3986L21.7858 49.2323C21.5283 49.7421 21.8988 50.3444 22.4699 50.3444Z"
        fill-rule="evenodd"
        fill="#000000"
      />
      <ellipse
        id="Ellipse 158"
        rx="8.966149"
        ry="5.029054"
        transform="matrix(1 0 0 1 28.3993 45.3153)"
        fill="#00AEEB"
      />
      <ellipse
        id="Ellipse 155"
        rx="22.116501"
        ry="20.707869"
        transform="matrix(1 0 0 1 28.3973 26.0866)"
        fill="#00AEEB"
      />
      <path
        id="Subtract"
        d="M11.1787 39.0865C15.2329 43.7888 21.4397 46.7965 28.3967 46.7965C40.6113 46.7965 50.5132 37.5252 50.5132 26.0886C50.5132 19.9935 47.7007 14.5134 43.2246 10.724C46.2885 14.2776 48.1231 18.7992 48.1231 23.7219C48.1231 35.1585 38.2212 44.4298 26.0066 44.4298C20.3017 44.4298 15.1013 42.4074 11.1787 39.0865Z"
        clip-rule="evenodd"
        fill-rule="evenodd"
        fill="#1D97D5"
        fill-opacity="1.000000"
      />
      <path
        id="Ellipse 161"
        d="M23.2341 46.6197Q15.7119 44.964 10.8455 39.2841Q5.89755 33.5091 5.89755 26.0866Q5.89755 17.343 12.4966 11.1642Q19.0849 4.99551 28.3973 4.99551Q37.7096 4.99551 44.2979 11.1642Q50.897 17.343 50.897 26.0866Q50.897 33.5376 45.915 39.3238Q41.0136 45.0164 33.4512 46.6435L33.2899 45.8942Q40.6007 44.3213 45.3342 38.8237Q50.1305 33.2531 50.1306 26.0866Q50.1306 17.6753 43.7741 11.7237Q37.4068 5.76196 28.3973 5.76196Q19.3877 5.76195 13.0205 11.7237Q6.664 17.6753 6.664 26.0866Q6.664 33.2257 11.4275 38.7855Q16.1271 44.2706 23.3988 45.8712L23.2341 46.6197Z"
        fill-rule="evenodd"
        fill="#000000"
      />
      <path
        id="Ellipse 162"
        d="M37.3654 31.7074C37.3654 36.7364 33.6813 45.3154 28.3993 45.3154C23.1173 45.3154 19.4331 36.7364 19.4331 31.7074C19.4331 27.6228 23.1173 24.9034 28.3993 24.9034C33.6813 24.9034 37.3654 27.6228 37.3654 31.7074Z"
        fill-rule="evenodd"
        fill="#AACE37"
      />
      <path
        id="Ellipse 162"
        d="M27.1819 45.9604Q27.7782 46.0818 28.3993 46.0818Q29.0203 46.0818 29.6167 45.9604Q32.9619 45.2792 35.5289 40.7769Q38.1319 36.2117 38.1319 31.7074Q38.1319 30.0932 37.5108 28.7706Q36.8114 27.281 35.3241 26.1613Q32.635 24.1369 28.3993 24.1369Q24.1636 24.1369 21.4745 26.1613Q19.9872 27.281 19.2877 28.7707Q18.6667 30.0932 18.6667 31.7074Q18.6667 36.2117 21.2696 40.7769Q23.8367 45.2792 27.1819 45.9604ZM28.3993 45.3154C33.6813 45.3154 37.3654 36.7364 37.3654 31.7074C37.3654 27.6228 33.6813 24.9034 28.3993 24.9034C23.1173 24.9034 19.4331 27.6228 19.4331 31.7074C19.4331 36.7364 23.1173 45.3154 28.3993 45.3154Z"
        fill-rule="evenodd"
        fill="#000000"
      />
      <ellipse
        id="Ellipse 156"
        rx="3.885332"
        ry="3.845749"
        transform="matrix(1 0 0 1 19.7318 46.4986)"
        fill="#00AEEB"
      />
      <path
        id="Subtract"
        d="M16.7086 48.9142C17.4208 49.7865 18.5106 50.3443 19.7319 50.3443C21.8778 50.3443 23.6173 48.6225 23.6173 46.4985C23.6173 45.2811 23.0457 44.1958 22.1538 43.4911C22.6929 44.1513 23.0157 44.9917 23.0157 45.9067C23.0157 48.0307 21.2762 49.7525 19.1304 49.7525C18.2145 49.7525 17.3727 49.4389 16.7086 48.9142Z"
        clip-rule="evenodd"
        fill-rule="evenodd"
        fill="#1D97D5"
        fill-opacity="1.000000"
      />
      <path
        id="Ellipse 159"
        d="M22.8438 49.3932Q21.5788 50.7276 19.7318 50.7276Q17.9648 50.7276 16.7148 49.4903Q15.4632 48.2515 15.4632 46.4986Q15.4632 44.7458 16.7148 43.5069Q17.9648 42.2696 19.7318 42.2696Q23.545 42.2696 23.9731 46.0184L23.2116 46.1054Q22.8611 43.0361 19.7318 43.0361Q16.2296 43.0361 16.2296 46.4986Q16.2296 49.9611 19.7318 49.9611Q21.2493 49.9611 22.2875 48.8659L22.8438 49.3932ZM22.2883 48.8941L22.2875 48.8659C22.4352 48.7101 22.6735 48.7038 22.8293 48.8514C22.985 48.999 22.9914 49.2374 22.8438 49.3932L22.8155 49.3939L22.2883 48.8941ZM23.9509 46.0008L23.9731 46.0184C23.9974 46.2316 23.849 46.4183 23.6358 46.4426C23.4226 46.467 23.2359 46.3186 23.2116 46.1054L23.2292 46.0832L23.9509 46.0008Z"
        fill-rule="evenodd"
        fill="#000000"
      />
      <ellipse id="Ellipse 157" rx="3.885332" ry="3.845749" transform="matrix(1 0 0 1 37.064 46.4986)" fill="#00AEEB" />
      <path
        id="Subtract"
        d="M34.628 43.4984C34.0947 44.1568 33.7758 44.9926 33.7758 45.902C33.7758 48.026 35.5153 49.7478 37.6611 49.7478C38.584 49.7478 39.4316 49.4293 40.0981 48.8974C39.3861 49.7766 38.2919 50.3396 37.0649 50.3396C34.9191 50.3396 33.1796 48.6178 33.1796 46.4938C33.1796 45.2833 33.7447 44.2034 34.628 43.4984Z"
        clip-rule="evenodd"
        fill-rule="evenodd"
        fill="#1D97D5"
        fill-opacity="1.000000"
      />
      <path
        id="Ellipse 160"
        d="M33.9287 49.3932Q35.1936 50.7276 37.0407 50.7276Q38.8076 50.7276 40.0576 49.4903Q41.3092 48.2515 41.3092 46.4986Q41.3092 44.7458 40.0576 43.5069Q38.8076 42.2696 37.0407 42.2696Q33.2275 42.2696 32.7994 46.0184L33.5609 46.1054Q33.9114 43.0361 37.0407 43.0361Q40.5428 43.0361 40.5428 46.4986Q40.5428 49.9611 37.0407 49.9611Q35.5232 49.9611 34.4849 48.8659L33.9287 49.3932ZM34.4842 48.8941L34.4849 48.8659C34.3373 48.7101 34.0989 48.7038 33.9432 48.8514C33.7874 48.999 33.781 49.2374 33.9287 49.3932L33.957 49.3939L34.4842 48.8941ZM32.8215 46.0008L32.7994 46.0184C32.775 46.2316 32.9234 46.4183 33.1367 46.4426C33.3499 46.467 33.5365 46.3186 33.5609 46.1054L33.5433 46.0832L32.8215 46.0008Z"
        fill-rule="evenodd"
        fill="#000000"
      />
      <path
        id="Rectangle 493"
        d="M22.528 49.7529L34.2659 49.7529C34.958 49.7529 35.4932 50.36 35.4065 51.0466L34.4591 58.5489C34.4107 58.9321 34.0848 59.2194 33.6987 59.2194L23.0953 59.2194C22.7091 59.2194 22.3833 58.9321 22.3349 58.5489L21.3874 51.0466C21.3007 50.36 21.8359 49.7529 22.528 49.7529Z"
        fill-rule="evenodd"
        fill="#A9CD36"
      />
      <path
        id="Subtract"
        d="M22.2715 58.0335L22.3363 58.5462C22.3847 58.9293 22.7105 59.2166 23.0967 59.2166L33.7001 59.2166C34.0863 59.2166 34.4121 58.9293 34.4605 58.5462L35.4079 51.0439C35.4946 50.3572 34.9594 49.7502 34.2673 49.7502L34.2203 49.7502C34.2192 49.7867 34.2163 49.8236 34.2116 49.8608L33.2642 57.3631C33.2158 57.7462 32.89 58.0335 32.5038 58.0335L22.2715 58.0335Z"
        clip-rule="evenodd"
        fill-rule="evenodd"
        fill="#98B22A"
        fill-opacity="1.000000"
      />
      <path
        id="Rectangle 495"
        d="M22.528 49.7529L34.2659 49.7529C34.958 49.7529 35.4932 50.36 35.4065 51.0466L34.4591 58.5489C34.4107 58.9321 34.0848 59.2194 33.6987 59.2194L23.0953 59.2194C22.7091 59.2194 22.3833 58.9321 22.3349 58.5489L21.3874 51.0466C21.3007 50.36 21.8359 49.7529 22.528 49.7529ZM22.528 50.5193L34.2659 50.5193Q34.3524 50.5193 34.4243 50.5518Q34.4962 50.5842 34.5534 50.6491Q34.6106 50.714 34.6338 50.7894Q34.657 50.8647 34.6461 50.9506L33.6987 58.4529L23.0953 58.4529L22.1478 50.9506Q22.137 50.8647 22.1602 50.7894Q22.1834 50.714 22.2406 50.6491Q22.2978 50.5842 22.3697 50.5518Q22.4415 50.5193 22.528 50.5193Z"
        fill-rule="evenodd"
        fill="#000000"
      />
      <path
        id="Vector 56"
        d="M14.9485 16.0284L22.7192 20.4658L14.9485 24.6073"
        stroke="#000000"
        stroke-width="1.532879"
        stroke-linejoin="round"
        stroke-linecap="round"
      />
      <path
        id="Vector 57"
        d="M42.1446 16.0284L34.374 20.4658L42.1446 24.6073"
        stroke="#000000"
        stroke-width="1.532879"
        stroke-linejoin="round"
        stroke-linecap="round"
      />
    </g>
  </svg>
</template>

<script setup lang="ts"></script>

<style scoped lang="scss"></style>
