export enum SmsCodeEnum {
  register = '101',
  login = '102',
  setPwd = '103',
  findPwd = '104',
  bindPhone = '201',
  setPhone = '202',
  setNickname = '203',
  setAvatar = '204',
}
