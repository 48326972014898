import { useSystemStore } from '~/store/system'

export const SetSiteInfo = (siteId?: string, languageCode?: string, sendWay?: string) => {
  const { toggleSiteSelectShow, changeSiteSelect, changeLanguageSelect, querySiteAreaCode, setSendWay } =
    useSystemStore()
  if (siteId) {
    // 設置當前站點
    // toggleSiteSelectShow(false)
    changeSiteSelect(siteId)
    // 設置區號列表
    querySiteAreaCode(siteId)
  }

  if (siteId && languageCode) {
    // 設置當前語言
    changeLanguageSelect(false, languageCode, siteId)
  }

  if (sendWay) {
    setSendWay(JSON.stringify(sendWay))
  }
}
