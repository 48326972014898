import { $axios } from '../index'
/**
 * @description: 獲取收藏
 */
export const apiGetCollect = () => {
  return $axios()
    .post(`:mall/app/favoriteSku/get`)
    .then((res: any) => {
      return res.data
    })
    .catch(() => {
      return null
    })
}

/**
 * @description: 批量添加收藏
 * @param {Array} data
 * @param {string} data.mallSkuId skuId
 * @param {string} data.status status
 * @param {string} data.addDetailId addDetailId
 */
export const apiAddBatchCollect = (data: any) => {
  return $axios()
    .post(`:mall/app/favoriteSku/addBatch`, data)
    .then((res: any) => {
      return 'success'
    })
    .catch(() => {
      return null
    })
}

/**
 * @description: 移除失效商品
 */
export const apiRemoveCollectInvalid = () => {
  return $axios()
    .post(`:mall/app/favoriteSku/removeInvalid`)
    .then((res: any) => {
      return res.data
    })
    .catch(() => {
      return null
    })
}
/**
 * @description: 移除其他站點商品
 */
export const apiRemoveCollectOtherSite = () => {
  return $axios()
    .post(`:mall/app/favoriteSku/removeOtherSite`)
    .then((res: any) => {
      return res.data
    })
    .catch(() => {
      return null
    })
}
/**
 * @description: 取消收藏
 * @param {Array} data 商品收藏明細id
 */
export const apiRemoveCollect = (data: string[]) => {
  return $axios()
    .post(`:mall/app/favoriteSku/deleteBatch`, data)
    .then((res: any) => {
      return res.data
    })
    .catch(() => {
      return null
    })
}

/**
 * @description: 取消收藏
 * @param {Array} skuId
 */
export const apiRemoveCollectBySkuId = (data: string[]) => {
  return $axios()
    .post(`:mall/app/favoriteSku/deleteBatchBySkuIds`, data)
    .then((res: any) => {
      return res.data
    })
    .catch(() => {
      return null
    })
}

/**
 * @description: 收藏分頁查詢
 * @param pageNum{number} :当前页码
 * @param data{Object} : 查詢參數
 */
export const apiQueryCollect = (pageNum: number = 1, data = {}) => {
  return $axios()
    .post(`:mall/app/favoriteSku/query?pageNum=${pageNum}&pageSize=10`, data)
    .then((res: any) => {
      return res.data
    })
    .catch(() => {
      return null
    })
}
