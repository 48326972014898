import { computed } from 'vue'
import Bowser from 'bowser'

export const useBroswerStore = defineStore('broswer', () => {
  const broswerInfo = ref({})

  const clientWidth = ref(0)
  // 使用 img 标签检测的 webp 支持方法
  const isWebpSupportOnImgTag = ref({
    isSupport: true,
  })

  // 使用浏览器检测的 webp 支持方法
  const isWebpSupportBrowser: Ref<boolean | undefined> = ref(true)

  function setClientWidth(width: number) {
    clientWidth.value = width
  }

  /* 
    检查是否支持 webp 图像显示。参考：https://developers.google.cn/speed/webp/faq?hl=zh-cn#html5_picture_element 
    feature 为检测的功能：lossy 有损压缩, lossless 无损压缩, alpha 通道, animation 动画.
  */
  function setIsSupportsWebP(feature: 'lossy' | 'lossless' | 'alpha' | 'animation' = 'lossy') {
    const kTestImages: any = {
      lossy: 'UklGRiIAAABXRUJQVlA4IBYAAAAwAQCdASoBAAEADsD+JaQAA3AAAAAA',
      lossless: 'UklGRhoAAABXRUJQVlA4TA0AAAAvAAAAEAcQERGIiP4HAA==',
      alpha:
        'UklGRkoAAABXRUJQVlA4WAoAAAAQAAAAAAAAAAAAQUxQSAwAAAARBxAR/Q9ERP8DAABWUDggGAAAABQBAJ0BKgEAAQAAAP4AAA3AAP7mtQAAAA==',
      animation:
        'UklGRlIAAABXRUJQVlA4WAoAAAASAAAAAAAAAAAAQU5JTQYAAAD/////AABBTk1GJgAAAAAAAAAAAAAAAAAAAGQAAABWUDhMDQAAAC8AAAAQBxAREYiI/gcA',
    }

    const img = new Image()

    img.onload = () => {
      const result = img.width > 0 && img.height > 0
      isWebpSupportOnImgTag.value = {
        isSupport: true || result,
      }
    }

    img.onerror = () => {
      isWebpSupportOnImgTag.value = {
        isSupport: false,
      }
    }

    img.src = 'data:image/webp;base64,' + kTestImages[feature]
  }

  /*
    使用 bowser 来检测浏览器情况
    文档：https://bowser-js.github.io/bowser/docs/
  */
  function setBroserInfo() {
    const browser = Bowser.getParser(window.navigator.userAgent)
    // console.log(`The current browser name is "${browser.getBrowserName()}"`)
    broswerInfo.value = browser
    isWebpSupportBrowser.value = browser.satisfies({
      // per platform (mobile, desktop or tablet)
      mobile: {
        safari: '>=14',
        android: '>=4',
        chrome: '>=25',
        samsung_internet: '>=4',
      },
      chrome: '>=17',
      edge: '>=18',
      firefox: '>=65',
      opera: '>=11.10',
      qq: '>=9.0',
    })
  }

  const appWidth = computed(() => {
    const { isMobile } = useDevice()

    let width: number = 0
    if (isMobile) {
      width = clientWidth.value
    } else {
      width = clientWidth.value > 400 ? 400 : clientWidth.value
    }
    return width
  })

  return {
    broswerInfo,
    appWidth,
    clientWidth,
    isWebpSupportOnImgTag,
    isWebpSupportBrowser,
    setClientWidth,
    setIsSupportsWebP,
    setBroserInfo,
  }
})
