import type { routerParams } from '@/types/router'
import debounce from 'lodash/debounce'

const _routerPush = async (name: string, params?: routerParams) => {
  const router = useRouter()
  name = analyzeRouteName(name)

  await router?.push({
    name,
    query: params?.query,
    params: params?.params,
  })
}

export const routerPush = debounce(_routerPush, 300, { leading: true })
