import { routerPush } from '@/composables/routerPush'
import { ActionType } from '@/enum/actionType'
import type { IActionType } from '@/types/IActionType'
import { useSystemStore } from '~/store/system'
import { ref, watch } from 'vue'

const log = console.log

export const useContentAction = (maybeRef: any) => {
  const data = toValue(maybeRef)
  const { updateChatDialog, updateChatPopup } = useSystemStore()
  const { actionCode, actionContent, actionContentCode } = data
  const actionData: IActionType = { actionCode, actionContent, actionContentCode }
  const isLogin = ref(false) // 是否登錄

  watch(
    () => useIsLogin(),
    (val, oldVal) => {
      isLogin.value = !!val
    },
    { immediate: true, deep: true }
  )
  // 動作數據，來自用內容組件數據
  // log('actionData', actionData)

  // 執行內容
  switch (actionCode!) {
    // 首頁
    case ActionType.INDEX:
      routerPush('index')
      break

    // 購物車
    case ActionType.CART:
      routerPush('cart')
      break

    // 家品曆
    case ActionType.NEWKI:
      routerPush('newki-id', { params: { id: actionContentCode || '' } })
      break

    case ActionType.MY:
      routerPush('my')
      break

    // 商品詳情頁
    case ActionType.PRODUCT_DETAIL:
      routerPush('product-skuId-contentId', { params: { skuId: actionContentCode } })
      break

    // 貼文詳情頁
    case ActionType.POST_DETAIL:
      routerPush('post-detail-id', { params: { id: actionContentCode } })
      break

    // 外鏈跳轉
    case ActionType.EXTERNAL_LINK:
      const target = actionContent.includes('newki') ? '_self' : '_blank'
      window.open(actionContent, target, 'rel=noopener noreferrer')
      break

    case ActionType.PROMOTION:
      routerPush('promotion-code', { params: { code: actionContentCode } })
      break

    case ActionType.ACTIVITY:
      routerPush('activity-code', { params: { code: actionContentCode } })
      break

    case ActionType.CHAT:
      routerPush('chat')
      break

    case ActionType.POPCHAT:
      if (!isLogin.value) {
        updateChatDialog(true)
      } else {
        updateChatPopup(true)
      }
      break
  }
}
