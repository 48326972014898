export const useComputedFontSize = (el: HTMLElement = document.documentElement) => {
  const computedFontSize = () => {
    const htmlFontSize = window.getComputedStyle(el, null).getPropertyValue('font-size')
    const scaledFontSize = parseInt(htmlFontSize)
    const scaleFactor = 15 / scaledFontSize
    const fontSize = scaledFontSize * scaleFactor * scaleFactor
    document.documentElement.style.fontSize = fontSize + 'px'
    // console.log('Calcaluted Font Size Success~')
    return fontSize
  }
  return computedFontSize()
}
