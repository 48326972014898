/**
 * @description 分析路由名称
 * @param name
 * @returns new name
 */
export const analyzeRouteName = (name: string) => {
  const siteCode = useRoute()?.meta?.siteCode
  const i18nCode = useRoute()?.meta?.i18nCode

  if (!siteCode && !i18nCode) return name

  if (siteCode && !i18nCode) return `${siteCode}__${name}`

  return `${siteCode}__${i18nCode}__${name}`
}
