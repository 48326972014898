export const usePopupStore = defineStore('popup', () => {
  const popupNode = ref('') // 觸發節點

  function setPopupNode(data: string) {
    popupNode.value = data
  }

  return {
    popupNode,
    setPopupNode,
  }
})
