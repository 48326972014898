<template>
  <div class="text-container">
    <div class="text">{{ content.text }}</div>
    <div v-for="(item, index) in content.buttons" :key="index" class="action" @click="action(item)">
      {{ item.title }}
    </div>
  </div>
</template>

<script setup lang="ts">
import { ChatActionType } from '@/enum/chat'
import { useImStore } from '~/store/im'

const { setSpecialMessage } = useImStore()
defineProps({
  content: {
    type: Object,
    required: false,
    default: () => {},
  },
})

function action(data: any) {
  if (data.url) {
    window.open(data.url)
  } else {
    let params = {
      type: 'text',
      autoReplyCode: data.payload.autoMsgTemplateCode,
      content: data.title,
    }
    setSpecialMessage(params)
  }
}
</script>

<style scoped lang="scss">
.text-container {
  max-width: 100%;
  border-radius: 12px;
  background-color: #fff;
  display: flex;
  flex-direction: column;

  .text {
    padding: 12px;
    font-size: 15px;
    line-height: 16px;
    color: #111827;
  }
  .action {
    padding: 8px 20px;
    font-size: 15px;
    text-align: center;
    color: #00aeeb;
    border-top: 1px solid #f6f6f6;

    &:hover {
      cursor: pointer;
    }
  }
}
</style>
