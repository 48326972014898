<template>
  <div class="box" :class="{ radius: isDialog }">
    <div class="box-left">
      <img src="~/assets/images/common/newki-avatar.svg" width="38" height="38" />
      <div class="text-box">
        <span class="text-head-regular">{{ t('chat.title') }}</span>
        <span class="tips">{{ t('chat.navTitle') }}</span>
      </div>
    </div>
    <div class="box-right">
      <NewkiSvg name="cross" :size="20" color="#111827" @click="close" />
      <NewkiSvg v-show="isDialog" name="full-screen" :size="20" color="#111827" @click="fullScreen" />
    </div>
  </div>
  <NewkiLineSvg />
</template>

<script setup lang="ts">
defineProps({
  isDialog: {
    type: Boolean,
    required: false,
    default: true,
  },
})

const { t } = useI18n()

const emit = defineEmits(['close', 'fullScreen'])

function close() {
  emit('close')
}

function fullScreen() {
  emit('fullScreen')
}
</script>

<style scoped lang="scss">
.box {
  position: relative;
  z-index: 10;
  display: flex;
  width: 100%;
  height: 60px;
  box-shadow: $shadow;
  padding: 0px 0px 0px $pageSpace;
  background-color: $white;
}
.radius {
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
}
.box-left {
  flex-grow: 1;
  display: flex;
  align-items: center;
  grid-gap: $space-sm;
  height: 100%;
}
.box-right {
  width: 70px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: row-reverse;

  :deep(.nuxt-icon) {
    @include cursor();
  }
}
.text-box {
  display: flex;
  flex-direction: column;
}
.tips {
  @extend .text-body;
  color: $text-color-assist;
}
</style>
