import { useI18n } from 'vue-i18n'
export const numberFormat = (number: string | number, fixed: number = 2) => {
  const { t } = useI18n()
  if (+number <= 9999) {
    if (Number.isInteger(Number(number))) {
      return `${Number(number)}`
    } else {
      return `${parseFloat(Number(number).toFixed(fixed))}`
    }
  } else if (+number <= 99999999) {
    let result = 0
    if (Number.isInteger(Number(number) / 10000)) {
      result = Number(number) / 10000
    } else {
      result = parseFloat((Number(number) / 10000).toFixed(fixed))
    }
    return `${result}${t('number.tenThousand')}`
  } else {
    let result = 0
    if (Number.isInteger(Number(number) / 100000000)) {
      result = Number(number) / 100000000
    } else {
      result = parseFloat((Number(number) / 100000000).toFixed(fixed))
    }
    return `${result}${t('number.hundredMillion')}`
  }
}
