export const useHistory = defineStore('history', () => {
  const routerHistory: any = ref([])

  const setRouterHistory = (data: any) => {
    routerHistory.value?.push(data)
  }

  const cleanRouterHistory = () => {
    routerHistory.value = []
  }

  return {
    routerHistory,
    setRouterHistory,
    cleanRouterHistory,
  }
})
