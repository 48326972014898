import dsBridge from 'dsbridge'
export default defineNuxtPlugin(() => {
  // 喚起APP ，執行指定操作
  dsBridge.register('operationSync', function (res: any) {
    try {
      let funcName = ''
      let data = null
      let type
      res = res.split('?data=')

      funcName = res[0]
      data = res[1]
      const { name, params, query } = JSON.parse(decodeURIComponent(data))
      switch (funcName) {
        case 'page':
          name && routerPush(name, { params, query })
          break
        case 'pushData':
          break
        default:
          break
      }
    } catch (e) {
      console.log(e)
    }
  })
  // 判斷按返回是否要退出APP
  dsBridge.register('canGoBackAsync', function () {
    try {
      const route = useRoute()
      const list = route.name.split('__')
      const name = list[list.length - 1]
      // 退出app
      const quitList = ['index', 'cart', 'my', 'newki-id']
      // 返回首頁
      const homeList = ['category', 'pay-success-id']
      if (quitList.includes(name)) {
        return JSON.stringify({
          code: 200,
          data: {
            quit: true,
          },
        })
      } else if (homeList.includes(name)) {
        routerReplace('index')
      } else {
        routerBack()
      }
      return JSON.stringify({
        code: 200,
        data: {
          quit: false,
        },
      })
    } catch (e) {
      console.log(e)
    }
  })
  return {
    provide: {
      jsBridge: dsBridge,
    },
  }
})
