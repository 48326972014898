<template>
  <SectionCard title="新品熱品" text="newki" :style="widgetStyle">
    <div class="new-hot-widget">
      <!-- 正常數據展示 -->
      <div class="box">
        <div class="box-view">
          <div class="viewer">
            <NuxtLink class="goods-box" :to="onGoodItemClick()" :class="{ active: isGoodShow }">
              <div class="good-animate" :class="{ active: isGoodShow }">
                <div class="good-img" :class="{ active: isGoodShow }">
                  <img :src="goodsItem.imgUrl ?? HolderImage" alt="" />
                </div>
                <div class="good-text" :class="{ active: isGoodShow }">
                  <div>{{ goodsItem.name }}</div>
                </div>
              </div>
            </NuxtLink>
            <NewkiVideo
              ref="NewkiVideoRef"
              :url="videoOption.url"
              :block="videoOption.block"
              :seekedStatus="videoOption.seekedStatus"
              :showMuteIcon="videoStatus === 'play'"
              :playerOptions="{
                controls: false,
              }"
              @onVideoStatusChange="handleVideoStatusChange"
              v-if="videoOption.url"
            />
          </div>
        </div>
        <div class="box-list">
          <div class="box-item" v-for="(item, index) in newList" @click="onItemClick(item, index)">
            <div class="item">
              <NewTag class="new" v-if="item.skuId" />
              <SkipLoop class="loop" :status="videoStatus" v-if="item.skuId === activeId && index === activeIndex" />
              <div class="poster" v-if="item.goodsPicture">
                <a :href="onOneItemUrl(item)" @click.prevent="onItemClick(item, index)">
                  <img
                    :src="useOSSImage(item.goodsPicture, { dpr: 1, q: 100 })"
                    width="50"
                    height="50"
                    alt=""
                    onerror="this.style.display='none'"
                  />
                </a>
              </div>
            </div>
          </div>
          <div class="box-item" v-for="(item, index) in hotList" @click="onItemClick(item, index)">
            <div class="item">
              <HotTag class="hot" v-if="item.skuId" />
              <SkipLoop class="loop" :status="videoStatus" v-if="item.skuId === activeId && index === activeIndex" />
              <div class="poster" v-if="item.goodsPicture">
                <a :href="onOneItemUrl(item)" @click.prevent="onItemClick(item, index)">
                  <img
                    :src="useOSSImage(item.goodsPicture, { dpr: 1, q: 100 })"
                    width="50"
                    height="50"
                    alt=""
                    onerror="this.style.display='none'"
                  />
                  <!-- <NewkiImage :src="item.goodsPicture" width="50" height="50" /> -->
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </SectionCard>
</template>

<script setup lang="ts">
import { ref, watch, onMounted, nextTick } from 'vue'
import NewTag from '~/components/render/NewTag.vue'
import HotTag from '~/components/render/HotTag.vue'
import SkipLoop from '~/components/SkipLoop.vue'
import NewkiVideo from '~/components/NewkiVideo.vue'
import SectionCard from '~/components/render/SectionCard.vue'
import { apiGetSkuNewHot } from '~/service/api/home'
import { useStyle } from './renderHooks'
import HolderImage from '~/assets/images/empty/normal.svg'

const props = defineProps({
  wData: {
    type: Object,
    required: false,
    default: () => {},
  },
})

// 播放器 ref
const NewkiVideoRef: any = ref(null)

// 当前激活的 id、index
const activeId = ref('')
const activeIndex = ref(0)

// 是否显示商品
const isGoodShow = ref(false)

// 是否正在加載中
const isLoading = ref(true)

// 商品详情
const goodsItem: any = ref({})

// 播放器配置
const videoOption = ref({
  url: '',
  poster: '',
  seekedStatus: 'pause',
  block: true,
})

// 新品热品数据
const newList: any = ref([])
const hotList: any = ref([])

// 临时定时器
const goodShowT: any = ref(null)

// 组件样式
const widgetStyle: any = useStyle(props.wData).widgetStyle

// 项目单击
const onItemClick = async (item: any, index: number) => {
  if (!item?.id) return false
  pick(item, index)
  // console.log(item)
  await nextTick()
  NewkiVideoRef.value?.videoReload()
  NewkiVideoRef.value?.doMuted(false)
  handleGoodShow()
}

// 項目SEO鏈接
const onOneItemUrl = (item: any) => {
  return routerNuxtLink('product-skuId', { params: { skuId: item.skuId } })
}

// 视频播放动画状态
const videoStatus = ref('')

const onGoodItemClick = () => {
  return routerNuxtLink('product-skuId', { params: { skuId: goodsItem.value.id } })
}

const pick = (item: any, index: number) => {
  if (!item?.id) return false
  activeId.value = item?.skuId
  activeIndex.value = index
  videoOption.value = {
    url: item?.goodsVideo,
    poster: item?.goodsPicture,
    seekedStatus: 'pause',
    block: true,
  }

  goodsItem.value = {
    id: item?.skuId,
    name: item?.goodsName,
    imgUrl: item?.goodsPicture,
  }
}

// 切换商品展示状态状态
const toggleGoodShow = (show: boolean = !isGoodShow.value) => {
  isGoodShow.value = show
}

// 处理商品动画
const handleGoodShow = () => {
  toggleGoodShow(false)
  clearTimeout(goodShowT.value)

  goodShowT.value = setTimeout(() => {
    clearTimeout(goodShowT.value)
    toggleGoodShow(true)
  }, 50)
}

const handleVideoStatusChange = (data: any) => {
  switch (data.eventName) {
    case 'play':
      toggleGoodShow(true)
      videoStatus.value = 'play'
      break
    case 'pause':
      videoStatus.value = 'pause'
      break
    case 'ended':
      videoStatus.value = 'ended'
      break
    default:
      toggleGoodShow(false)
      break
  }
}

// 服務端調用
const { data } = await useAsyncData(`apiGetSkuNewHot-${useNanoid(6)}`, () => apiGetSkuNewHot(), {
  lazy: true,
  default: () => ({
    newList: [{}, {}, {}, {}],
    hotList: [{}],
  }),
})

watch(
  data,
  val => {
    // console.log('NewHot Data:', val)

    // 新品
    if (val?.newGoods?.length) {
      newList.value = val?.newGoods
    } else {
      newList.value = []
    }

    // 熱品
    if (val?.hotGoods?.length) {
      hotList.value = val?.hotGoods
    } else {
      hotList.value = []
    }

    if (val?.newGoods?.length === 0 && val?.hotGoods?.length === 0) {
      newList.value = [{}, {}, {}, {}]
      hotList.value = [{}]
    }

    // 激活第一个新品、或者激活第一个热品
    if (val.newGoods) {
      pick(val.newGoods[0], 0)
    } else if (val.hotGoods) {
      pick(val.hotGoods[0], 0)
    } else {
      // do nothing
    }
  },
  { immediate: true }
)

onMounted(() => {})
</script>

<style scoped lang="scss">
.new-hot-widget {
  @include flex(column, flex-start, center);
  width: 100%;

  .box {
    display: grid;
    grid-template-columns: 80% 20%;
    width: 100%;

    .box-view {
      position: relative;
      z-index: 10;
      padding-bottom: 125%;

      .viewer {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 10;
        border-radius: 12px;
        overflow: hidden;
        background-color: #e1e1e1;

        .goods-box {
          display: none;
          gap: 8px;
          position: absolute;
          right: 4px;
          bottom: 4px;
          left: 4px;
          z-index: 12;
          $size: 32px;
          height: 56px;
          transition: all 0.2s ease;

          &.active {
            @include flex(row, flex-start, center);
          }

          .good-animate {
            @include flex(row, flex-start, center);
            width: 54px;
            height: 56px;
            padding: 6px;
            border-radius: 28px;
            background-color: rgba(100, 100, 100, 0.65);
            opacity: 0;

            @keyframes show {
              from {
                opacity: 0;
              }
              to {
                opacity: 1;
              }
            }

            @keyframes square {
              to {
                border-radius: 12px;
              }
            }

            @keyframes move {
              from {
                width: 54px;
              }
              to {
                width: 100%;
              }
            }

            &.active {
              animation: show 0.3s ease-in-out 2s 1 forwards, move 0.3s ease-in-out 3s 1 forwards,
                square 0.2s ease-in-out 3.2s 1 forwards;
            }

            .good-img {
              width: 42px;
              height: 42px;
              border-radius: 21px;
              background-color: #fff;
              overflow: hidden;

              img {
                display: block;
                width: 42px;
                height: 42px;
              }

              @keyframes gSquare {
                to {
                  border-radius: 6px;
                }
              }

              &.active {
                animation: gSquare 0.3s ease-in-out 3.2s 1 forwards;
              }
            }

            .good-text {
              @include line(2);
              flex: 1;
              font-size: $text-md;
              color: $white;
              opacity: 0;

              @keyframes textShow {
                to {
                  opacity: 1;
                  margin-left: 8px;
                }
              }

              &.active {
                animation: textShow 0.3s ease-in-out 3.3s 1 forwards;
              }
            }
          }
        }
      }
    }

    .box-list {
      @include flex(column, space-evenly, flex-end);
      width: 100%;

      .box-item {
        position: relative;
        z-index: 10;
        width: 84%;
        padding-bottom: 84%;
        border-radius: 6px;
        cursor: pointer;

        .item {
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          z-index: 10;
          border-radius: 6px;
          background-color: #e1e1e1;

          .loop {
            z-index: 12;
          }

          .new,
          .hot {
            position: absolute;
            top: -4px;
            left: -6px;
            z-index: 13;
          }

          .poster {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            z-index: 10;
            background-position: center;
            background-size: contain;
            background-repeat: no-repeat;
            border-radius: 6px;
            overflow: hidden;

            img {
              display: block;
              width: 100%;
              height: 100%;
              object-fit: contain;
            }
          }
        }
      }
    }
  }
}
</style>
