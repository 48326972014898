<template>
  <span class="new">NEW</span>
</template>

<style scoped lang="scss">
.new {
  padding: 2px 4px;
  color: $white;
  font-size: 10px;
  font-weight: 500;
  font-style: italic;
  line-height: 1;
  background: linear-gradient(180deg, rgb(187, 216, 95), rgb(170, 206, 55) 100%);
  border: 1px solid $white;
  border-radius: 99px;
}
</style>
