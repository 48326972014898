import { $axios } from '../index'
/**
 * @description: 添加購物車
 */
export const apiAddCart = (data: any) => {
  return $axios()
    .post(`:mall/app/shopCart/add`, data)
    .then((res: any) => {
      return res.data
    })
    .catch(() => {
      return null
    })
}
/**
 * @description: 批量添加購物車
 */
export const apiAddBatchCart = (data: any) => {
  return $axios()
    .post(`:mall/app/shopCart/addBatch`, data)
    .then((res: any) => {
      return res.data
    })
    .catch(() => {
      return null
    })
}
/**
 * @description: 批量添加換購品
 */
export const apiAddBatchExchange = (data: any) => {
  return $axios()
    .post(`:mall/app/shopCart/addBatchExchange`, data)
    .then((res: any) => {
      return res.data
    })
    .catch(() => {
      return null
    })
}
/**
 * @description: 獲取購物車數據
 */
export const apiGetCart = (data?: any) => {
  return $axios()
    .post(`:mall/app/shopCart/get`, data)
    .then((res: any) => {
      return res.data
    })
    .catch(() => {
      return null
    })
}

/**
 * @description: 勾選購物車
 * @param data.id{number}: 購物車明細Id
 * @param data.isSelected{string}: 商品是否選中 1-是，0-否
 */
export const apiSelectCart = (data: any) => {
  return $axios()
    .post(`:mall/app/shopCart/select`, data)
    .then((res: any) => {
      return res.data
    })
    .catch(() => {
      return null
    })
}

/**
 * @description: 全選購物車
 * @param isSelected{string}: 商品是否選中 1-是，0-否
 */
export const apiSelectAllCart = (data: any) => {
  return $axios()
    .post(`:mall/app/shopCart/selectAll`, data)
    .then((res: any) => {
      return res.data
    })
    .catch(() => {
      return null
    })
}

/**
 * @description: 購物車數量更改
 * @param id{string}: 購物車明細id
 * @param qty{string}: 數量
 */
export const apiUpdateCartQty = (id: string, qty: string, sourceType?: string, sourceId?: string) => {
  return $axios()
    .post(`:mall/app/shopCart/updateQty`, { id, qty, sourceType, sourceId })
    .then((res: any) => {
      return res.data
    })
    .catch(() => {
      return null
    })
}

/**
 * @description: 購物車刪除
 * @param ids{Array}: 購物車明細id
 */
export const apiDeleteCart = (ids: Array<string>) => {
  return $axios()
    .post(`:mall/app/shopCart/deleteBatch`, ids)
    .then((res: any) => {
      return res.data
    })
    .catch(() => {
      return null
    })
}
/**
 * @description: 購物車修改
 * @param oldId{string}: 購物車明細id
 * @param newId{string}: skuID
 */
export const apiUpdateCart = (data: any) => {
  return $axios()
    .post(`:mall/app/shopCart/updateSku`, data)
    .then((res: any) => {
      return res.data
    })
    .catch(() => {
      return null
    })
}
/**
 * @description: 移除失效商品
 */
export const apiRemoveInvalid = () => {
  return $axios()
    .post(`:mall/app/shopCart/removeInvalid`)
    .then((res: any) => {
      return res.data
    })
    .catch(() => {
      return null
    })
}
/**
 * @description:未登錄獲取購物車數據
 */
export const apiNotLoginAdd = (data: any) => {
  return $axios()
    .post(`:mall/app/shopCart/getNotLogin`, data)
    .then((res: any) => {
      return res.data
    })
    .catch(() => {
      return null
    })
}
/**
 * @description: 購物車分頁查詢
 * @param pageNum{number} :当前页码
 * @param data{Object} : 查詢參數
 */
export const apiQueryCart = (pageNum: number = 1, data = {}) => {
  return $axios()
    .post(`:mall/app/shopCart/queryDetail?pageNum=${pageNum}&pageSize=10`, data)
    .then((res: any) => {
      return res.data
    })
    .catch(() => {
      return null
    })
}
