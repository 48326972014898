export enum PopupType {
  popup = '1', // 僅彈窗
  float = '2', // 僅浮窗
  both = '3', // 彈窗+浮窗
}

export enum PopupTime {
  now = '1', // 立即觸發
  register = '2', // 註冊成功
  login = '3', // 登入成功
  pay = '4', // 支付成功
}

export enum PopupFrequency {
  once = '1', // 只彈出一次
  ever = '2', // 每次訪問都彈出
  day = '3', // 每天首次訪問彈出
}

export enum popUpPage {
  home = '1', // 首頁
  goods = '2', // 商品頁
  category = '3', // 品類頁
  cart = '4', // 購物車
  newki = '5', // 家品歷
  my = '6', // 我的
  searchResult = '7', // 搜索結果頁
  paySuccess = '8', // 支付成功頁
}
export enum PopupAction {
  home = 'F001', // 页面跳转(首頁) + 无执行内容、表单禁用
  cart = 'F002', // 页面跳转(購物車) + 无执行内容、表单禁用
  newki = 'F003', // 页面跳转(家品歷) + 无执行内容、表单禁用
  my = 'F004', // 页面跳转(個人中心) + 无执行内容、表单禁用
  goods = 'F005', // 页面跳转(商品详情) + 执行内容(具体商品)
  article = 'F006', // 页面跳转(帖文详情) + 执行内容(具体帖文id)
  link = 'F007', // 页面跳转(外链) + 执行内容(外链地址、需要 url 校验)、表单控件
  introduce = 'F008', // 页面跳转(宣传页) + 执行内容(具体id)
  activity = 'F009', // 页面跳转(活动页) + 执行内容(具体id)
  chat = 'F010', // 页面跳转(客服页) + 无执行内容、表单禁用
  popchat = 'F011', // 頁面彈窗 + 有执行内容，彈出內容
}
