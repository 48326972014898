<template>
  <SiteSelect ref="siteSelect" />
  <LanguageSelect ref="languageSelect" />
  <NewkiLoading v-model="loading" />
  <ChatDialog v-model="chatDialog" />
  <ChatPopup v-model="chatPopup" :mask-close="true" />
  <GetBetterPrice />
</template>

<script setup lang="ts">
import { watch } from 'vue'
import { useSystemStore } from '~/store/system'
import SiteSelect from '~/components/SiteSelect.vue'
import LanguageSelect from '~/components/LanguageSelect.vue'
import NewkiLoading from '~/components/NewkiLoading.vue'
import ChatDialog from '~/components/chat/ChatDialog.vue'
import ChatPopup from '~/components/chat/ChatPopup.vue'
import GetBetterPrice from '~/components/activity/getBetterPrice/Index.vue'

let { showLanguageSelect, loading, chatDialog, chatPopup } = storeToRefs(useSystemStore())

const siteSelect = ref<InstanceType<typeof SiteSelect> | null>(null)
const languageSelect = ref<InstanceType<typeof LanguageSelect> | null>(null)

watch(
  showLanguageSelect,
  val => {
    if (val) {
      languageSelect.value?.showModal()
    }
  },
  { immediate: true }
)
</script>

<style scoped></style>
