<template>
  <div class="item-box" :style="widgetStyle">
    <div class="post-item" :style="widgetLayout">
      <template v-for="item in content" :key="item.id">
        <Render :name="item.name" :wData="item" />
      </template>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted } from 'vue'
import { useStyle, useShowNum, useShowMode } from './renderHooks'
import { getPostTemplateContentById } from '~/service/api/post'
import Render from '~/components/render/Render.vue'

const props = defineProps({
  wData: {
    type: Object,
    required: false,
    default: {},
  },
})

// 模版内容
const content: any = ref([])

// 组件的布局
const widgetLayout: any = useShowMode(props.wData)

// 组件样式
const widgetStyle: any = useStyle(props.wData).widgetStyle

const postTemplateId: Ref<string> = ref(props.wData?.postTemplateId)

// 服務端調用
const { data } = await useAsyncData(
  `getPostTemplateContentById-${useNanoid(6)}`,
  () => getPostTemplateContentById(postTemplateId.value),
  {
    lazy: true,
    default: () => ({
      content: [],
    }),
  }
)

watch(
  data as any,
  val => {
    if (val?.content) {
      try {
        content.value = JSON.parse(val?.content)
      } catch {
        content.value = []
      }
    } else {
      content.value = []
    }
  },
  { immediate: true }
)
</script>

<style scoped lang="scss">
.item-box {
  position: relative;
  z-index: 10;
  padding: 10px;
  border-radius: 12px;
  box-shadow: $shadow;
  background-color: $white;
  background-size: cover;
  background-position: center center;

  .post-item {
    @include flex();
    gap: 10px;
    width: 100%;
    padding: 10px;

    .post-content {
      @include flex();
      font-size: 12px;
      color: #999;
    }
  }
}
</style>
