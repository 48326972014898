import type { RouteLocationRaw } from 'vue-router'
import type { routerParams } from '@/types/router'
import debounce from 'lodash/debounce'

const _routerReplace = async (name: string, params?: routerParams) => {
  // const router = useRouter()
  // const nuxtApp: any = useNuxtApp()
  // const route = useRoute()
  // const to: RouteLocationRaw = {}

  // name = analyzeRouteName(name)

  // name ? (to.name = name) : (to.name = route.name)
  // params?.params ? (to.params = params.params) : (to.params = route.params)
  // params?.query ? (to.query = params.query) : (to.query = route.query)
  // await router.replace(to)
  const router = useRouter()
  const route = useRoute()
  if (!name) {
    const list = route.name.split('__')
    name = list[list.length - 1]
  }
  name = analyzeRouteName(name)
  await router.replace({
    name,
    query: params?.query,
    params: params?.params,
  })
}

export const routerReplace = debounce(_routerReplace, 300, { leading: true })
