<template>
  <div class="third-login-container">
    <div class="title">
      <span class="title-text">{{ t('login.thirdLogin') }}</span>
    </div>
    <div class="third-login-content">
      <NewkiSvg name="wechat" :filled="true" :size="48" @click="check" />
    </div>
  </div>
</template>

<script setup lang="ts">
import NewkiSvg from '~/components/NewkiSvg.vue'
import { useMemberStore } from '~/store/member'
import { showFailToast } from 'vant'

const { isAgreeAgreement } = storeToRefs(useMemberStore())
const { t } = useI18n()

function check() {
  if (isAgreeAgreement.value) {
    weChatLogin()
  } else {
    showFailToast(`${t('login.agreementTip')}${t('login.ServiceAgreement')}和${t('login.PrivacyAgreement')}`)
  }
}

function weChatLogin() {
  // 獲取運行時配置
  const runtimeConfig = useRuntimeConfig()
  const weChatId = runtimeConfig.public.wechatLoginId
  // 換取微信授權code
  const url = runtimeConfig.public.webUrl + '/login/wechatBind'
  useLocalStorage('wx-login', '').value = window.location.href
  window.location.href = `https://blob.newki.com/wechat.html?appid=${weChatId}&redirect_uri=${encodeURIComponent(url)}`
}

defineExpose({
  weChatLogin,
})
</script>

<style scoped lang="scss">
.third-login-container {
  width: 100%;
  padding: 12px 16px;

  .title {
    @extend .text-tips;
    display: flex;
    justify-content: center;
    color: $text-color-assist;
    position: relative;
    .title-text {
      position: relative;
      z-index: 1;
      background-color: $white;
      padding: 0 10px;
    }
    &::before {
      content: '';
      width: 100%;
      height: 1px;
      background-color: $border-color;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 0;
    }
  }

  .third-login-content {
    padding-top: 8px;
    display: grid;
    //grid-template-columns: repeat(auto-fill, 48px);
    grid-column-gap: 10px;
    place-content: center;
  }
}
</style>
