<template>
  <div class="recommend" :style="widgetStyle">
    <div class="recommend-box">
      <div class="title-box">
        <div class="title">{{ t('common.recommend') }}：</div>
      </div>
      <div class="rs-box">
        <RecommendSwiper :items="historyList" name="noti-swiper" :option="swiperOption" />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { onMounted } from 'vue'
import { getRecommendYou } from '~/service/api/newki'
import RecommendSwiper from './RecommendSwiper.vue'
import { useStyle } from './renderHooks'

const swiperOption = ref({
  loop: false,
  spaceBetween: 20,
  watchSlidesProgress: true,
  slidesPerView: 'auto',
  centeredSlides: true,
  preloadImages: false,
  navigation: false,
  pagination: false,
  scrollbar: false,
  modules: ['Navigation', 'Pagination', 'Scrollbar', 'A11y', 'Autoplay', 'Mousewheel'],
})

const props = defineProps({
  wData: {
    type: Object,
    required: false,
    default: {},
  },
})

// 组件样式
const widgetStyle: any = useStyle(props.wData).widgetStyle

const { t } = useI18n()
const historyList = ref<any[]>([])

onMounted(() => {
  getData()
})

async function getData() {
  let data = await getRecommendYou()
  if (data) {
    historyList.value = data
  }
}

function goDetail(skuId: string) {
  routerPush('product-skuId-contentId', { params: { skuId: +skuId } })
}
</script>

<style scoped lang="scss">
.recommend {
  width: 100%;
  padding: 10px;
  background-color: $white;

  .recommend-box {
    @include flex(column, flex-start, center);
    position: relative;
    z-index: 10;

    .title-box {
      @include flex(row, flex-start, center);
      @extend .text-body;
      width: 100%;
      margin-bottom: 12px;
      font-size: 15px;
      color: $text-color-assist;

      .title {
      }
    }

    .rs-box {
      position: relative;
      z-index: 10;
      width: 100%;
    }
  }
}
</style>
