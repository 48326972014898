export const useLog = () => {
  if (!import.meta.env.PROD) return false

  const empty: Function = () => {}

  for (const key in window.console) {
    //@ts-ignore
    window.console[key] = empty
  }
  //@ts-ignore
  window.alert = empty
}
