import { apiAddBatchCollect, apiRemoveCollectBySkuId } from '~/service/api/collect'
import type { ICollect, ICommonGoodsDetails } from '~/types/ICollect'
import { computed } from 'vue'

export const useCollectStore = defineStore('collect', () => {
  const maxFavNum = ref(0)
  // 添加收藏的數量，因添加接口不返回數據，臨時記錄做數量加減
  const addNum = ref(0)
  const commonGoodsDetails = ref<ICommonGoodsDetails[] | null>(null)
  const invalidGoodsDetails = ref<ICommonGoodsDetails[] | null>(null)
  const otherSiteGoodsDetails = ref<ICommonGoodsDetails[] | null>(null)

  const hasCollectData = computed(() => {
    return (
      (invalidGoodsDetails.value?.length || 0) +
      (otherSiteGoodsDetails.value?.length || 0) +
      (commonGoodsDetails.value?.length || 0) +
      addNum.value
    )
  })

  // 是否有選中
  const hasSelect = computed(() => {
    if (commonGoodsDetails.value && commonGoodsDetails.value.length) {
      for (let i = 0; i < commonGoodsDetails.value!.length; i++) {
        if (commonGoodsDetails.value![i].isSelect) {
          return true
        }
      }
      return false
    } else {
      return false
    }
  })

  // 是否全選
  const isSelectAll = computed(() => {
    if (commonGoodsDetails.value && commonGoodsDetails.value.length) {
      for (let i = 0; i < commonGoodsDetails.value!.length; i++) {
        if (!commonGoodsDetails.value![i].isSelect) {
          return false
        }
      }
      return true
    } else {
      return false
    }
  })

  // 是否全沒選
  const isCancelSelectAll = computed(() => {
    if (commonGoodsDetails.value && commonGoodsDetails.value.length) {
      for (let i = 0; i < commonGoodsDetails.value!.length; i++) {
        if (commonGoodsDetails.value![i].isSelect) {
          return false
        }
      }
      return true
    } else {
      return true
    }
  })

  function setCollect(data: ICollect) {
    addNum.value = 0
    maxFavNum.value = data.maxFavNum
    commonGoodsDetails.value = data.commonGoodsDetails
    invalidGoodsDetails.value = data.invalidGoodsDetails
    otherSiteGoodsDetails.value = data.otherSiteGoodsDetails
  }

  function setSelected(id: string) {
    for (let i = 0; i < commonGoodsDetails.value!.length; i++) {
      if (commonGoodsDetails.value![i].id === id) {
        commonGoodsDetails.value![i].isSelect = commonGoodsDetails.value![i].isSelect ? 0 : 1
      }
    }
  }

  function setAllSelected(flag: boolean) {
    for (let i = 0; i < commonGoodsDetails.value!.length; i++) {
      commonGoodsDetails.value![i].isSelect = flag ? 1 : 0
    }
  }

  async function addCollect(params: any) {
    const data = await apiAddBatchCollect(params)
    if (data === 'success') {
      addNum.value += params.length
      return true
    } else {
      return false
    }
  }

  async function deleteCollect(params: any) {
    const data = await apiRemoveCollectBySkuId(params)
    if (data === 'success') {
      addNum.value = addNum.value - 1
      return true
    } else {
      return false
    }
  }

  return {
    maxFavNum,
    commonGoodsDetails,
    invalidGoodsDetails,
    otherSiteGoodsDetails,
    hasCollectData,
    addCollect,
    deleteCollect,
    setCollect,
    setSelected,
    isSelectAll,
    isCancelSelectAll,
    setAllSelected,
    hasSelect,
  }
})
