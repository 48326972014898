export const px2rem = (value: string | number) => {
  const num = Number(value)
  let rootSize = 15
  try {
    rootSize = useComputedFontSize()
  } catch (e: any) {
    rootSize = 15
  }
  return num / rootSize
}
