<script lang="ts">
import { h } from 'vue'
/*
  Vue type 類型請參考：
  https://github.com/vuejs/core/blob/main/packages/runtime-core/src/index.ts#L131
*/
import type { Component } from 'vue'

import Category from '~/components/render/Category.vue'
import Review from '~/components/render/Review.vue'
import Share from '~/components/render/Share.vue'
import Carousel from '~/components/render/Carousel.vue'
import NewkiCalendar from '~/components/render/NewkiCalendar.vue'
import Recommend from '~/components/render/Recommend.vue'
import NewHot from '~/components/render/NewHot.vue'
import ProductList from '~/components/render/ProductList.vue'
import Text from '~/components/render/Text.vue'
import RichText from '~/components/render/RichText.vue'
import Picture from '~/components/render/Picture.vue'
import PictureWindow from '~/components/render/PictureWindow.vue'
import Video from '~/components/render/Video.vue'
import Hotspot from '~/components/render/Hotspot.vue'
import BestVideo from '~/components/render/BestVideo.vue'
import TopNavigation from '~/components/render/TopNavigation.vue'
import PostTemplate from '~/components/render/PostTemplate.vue'
import BuyVip from '~/components/render/BuyVip.vue'

// dev 用的佔位組件
import Empty from '~/components/render/Empty.vue'
import ProductGroup from '~/components/render/ProductGroup.vue'

const WidgetMap: Record<string, Component> = {
  page: Empty,
  category: Category, // 品類
  newHot: NewHot, // 新品熱品
  carousel: Carousel, // 图片轮播
  evaluation: Review, // 品味評測
  share: Share, // 體驗分享
  picture: Picture, // 图片
  pictureWindow: PictureWindow, // 商品橱窗
  hotspot: Hotspot, // 图片热区
  richText: RichText, // 富文本
  text: Text, // 文本
  video: Video, // 视频
  template: PostTemplate,
  recommendCalendar: NewkiCalendar, // 家品曆
  recommend: Recommend, // 為你推薦
  productList: ProductList, // 商品列表
  notice: Empty,
  offlineShop: Empty,
  bestVideo: BestVideo, // 精选视频
  productGroup: ProductGroup, // 商品组
  topNavigation: TopNavigation, // 顶部导航
  buyVIP: BuyVip, // 購買會員
}

// 單個組件的渲染
const buildDom = (name: string, wData: any) => {
  return () => {
    let widget: Component = WidgetMap[name] as Component

    return h(widget, {
      key: wData?.id ?? wData?.key,
      wData: wData, // 組件內部同名的 props
    })
  }
}

export default {
  name: 'WidgetRender',
  props: {
    name: {
      type: String,
      required: true,
    },
    wData: {
      type: Object,
      required: false,
      default: () => {},
    },
  },
  setup(props) {
    return () => h(buildDom(props.name, props.wData))
  },
}
</script>
