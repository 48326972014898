<template>
  <NewkiErrorPage v-model="show" :code="error?.statusCode || 404" />
</template>

<script setup lang="ts">
import { onMounted } from 'vue'

const { t } = useI18n()

const timeCount = ref(3)

const show = ref(true)

const timer: any = ref(null)

const props = defineProps({
  error: Object,
})

useHead({
  bodyAttrs: {
    id: 'pc-container',
  },
})

onMounted(() => {
  console.log('Error:', props)
})
</script>

<style scoped lang="scss">
.error-container {
  @include flex(column, flex-start, center);
  height: 100%;
  background-color: $white;
  position: relative;

  .error-content {
    @include flex(column, center, center);
    flex: 1;
    width: 100%;
    padding: 55px 0 168px;

    .error-img {
      position: relative;
      z-index: 10;
      $size: calc(58%);
      width: $size;
      padding-bottom: $size;

      img {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 10;
        width: 100%;
        height: 100%;
      }
    }

    .error-tip {
      margin-top: 10px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .text-bold {
        font-size: 16px;
        font-weight: bold;
      }

      .text {
        color: $text-color-default;
        font-size: 15px;
      }

      &.code-500 {
        .text:nth-child(1) {
          font-size: 17px;
          margin-bottom: 10px;
        }
        .text:nth-child(2),
        .text:nth-child(3) {
          color: $text-color-assist;
        }
      }
    }
    .refresh {
      width: calc(190 / 375 * 100%);
      margin-top: 10px;
      .login-btn {
        border-bottom: 4px solid $secondary;
      }
    }
    .countdown {
      color: $price;
    }
  }
}

.logo {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
  width: 100%;
  padding-bottom: calc(env(safe-area-inset-bottom) + 20px);
  height: auto;

  img {
    width: 100%;
    height: 40px;
  }
}
</style>
