export default defineNuxtPlugin((nuxtApp: any) => {
  nuxtApp.vueApp.$nuxt.$router.beforeEach((to: any, from: any) => {
    const newkiQuery = {
      ni: '', // 邀請碼
      nu: '', // utm檔案
      comp: '', // 海關識別碼
    }
    if (to.query.ni || to.query.nu || to.query.comp) {
      setCookie(to.query.ni as string, to.query.nu as string, to.query.comp as string)
      return true
    } else {
      // 獲取cookie參數
      const ni = useCookie('newki-url-query-ni').value || ''
      const nu = useCookie('newki-url-query-nu').value || ''
      const comp = useCookie('newki-url-query-comp').value || ''
      // 獲取路由本身帶的參數
      newkiQuery.ni = (from.query.ni as string) || ni
      newkiQuery.nu = (from.query.nu as string) || nu
      newkiQuery.comp = (from.query.comp as string) || comp
      setCookie(newkiQuery.ni, newkiQuery.nu, newkiQuery.comp)
      if (newkiQuery.ni || newkiQuery.nu || newkiQuery.comp) {
        const params: any = { ...to.query }
        if (newkiQuery.ni) {
          params.ni = newkiQuery.ni
        }
        if (newkiQuery.nu) {
          params.nu = newkiQuery.nu
        }
        if (newkiQuery.comp) {
          params.comp = newkiQuery.comp
        }
        return { path: to.path, query: params }
      } else {
        return true
      }
    }
  })
  nuxtApp.vueApp.$nuxt.$router.afterEach((to: any, from: any) => {
    // console.log('全局路由进入守卫:', to)
    // useRouteHistory(from)
  })
})

function setCookie(ni: string, nu: string, comp: string) {
  // 設置cookie參數,有效期24小時
  const expire = 60 * 60 * 24
  useCookie('newki-url-query-ni', { maxAge: expire }).value = ni
  useCookie('newki-url-query-nu', { maxAge: expire }).value = nu
  // 長期有效
  useCookie('newki-url-query-comp').value = comp
}
