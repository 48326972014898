<template>
  <div class="text-render" :style="widgetStyle">
    <div class="text">{{ text }}</div>
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted } from 'vue'

const props = defineProps({
  wData: {
    type: Object,
    required: true,
  },
})

const text: Ref<string> = ref(props.wData?.items[0]?.text)

const widgetStyle: any = ref({})

// 计算外层容器的 padding
const calcWidgetStyle = (wData: any = props.wData) => {
  let unit = 'px'
  widgetStyle.value = {
    paddingLeft: wData?.widgetStyle?.paddingLeft + unit,
    paddingTop: wData?.widgetStyle?.paddingTop + unit,
    paddingRight: wData?.widgetStyle?.paddingRight + unit,
    paddingBottom: wData?.widgetStyle?.paddingBottom + unit,
  }
}

onMounted(() => {
  // console.log('wData:', props.wData)
  calcWidgetStyle()
})
</script>

<style scoped lang="scss">
.text {
  width: 100%;
  font-size: 14px;
  color: #333;
  white-space: pre-wrap;
}
</style>
