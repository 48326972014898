export const getPlatForm = () => {
  let ua: string = ''
  const userAgent = useRequestHeaders()
  if (userAgent['user-agent']) {
    ua = userAgent['user-agent']
  } else {
    ua = navigator.userAgent
  }
  if (ua.includes('FBAN') || ua.includes('FBAV')) {
    return 'Facebook'
  }
  if (ua.includes('MicroMessenger')) {
    return 'WeChat'
  }
  if (ua.includes('Takewant')) {
    return 'App'
  }
  if (ua.match(/AppleWebKit.*Mobile.*/)) {
    return 'H5'
  } else {
    return 'PC'
  }
}
