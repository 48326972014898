import { computed } from 'vue'
import type { ICart, ShopCartDetail } from '@/types/ICart'
import type { IString } from '@/types/IString'

export const useCartStore = defineStore('cart', () => {
  // 已優惠金額，合計金額=所有商品累加（（商品原單價-商品單價）*數量）
  const discountAmount = ref(0)
  // 已選合計金額，合計金額=所有商品累加（商品單價*數量）
  const totalAmount = ref(0)
  // 所有正常商品的合計
  const allTotalAmount = ref(0)
  // 購物車最大購買數量
  const maxCartNum = ref(0)
  // 換購品
  const exchangeGoodsDetails = ref<ShopCartDetail[] | null>(null)
  // 失效商品
  const invalidGoodsDetails = ref<ShopCartDetail[] | null>(null)
  // 非本站點商品
  const otherSiteGoodsDetails = ref<ShopCartDetail[] | null>(null)
  // 正常商品和促銷商品
  const promotionalGoodsDetails = ref<ShopCartDetail[] | null>(null)
  // 命中促銷
  const addDetail = ref<IString>({})
  // 是否編輯狀態
  const isEdit = ref(false)
  // 是否刪除狀態
  const isDelete = ref(false)

  // 購物車數量
  const cartNum = computed(() => {
    return (
      (promotionalGoodsDetails.value?.length || 0) +
      (invalidGoodsDetails.value?.length || 0) +
      (otherSiteGoodsDetails.value?.length || 0) +
      (exchangeGoodsDetails.value?.length || 0)
    )
  })

  // 有效的可購買的商品
  const validGoodsList = computed(() => {
    const list: ShopCartDetail[] = []
    const normalList = promotionalGoodsDetails.value || []
    const giftList = exchangeGoodsDetails.value || []
    return list.concat(normalList, giftList)
  })

  // 已勾選數量
  const selectedNum = computed(() => {
    let num = 0

    validGoodsList.value.forEach((item: any) => {
      if (item.isSelected === '1') {
        num++
      }
    })
    return num
  })

  // 是否全選
  const isSelectAll = computed(() => {
    return validGoodsList.value.length ? validGoodsList.value.length === selectedNum.value : false
  })

  // 設置購物車數據
  function setCartData(data: ICart) {
    // console.log('setCartData：', data)
    discountAmount.value = data.discountAmount
    totalAmount.value = data.totalAmount
    maxCartNum.value = data.maxCartNum
    promotionalGoodsDetails.value = data.promotionalGoodsDetails || null
    exchangeGoodsDetails.value = data.exchangeGoodsDetails || null
    invalidGoodsDetails.value = data.invalidGoodsDetails || null
    otherSiteGoodsDetails.value = data.otherSiteGoodsDetails || null
    promotionalGoodsDetails.value &&
      promotionalGoodsDetails.value.forEach((item: any) => {
        allTotalAmount.value += item.retailPrice * item.qty
      })
    addDetail.value = {}
    if (data.matchPromotionAddDetails) {
      handelChangeGift(data.matchPromotionAddDetails)
    }
  }

  function deleteInvalidGoods() {
    invalidGoodsDetails.value = []
  }

  // 清除購物車數據
  function clearCartData() {
    discountAmount.value = 0
    totalAmount.value = 0
    promotionalGoodsDetails.value = null
    exchangeGoodsDetails.value = null
    invalidGoodsDetails.value = null
    otherSiteGoodsDetails.value = null
    console.log('clearCartData')
  }

  // 處理換購數據
  function handelChangeGift(data: any) {
    for (let i = 0; i < data.length; i++) {
      if (data[i].addQty) {
        addDetail.value[data[i].id] = data[i].addQty
      }
    }
  }

  // 設置指定商品數量
  function setQty(id: string, qty: number) {
    const index = promotionalGoodsDetails!.value?.findIndex(item => item.id === id)
    const index1 = exchangeGoodsDetails!.value?.findIndex(item => item.id === id)
    if (index !== undefined && index > -1) {
      promotionalGoodsDetails.value![index].qty = qty
      return
    }
    if (index1 !== undefined && index1 > -1) {
      exchangeGoodsDetails.value![index1].qty = qty
      return
    }
  }

  // 改變購物狀態
  function setEdit(status: boolean) {
    isEdit.value = status
  }

  // 改變刪除狀態
  function setDelete(status: boolean) {
    isDelete.value = status
  }

  return {
    discountAmount,
    totalAmount,
    maxCartNum,
    exchangeGoodsDetails,
    invalidGoodsDetails,
    otherSiteGoodsDetails,
    promotionalGoodsDetails,
    cartNum,
    setCartData,
    selectedNum,
    isSelectAll,
    validGoodsList,
    isEdit,
    setEdit,
    setQty,
    addDetail,
    clearCartData,
    deleteInvalidGoods,
    allTotalAmount,
    setDelete,
    isDelete,
  }
})
