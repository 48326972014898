// 埋點事件統計相關
// phone 僅fb傳,  需加密處理
import { useMemberStore } from '~/store/member'
import { useSystemStore } from '~/store/system'
import { nanoid } from 'nanoid'
import sha256 from 'js-sha256'
import type { FB_INIT_PARAM } from '@/types/IFacebookParam'
import { init as apmInit, captureException, sendAPILog } from '@umengfe/apm'

type NewkiError = {
  title: string
  msg: any
}

type NewkiApiTraceType = {
  url: string // 请求地址
  method: number // 请求方法
  rc: number // 响应编码
  rt: number // 请求耗时（毫秒）
  traceid: string // 请求追踪id
  req_query: string // 入参query
  req_body: string // 入参body (仅状态码>400 且不等于0 采集上报，其它状态为空)
}

export default defineNuxtPlugin(nuxtApp => {
  let gaId = '' // 谷歌埋點ID
  let fbIdList: any[] = [] // FB埋點ID
  let czcId = '' // 友盟ID
  let czc_web_apm_id = '' // 友盟 U-APM id
  const memberStore = useMemberStore()
  let previousValue = memberStore.getMemberFullPhone

  function init() {
    const { config } = useSystemStore()
    let codeList = []

    if (!config.thirdPartCode) return

    // 解析中台配置的第三方代碼
    try {
      config.thirdPartCode && (codeList = JSON.parse(config.thirdPartCode))
    } catch (e) {
      console.log(e)
      // 解析異常時退出init
      return
    }

    if (codeList && codeList.length) {
      codeList.forEach((item: any) => {
        if (item['ga_id']) {
          gaId = item['ga_id']
        }
        if (item['fb_id']) {
          fbIdList = item['fb_id']
        }
        if (item['czc_id']) {
          czcId = item['czc_id']
        }
        if (item['czc_web_apm_id']) {
          czc_web_apm_id = item['czc_web_apm_id']
        }
      })
    }
    if (canUseGA() && gaId) {
      gaInit(gaId)
    }

    if (canUseFB() && fbIdList.length) {
      fbInit(fbIdList)
    }
    if (canUseCZC() && czcId) {
      czcInit(czcId)
    }
    if (canUseCZC() && czc_web_apm_id) {
      czcAPMInit(czc_web_apm_id)
    }
  }

  // 監聽會員手機號，變化時重新初始化FB
  memberStore.$subscribe(() => {
    const currentValue = memberStore.getMemberFullPhone

    if (previousValue !== currentValue) {
      previousValue = currentValue

      if (canUseFB() && fbIdList.length) {
        fbInit(fbIdList)
      }
    }
  })

  // fb 事件統計初始化
  async function fbInit(fbIdList: any[]) {
    const { config } = useSystemStore()
    let scriptNode = document.getElementById('facebookq') as HTMLScriptElement

    // 避免重複加載js
    if (!scriptNode) {
      scriptNode = document.createElement('script')
      scriptNode.id = 'facebookq'
      scriptNode.type = 'text/javascript'
      const code = `;(function (f, b, e, v, n, t, s) {
        if (f.fbq) return
        n = f.fbq = function () {
          n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments)
        }
        if (!f._fbq) f._fbq = n
        n.push = n
        n.loaded = !0
        n.version = '2.0'
        n.queue = []
        t = b.createElement(e)
        t.async = !0
        t.src = v
        s = b.getElementsByTagName(e)[0]
        s.parentNode.insertBefore(t, s)
      })(window, document, 'script', 'https://connect.facebook.net/en_US/fbevents.js')`
      scriptNode.appendChild(document.createTextNode(code))
      document.getElementsByTagName('head')[0].appendChild(scriptNode)
    }

    const fbc = useCookie('_fbc').value
    const fbp = useCookie('_fbp').value
    const external_id = memberStore.memberInfo.memberId || nanoid(8)
    const ph = previousValue ? sha256.sha256(previousValue) : ''
    const client_ip_address = config.ip || ''

    for (let i = 0; i < fbIdList.length; i++) {
      const param: FB_INIT_PARAM = {
        external_id,
        ph,
        fbc,
        fbp,
        client_user_agent: navigator.userAgent,
        client_ip_address,
      }

      // 如已初始化過，直接修改實例數據
      if (window.fbq && window.fbq.instance) {
        window.fbq.instance.pixelsByID[fbIdList[i]].userData.ph = ph
        window.fbq.instance.pixelsByID[fbIdList[i]].userData.external_id = external_id
      } else {
        await window.fbq('init', fbIdList[i], param)
      }
    }
    window.fbq('track', 'PageView')
  }

  // google 事件統計初始化
  async function gaInit(gaId: string) {
    const GASrc = `https://www.googletagmanager.com/gtag/js?id=${gaId}`
    await fetchScript(GASrc, 'google-analytics')
    const scriptNode = document.createElement('script')
    scriptNode.id = 'googleAnalytics'
    scriptNode.type = 'text/javascript'
    const code =
      'window.dataLayer = window.dataLayer || []; function gtag(){dataLayer.push(arguments);}' +
      `gtag('js', new Date()); gtag('config', '${gaId}');`
    scriptNode.appendChild(document.createTextNode(code))
    document.getElementsByTagName('head')[0].appendChild(scriptNode)
  }

  // 友盟 事件統計初始化
  function czcInit(czcId: string) {
    const scriptNode = document.createElement('script')
    scriptNode.id = 'cnzzAnalytics'
    scriptNode.type = 'text/javascript'
    const code = `var _czc = _czc || [];
      (function () {
      var um = document.createElement("script");
      um.src = "https://v1.cnzz.com/z.js?id=${czcId}&async=1";
      var s = document.getElementsByTagName("script")[0];
      s.parentNode.insertBefore(um, s);
      })();`
    scriptNode.appendChild(document.createTextNode(code))
    document.getElementsByTagName('head')[0].appendChild(scriptNode)
  }
  // 友盟 U-APM 事件統計初始化
  function czcAPMInit(czc_web_apm_id: string) {
    const params: any = {
      pageFilter: {
        mode: 'ignore', // 黑名单模式、即命中规则的不上报
        rules: [], // 空数组表示黑名单为空，日志全部上报
      },
      pid: czc_web_apm_id,
      logLevel: 0,
      traceKey: 'traceId',
    }
    apmInit(params)
    // console.log('czcAPMInit run：', params)

    try {
      /* 启动错误 */
      nuxtApp.hook('app:error', error => {
        newkiTrace({
          title: '启动错误（app:error）',
          msg: error,
        })
      })

      /* JS chunk 错误 */
      nuxtApp.hook('app:chunkError', error => {
        newkiTrace({
          title: 'JS chunk 错误（app:chunkError）',
          msg: error,
        })
      })

      /* 所有的 Vue 错误（基于onErrorCaptured） */
      nuxtApp.hook('vue:error', error => {
        newkiTrace({
          title: '所有的 Vue 错误（vue:error(onErrorCaptured)）',
          msg: error,
        })
      })

      // 客户端的 Promise 错误
      window.addEventListener('unhandledrejection', event => {
        newkiTrace({
          title: 'Promise Unhandledrejection 错误',
          msg: event,
        })
      })
    } catch (error) {
      newkiTrace({
        title: 'captureException 兜底的错误',
        msg: error,
      })
    }

    // console.log('✅ U-APM Init Done with params: ', params)
  }

  // 手动捕获 JavaScript 异常
  function newkiTrace(data: NewkiError) {
    let errorText: string = ''

    if (typeof data.msg === 'string' || typeof data.msg === 'number') {
      errorText = `${data.title}：${data.msg}`
    } else {
      errorText = `${data.title}：${JSON.stringify(data.msg) ?? data.msg}`
    }

    captureException(Error(errorText))

    console.log('NewkiTrace Run with data~ ✅:', errorText)
  }

  // 手动捕获 API 异常
  function newkiApiTrace(error: NewkiApiTraceType) {
    // 参考 https://developer.umeng.com/docs/193624/detail/432099#eoGCu 文档
    const codeMap: any = {
      GET: 1,
      POST: 2,
      PUT: 3,
      HEAD: 4,
      DELETE: 5,
      CONNECT: 6,
      OPTIONS: 7,
      TRACE: 8,
      PATCH: 9,
    }

    // 转换成数字再提交
    error.method = codeMap[String(error.method).toUpperCase()]
    sendAPILog(error)

    console.log('NewkiApiTrace Run with data~ ✅:', error)
  }

  // 能否使用google埋點
  function canUseGA(): boolean {
    const { config } = useSystemStore()
    const region = config.region
    return region !== 'Mainland'
  }

  // 能否使用fb埋點
  function canUseFB(): boolean {
    const { config } = useSystemStore()
    const region = config.region
    return region !== 'Mainland'
  }

  // 能否使用友盟
  function canUseCZC(): boolean {
    // const { config } = useSystemStore()
    // const region = config.region
    // 所有地區都可使用友盟
    return true
  }

  /**
   * @description: 查看商品
   * @param data { Object}: 埋點參數
   * @param data.spu_id {number}: 商城商品spu_id 必填
   * @param data.sku_id {number}: 商城商品sku_id 必填
   * @param data.content_id {number}: 內容檔案id
   * @param data.name {string}: 商品名稱- 必填
   * @param data.category1 {string}: 品類1名稱 必填
   * @param data.category2 {string}: 品類2名稱
   * @param data.category3 {string}: 品類3名稱
   * @param data.brand {string}: 商品基礎屬性中的商品品牌名稱 必填
   * @param data.currency {string}: 幣種 必填
   * @param data.price {number}: 購買單價 必填
   * @param data.quantity {number}: 商品數量
   */
  function viewGoods(data: any) {
    const nu = useCookie('newki-url-query-nu').value || ''
    const { memberInfo } = useMemberStore()
    if (canUseGA()) {
      window.gtag &&
        window.gtag('event', 'view_item', {
          currency: data.currency,
          value: data.price,
          items: [data],
        })
    }
    if (canUseFB()) {
      window.fbq &&
        window.fbq(
          'track',
          'ViewContent',
          {
            content_name: data.name,
            content_category: '瀏覽商品',
            currency: data.currency,
            value: data.price,
            content_type: 'product',
            contents: [{ id: data.sku_id, quantity: data.quantity }],
            spu_id: data.spu_id,
            sku_id: data.sku_id,
            content_id: data.content_id,
            brand: data.brand,
            nu,
            member_id: memberInfo?.memberId || '',
          },
          { eventID: nanoid(8) }
        )
    }
    if (canUseCZC()) {
      data.member_id = memberInfo?.memberId || ''
      data.nu = nu
      window._czc && window._czc.push(['_trackEvent', 'view_item', '商品詳情/切換銷售屬性', JSON.stringify(data)])
    }
  }

  /**
   * @description: 完成註冊
   * @param method {string} :註冊方式
   * @param memberId {string} :memberId
   */
  function register(method: string = 'sms', memberId = '') {
    const { memberInfo } = useMemberStore()
    const ni = useCookie('newki-url-query-ni').value || ''
    const nu = useCookie('newki-url-query-nu').value || ''
    if (canUseGA()) {
      window.gtag &&
        window.gtag('event', 'sign_up', {
          method,
          ni,
          nu,
          member_id: memberId ? memberId : memberInfo?.memberId || '',
        })
    }
    if (canUseFB()) {
      window.fbq &&
        window.fbq(
          'track',
          'CompleteRegistration',
          {
            content_name: '完成註冊',
            status: true,
            ni,
            nu,
            member_id: memberId ? memberId : memberInfo?.memberId || '',
          },
          { eventID: nanoid(8) }
        )
    }
    if (canUseCZC()) {
      const data = {
        ni,
        nu,
        member_id: memberId ? memberId : memberInfo?.memberId || '',
      }
      window._czc && window._czc.push(['_trackEvent', 'sign_up', '完成註冊', JSON.stringify(data)])
    }
  }

  /**
   * @description: 開始登入/註冊
   */
  function beginLogin() {
    const ni = useCookie('newki-url-query-ni').value || ''
    const nu = useCookie('newki-url-query-nu').value || ''
    if (canUseGA()) {
      window.gtag &&
        window.gtag('event', 'begin_login_or_sign_up', {
          ni,
          nu,
        })
    }
    if (canUseFB()) {
      window.fbq &&
        window.fbq(
          'trackCustom',
          'begin_login_or_sign_up',
          {
            ni,
            nu,
          },
          { eventID: nanoid(8) }
        )
    }
    if (canUseCZC()) {
      const data = {
        ni,
        nu,
      }
      window._czc && window._czc.push(['_trackEvent', 'begin_login_or_sign_up', '開始註冊或登錄', JSON.stringify(data)])
    }
  }

  /**
   * @description: 完成登錄
   * @param method {string} :登錄方式
   * @param memberId {string} :memberId
   */
  function login(method: string, memberId = '') {
    const { memberInfo } = useMemberStore()
    const nu = useCookie('newki-url-query-nu').value || ''
    if (canUseGA()) {
      window.gtag &&
        window.gtag('event', 'login', {
          method: method,
          nu,
          member_id: memberId ? memberId : memberInfo?.memberId || '',
        })
    }
    if (canUseFB()) {
      window.fbq &&
        window.fbq(
          'trackCustom',
          'Login',
          {
            nu,
            member_id: memberId ? memberId : memberInfo?.memberId || '',
          },
          { eventID: nanoid(8) }
        )
    }
    if (canUseCZC()) {
      const data = {
        member_id: memberId ? memberId : memberInfo?.memberId || '',
        nu,
      }
      window._czc && window._czc.push(['_trackEvent', 'login', '完成登錄', JSON.stringify(data)])
    }
  }

  /**
   * @description: 搜索
   * @param data {Object} :埋點參數
   * @param data.keyword {string} :搜索關鍵詞
   * @param data.category {string} :品類名
   */
  function search(data: any) {
    const { memberInfo } = useMemberStore()
    const nu = useCookie('newki-url-query-nu').value || ''
    if (canUseGA()) {
      window.gtag &&
        window.gtag('event', 'search', {
          search_term: data.keyword,
          category: data.category,
          nu,
          member_id: memberInfo?.memberId || '',
        })
    }
    if (canUseFB()) {
      window.fbq &&
        window.fbq(
          'track',
          'Search',
          {
            content_category: data.category,
            content_type: 'product',
            search_string: data.keyword,
            nu,
            member_id: memberInfo?.memberId || '',
          },
          { eventID: nanoid(8) }
        )
    }
    if (canUseCZC()) {
      const params = {
        keyword: data.keyword,
        category: data.category,
        member_id: memberInfo?.memberId || '',
        nu,
      }
      window._czc && window._czc.push(['_trackEvent', 'search', '搜索', JSON.stringify(params)])
    }
  }

  /**
   * @description: 加入購物車
   * @param data {Object} :埋點參數
   * @param data.currency {string} :幣種 必填
   * @param data.value {number} : 加入購物車時的商品列表總價，不管促銷前端加總即可 必填
   * @param data.spu_id {number} : 商城商品spu_id 必填
   * @param data.sku_id {number} : 商城商品sku_id 必填
   * @param data.name {string} : 商品名稱
   * @param data.price {number } : 購買單價
   * @param data.quantity {number } : 購買數量
   */
  function addToCart(data: any) {
    const { memberInfo } = useMemberStore()
    const nu = useCookie('newki-url-query-nu').value || ''
    if (canUseGA()) {
      window.gtag &&
        window.gtag('event', 'add_to_cart', {
          currency: data.currency,
          value: data.value,
          items: [
            {
              item_name: data.name,
              item_id: data.sku_id,
              price: data.price,
              quantity: data.quantity,
              spu_id: data.spu_id,
              sku_id: data.sku_id,
            },
          ],
          nu,
          member_id: memberInfo?.memberId || '',
        })
    }
    if (canUseFB()) {
      window.fbq &&
        window.fbq(
          'track',
          'AddToCart',
          {
            content_ids: [data.sku_id],
            content_name: data.name,
            content_type: 'product',
            contents: [{ id: data.sku_id, quantity: data.quantity }],
            currency: data.currency,
            value: data.value,
            nu,
            member_id: memberInfo?.memberId || '',
            spu_id: data.spu_id,
            sku_id: data.sku_id,
          },
          { eventID: nanoid(8) }
        )
    }
    if (canUseCZC()) {
      data.member_id = memberInfo?.memberId || ''
      data.nu = nu
      window._czc && window._czc.push(['_trackEvent', 'add_to_cart', '添加購物車', JSON.stringify(data)])
    }
  }

  /**
   * @description: 查看購物車
   * @param data {Object} :埋點參數
   * @param data.currency {string} :幣種 必填
   * @param data.value {number} : 加入購物車時的商品列表總價，不管促銷前端加總即可 必填
   * @param data.items {Array<Object>} :購物車明細
   * @param data.items.spu_id {number} : 商城商品spu_id 必填
   * @param data.items.sku_id {number} : 商城商品sku_id 必填
   * @param data.items.name {string} : 商品名稱
   * @param data.items.price {number } : 購買單價
   * @param data.items.quantity {number } : 購買數量
   */
  function viewCart(data: any) {
    const { memberInfo } = useMemberStore()
    const nu = useCookie('newki-url-query-nu').value || ''
    if (canUseGA()) {
      const items: any[] = []
      data.items.forEach((item: any) => {
        items.push({
          item_name: item.name,
          item_id: item.sku_id,
          price: item.price,
          quantity: item.quantity,
          spu_id: item.spu_id,
          sku_id: item.sku_id,
        })
      })
      window.gtag &&
        window.gtag('event', 'view_cart', {
          currency: data.currency,
          value: data.value,
          items: items,
          nu,
          member_id: memberInfo?.memberId || '',
        })
    }
    if (canUseFB()) {
      const items: any[] = []
      const contents: any[] = []
      data.items.forEach((item: any) => {
        items.push({
          item_name: item.name,
          item_id: item.sku_id,
          price: item.price,
          quantity: item.quantity,
          spu_id: item.spu_id,
          sku_id: item.sku_id,
        })
        contents.push({
          id: item.sku_id,
          quantity: item.quantity,
          name: item.name,
        })
      })
      window.fbq &&
        window.fbq(
          'trackCustom',
          'ViewCart',
          {
            content_type: 'product',
            contents,
            currency: data.currency,
            value: data.value,
            nu,
            member_id: memberInfo?.memberId || '',
            items,
          },
          { eventID: nanoid(8) }
        )
    }
    if (canUseCZC()) {
      data.member_id = memberInfo?.memberId || ''
      data.nu = nu
      window._czc && window._czc.push(['_trackEvent', 'view_cart', '查看購物車', JSON.stringify(data)])
    }
  }

  /**
   * @description: 移除購物車
   * @param data {Object} :埋點參數
   * @param data.currency {string} :幣種 必填
   * @param data.value {number} : 加入購物車時的商品列表總價，不管促銷前端加總即可 必填
   * @param data.spu_id {number} : 商城商品spu_id 必填
   * @param data.sku_id {number} : 商城商品sku_id 必填
   * @param data.name {string} : 商品名稱
   * @param data.price {number } : 購買單價
   * @param data.quantity {number } : 購買數量
   */
  function removeFromCart(data: any) {
    const { memberInfo } = useMemberStore()
    const nu = useCookie('newki-url-query-nu').value || ''
    if (canUseGA()) {
      window.gtag &&
        window.gtag('event', 'remove_from_cart', {
          currency: data.currency,
          value: data.value,
          items: [
            {
              item_name: data.name,
              item_id: data.sku_id,
              price: data.price,
              quantity: data.quantity,
              spu_id: data.spu_id,
              sku_id: data.sku_id,
            },
          ],
          nu,
          member_id: memberInfo?.memberId || '',
        })
    }
    if (canUseFB()) {
      window.fbq &&
        window.fbq(
          'trackCustom',
          'RemoveFromCart',
          {
            content_ids: [data.sku_id],
            content_name: data.name,
            content_type: 'product',
            contents: [{ id: data.sku_id, quantity: data.quantity }],
            currency: data.currency,
            value: data.value,
            nu,
            member_id: memberInfo?.memberId || '',
            spu_id: data.spu_id,
            sku_id: data.sku_id,
          },
          { eventID: nanoid(8) }
        )
    }
    if (canUseCZC()) {
      data.member_id = memberInfo?.memberId || ''
      data.nu = nu
      window._czc && window._czc.push(['_trackEvent', 'remove_from_cart', '移除購物車', JSON.stringify(data)])
    }
  }

  /**
   * @description: 加入收藏
   * @param data {Object} :埋點參數
   * @param data.currency {string} :幣種 必填
   * @param data.value {number} : 加入購物車時的商品列表總價，不管促銷前端加總即可 必填
   * @param data.items {Array<Object>} :購物車明細
   * @param data.items.spu_id {number} : 商城商品spu_id 必填
   * @param data.items.sku_id {number} : 商城商品sku_id 必填
   * @param data.items.name {string} : 商品名稱
   * @param data.items.price {number } : 購買單價
   * @param data.items.quantity {number } : 購買數量
   */
  function addToCollect(data: any) {
    const { memberInfo } = useMemberStore()
    const nu = useCookie('newki-url-query-nu').value || ''
    if (canUseGA()) {
      const items: any[] = []
      data.items.forEach((item: any) => {
        items.push({
          item_name: item.name,
          item_id: item.sku_id,
          price: item.price,
          quantity: item.quantity,
          spu_id: item.spu_id,
          sku_id: item.sku_id,
        })
      })
      window.gtag &&
        window.gtag('event', 'add_to_wishlist', {
          currency: data.currency,
          value: data.value,
          items,
          nu,
          member_id: memberInfo?.memberId || '',
        })
    }
    if (canUseFB()) {
      const items: any[] = []
      const contents: any[] = []
      data.items.forEach((item: any) => {
        items.push({
          item_name: item.name,
          item_id: item.sku_id,
          price: item.price,
          quantity: item.quantity,
          spu_id: item.spu_id,
          sku_id: item.sku_id,
        })
        contents.push({
          id: item.sku_id,
          quantity: item.quantity,
          name: item.name,
        })
      })
      window.fbq &&
        window.fbq(
          'track',
          'AddToWishlist',
          {
            content_category: '加入收藏',
            contents: contents,
            items,
            currency: data.currency,
            value: data.value,
            nu,
            member_id: memberInfo?.memberId || '',
          },
          { eventID: nanoid(8) }
        )
    }
    if (canUseCZC()) {
      data.member_id = memberInfo?.memberId || ''
      data.nu = nu
      window._czc && window._czc.push(['_trackEvent', 'add_to_collect', '加入收藏', JSON.stringify(data)])
    }
  }

  /**
   * @description: 結算
   * @param data {Object} :埋點參數
   * @param data.currency {string} :幣種 必填
   * @param data.skuIds {Array} :sku集合
   * @param data.item {Array<Object>} :結算列表
   * @param data.item.id {string} :skuId
   * @param data.item.quantity {number} :數量
   */
  function settlement(data: any) {
    const { memberInfo } = useMemberStore()
    const nu = useCookie('newki-url-query-nu').value || ''
    if (canUseGA()) {
      window.gtag &&
        window.gtag('event', 'settlement', {
          ...data,
          nu,
          member_id: memberInfo?.memberId || '',
        })
    }
    if (canUseFB()) {
      let num = 0
      const ids: any[] = []
      const items: any[] = []
      data.forEach((item: any) => {
        num += item.qty
        ids.push(item.skuId)
        items.push({
          id: item.skuId,
          quantity: item.qty,
        })
      })
      window.fbq &&
        window.fbq(
          'track',
          'InitiateCheckout',
          {
            content_ids: ids,
            content_category: '結算',
            contents: items,
            currency: 'MOP',
            num_items: num,
            nu,
            member_id: memberInfo?.memberId || '',
          },
          { eventID: nanoid(8) }
        )
    }
    if (canUseCZC()) {
      data.member_id = memberInfo?.memberId || ''
      data.nu = nu
      window._czc && window._czc.push(['_trackEvent', 'settlement', '結算', JSON.stringify(data)])
    }
  }

  /**
   * @description: 提交訂單
   * @param data {Object} :埋點參數
   * @param data.currency {string} :幣種 必填
   * @param data.amount {number} :結算總金額 必填
   * @param data.transactionId {string} :交易id
   * @param data.shippingMethod {string} :交收方式代碼
   * @param data.items {Array<Object>} :商品明細
   * @param data.item.skuIds {number} :skuId
   * @param data.item.spuId {number} :spuId
   * @param data.item.name {string} :商品名稱
   * @param data.item.price {number} :單價
   * @param data.item.discountAmount {number} :優惠金額
   * @param data.item.quantity {number} :數量
   */
  function confirmOrder(data: any) {
    const { memberInfo } = useMemberStore()
    const nu = useCookie('newki-url-query-nu').value || ''
    const items: any = []
    data.items.forEach((item: any) => {
      items.push({
        id: item.skuId,
        item_id: item.skuId,
        sku_id: item.skuId,
        item_name: item.name,
        price: item.price,
        spu_id: item.spuId,
        discount_amount: item.discountAmount,
        quantity: item.quantity,
      })
    })
    if (canUseGA()) {
      window.gtag &&
        window.gtag('event', 'begin_checkout', {
          currency: data.currency,
          value: data.amount,
          transaction_id: data.transactionId,
          shipping_method: data.shippingMethod,
          nu,
          member_id: memberInfo?.memberId || '',
          items,
        })
    }
    if (canUseFB()) {
      window.fbq &&
        window.fbq(
          'trackCustom',
          'ConfirmOrder',
          {
            currency: data.currency,
            value: data.amount,
            transaction_id: data.transactionId,
            shipping_method: data.shippingMethod,
            nu,
            member_id: memberInfo?.memberId || '',
            contents: items,
          },
          { eventID: nanoid(8) }
        )
    }
    if (canUseCZC()) {
      const params = {
        currency: data.currency,
        value: data.amount,
        transaction_id: data.transactionId,
        shipping_method: data.shippingMethod,
        nu,
        member_id: memberInfo?.memberId || '',
        items: items,
      }
      window._czc && window._czc.push(['_trackEvent', 'confirm_order', '提交訂單', JSON.stringify(params)])
    }
  }

  /**
   * @description: 提交支付信息
   * @param data {Object} :埋點參數
   * @param data.currency {string} :幣種 必填
   * @param data.amount {number} :結算總金額 必填
   * @param data.transactionId {string} :交易id
   * @param data.paymentType {string} :支付方式code
   */
  function addPaymentInfo(data: any) {
    const { memberInfo } = useMemberStore()
    const nu = useCookie('newki-url-query-nu').value || ''
    const params = {
      currency: data.currency,
      value: data.amount,
      transaction_id: data.transactionId,
      payment_type: data.paymentType,
      items: [],
      nu,
      member_id: memberInfo?.memberId || '',
    }
    if (canUseGA()) {
      window.gtag && window.gtag('event', 'add_payment_info', params)
    }
    if (canUseFB()) {
      window.fbq && window.fbq('track', 'AddPaymentInfo', params, { eventID: nanoid(8) })
    }
    if (canUseCZC()) {
      window._czc && window._czc.push(['_trackEvent', 'add_payment_info', '提交支付方式', JSON.stringify(params)])
    }
  }

  /**
   * @description: 完成購買
   * @param data.currency {string} :幣種 必填
   * @param data.amount {number} :結算總金額 必填
   * @param data.transactionId {string} :交易id
   * @param data.shippingMethod {string} :交收方式代碼
   * @param data.items {Array<Object>} :商品明細
   * @param data.item.skuIds {number} :skuId
   * @param data.item.spuId {number} :spuId
   * @param data.item.name {string} :商品名稱
   * @param data.item.price {number} :單價
   * @param data.item.discountAmount {number} :優惠金額
   * @param data.item.quantity {number} :數量
   */
  function purchase(data: any) {
    const { memberInfo } = useMemberStore()
    const nu = useCookie('newki-url-query-nu').value || ''
    const items: any = []
    data.items.forEach((item: any) => {
      items.push({
        id: item.skuId,
        item_id: item.skuId,
        sku_id: item.skuId,
        item_name: item.name,
        price: item.price,
        spu_id: item.spuId,
        discount_amount: item.discountAmount,
        quantity: item.quantity,
      })
    })
    if (canUseGA()) {
      window.gtag &&
        window.gtag('event', 'purchase', {
          currency: data.currency,
          transaction_id: data.transactionId,
          value: data.amount,
          shipping_method: data.shippingMethod,
          nu,
          member_id: memberInfo?.memberId || '',
          items: items,
        })
    }
    if (canUseFB()) {
      window.fbq &&
        window.fbq(
          'track',
          'Purchase',
          {
            currency: data.currency,
            value: data.amount,
            transaction_id: data.transactionId,
            shipping_method: data.shippingMethod,
            nu,
            member_id: memberInfo?.memberId || '',
            contents: items,
          },
          { eventID: nanoid(8) }
        )
    }
    if (canUseCZC()) {
      const params = {
        currency: data.currency,
        value: data.amount,
        transaction_id: data.transactionId,
        shipping_method: data.shippingMethod,
        nu,
        member_id: memberInfo?.memberId || '',
        items: items,
      }
      window._czc && window._czc.push(['_trackEvent', 'purchase', '完成購買', JSON.stringify(params)])
    }
  }

  return {
    provide: {
      analyze: {
        init,
        viewGoods,
        register,
        newkiTrace,
        newkiApiTrace,
        captureException,
        beginLogin,
        login,
        search,
        addToCart,
        removeFromCart,
        viewCart,
        addToCollect,
        settlement,
        confirmOrder,
        addPaymentInfo,
        purchase,
      },
    },
  }
})
