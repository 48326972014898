<template>
  <ClientOnly>
    <div class="newki-video-box">
      <div class="video-box" ref="videoRef" :id="id"></div>
      <div class="muted-icon" @click.stop="doMuted()" v-if="showMuteIcon && player">
        <NewkiSvg name="mute-fill" :size="20" :filled="true" v-show="player.muted" />
        <NewkiSvg name="voice-fill" :size="20" :filled="true" v-show="!player.muted" />
      </div>
    </div>
  </ClientOnly>
</template>

<script setup lang="ts">
import { onMounted, onUnmounted, nextTick, watch } from 'vue'
import { useColorsStore } from '~/store/colors'
import { useSystemStore } from '~/store/system'
import logoGreen from '~/assets/icons/logo-green.svg'
import playBtn from '~/assets/icons/play-btn.svg'

import Player from 'xgplayer'
const { Events } = Player
import 'xgplayer/dist/index.min.css'

const { pageVisible } = storeToRefs(useSystemStore())
const { isMobile } = useDevice()
const id = ref(`video-${useUniqueId()}`)
const logoIcon = logoGreen
const startPlay = playBtn
const { primary, secondary, bg } = storeToRefs(useColorsStore())
const { autoPlay } = storeToRefs(useSystemStore())
const player: Ref<Player | null> = ref(null)
let videoRef: Ref<HTMLElement | null> = ref(null)

// 检查视频滚动位置、自动播放
const vObserver: Ref<IntersectionObserver | null> = ref(null)

const props = defineProps({
  url: {
    type: String,
    required: true,
  },
  width: {
    type: Number,
    required: false,
    default: 375,
  },
  height: {
    type: Number,
    required: false,
    default: 468.75,
  },
  block: {
    type: Boolean,
    required: false,
    default: false,
  },
  seekedStatus: {
    type: String,
    required: false,
    default: 'play',
    validator(value: string) {
      return ['play', 'pause', 'auto'].includes(value)
    },
  },
  poster: {
    type: String,
    required: false,
    default: '',
  },
  fitVideoSize: {
    required: false,
    validator(value: any) {
      return ['fixWidth', 'fixHeight', 'fixed', undefined].includes(value)
    },
  },
  showMuteIcon: {
    type: Boolean,
    required: false,
    default: false,
  },
  playerOptions: {
    type: Object,
    required: false,
    default: () => {},
  },
  disableGesture: {
    type: Boolean,
    required: false,
    default: true,
  },
  fullscreen: {
    type: Boolean,
    required: false,
    default: false,
  },
  rootMargin: {
    type: String,
    required: false,
    default: '',
  },
  // 视频容器可见度达到多少百分比时，观察器的回调就应该执行 1 = 100%
  threshold: {
    type: Number,
    required: false,
    default: 1,
  },
})

const emit = defineEmits(['onVideoStatusChange'])

// 播放器状态
const playerStatus: any = ref('')

watch(
  pageVisible,
  val => {
    if (!val) {
      // 页面不可见时，暂停播放
      doPause()
    } else {
      // 页面可见时，计算相关属性并判断是否可以继续播放
      // createObserver()
    }
  },
  { immediate: true }
)

// 把事件暴露給到父組件
defineExpose({
  initVideo,
  videoReload,
  doMuted,
  doPlay,
  doPause,
  doNoMutedPlay,
})

// 静音
function doMuted(muted: boolean = !player.value!.muted) {
  player.value!.muted = muted
}

// 播放
function doPlay() {
  player.value?.play()
}

// 暂停
function doPause() {
  player.value?.pause()
}

// 有声播放
function doNoMutedPlay(muted: boolean = false) {
  player.value!.muted = muted
  let t = setTimeout(() => {
    clearTimeout(t)
    player.value?.play()
  }, 200)
}

function initVideo() {
  const seekedStatus = props.seekedStatus as any

  let dom = document.querySelector(`#${id.value}`)
  if (!dom) return false

  player.value = new Player({
    id: id.value,
    url: props.url,
    // height: !isMobile ? '600px' : props.block ? '100%' : `${px2rem(props.height)}rem`,
    // width: !isMobile ? '400px' : props.block ? '100%' : `${px2rem(props.width)}rem`,
    width: props.block ? '100%' : `${px2rem(props.width)}rem`,
    height: props.block ? '100%' : `${px2rem(props.height)}rem`,
    // @ts-ignore
    fitVideoSize: props.fitVideoSize,
    'x5-video-player-type': 'h5',
    playsinline: true,
    poster: props.poster ? props.poster : props.url + '?x-oss-process=video/snapshot,t_0,f_jpg,m_fast',
    volume: 0.5,
    download: false,
    commonStyle: {
      // progressColor: primary.value.default,
      // playedColor: secondary.value.default,
      progressColor: bg.value,
      playedColor: primary.value.gradual,
      volumeColor: secondary.value.default,
      sliderBtnStyle: {
        backgroundImage: `url(${logoIcon})`,
        backgroundSize: '100% 100%',
        backgroundRepeat: 'no-repeat',
        backgroundColor: 'transparent',
        border: 'none',
        boxShadow: 'none',
      },
    },
    cssFullscreen: false,
    fullscreen: props.fullscreen,
    autoplay: false,
    autoplayMuted: true,
    seekedStatus: seekedStatus,
    playbackRate: 0,
    icons: {
      startPlay,
    },
    mobile: {
      disableGesture: props.disableGesture,
    },
    closeVideoDblclick: true, // 设为 ture 在移动端开启单击暂停
    ...props.playerOptions,
  })

  // player.value.on(Events.USER_ACTION, (data) => {
  //   console.log("USER_ACTION:", data)
  // })

  player.value.on(Events.PLAY, data => {
    emit('onVideoStatusChange', data)
    playerStatus.value = data
    // console.log('PLAY')
  })

  player.value.on(Events.ENDED, data => {
    emit('onVideoStatusChange', data)
    playerStatus.value = data
  })

  player.value.on(Events.PAUSE, data => {
    emit('onVideoStatusChange', data)
    playerStatus.value = data
    // console.log('PAUSE')
  })

  player.value.on(Events.VOLUME_CHANGE, data => {
    // isMutedChange: 这个值用来标记是否为点击静音而产生的触发
    if (data.isMutedChange) return false
    if (data.volume === 0) {
      doMuted(true)
    } else {
      doMuted(false)
    }
  })

  player.value.on(Events.ERROR, data => {
    console.log('ERROR:', data)
  })
}

// 重新加載視頻
function videoReload() {
  initVideo()
  setTimeout(() => {
    player.value?.play()
  }, 200)
}

// 创建交叉观察期，以获取视频元素的边界信息
function createObserver() {
  if (vObserver.value) {
    vObserver.value?.disconnect()
  }
  let target: HTMLElement | null = document.querySelector(`#${id.value}`)
  if (!target) return false

  const center = (document.body.offsetHeight - (videoRef.value!.clientHeight ?? 0)) / 2 // 屏幕高度减 - 视频容器高度 = 剩下的上下边距
  const topOffset = 0 // 缩短顶部可视区的数值
  const bottomOffset = center / 4 || 60 // 缩短底部可视区的数值（居中就触发）

  let rootMargin = props.rootMargin ? props.rootMargin : `-${topOffset}px 0px -${bottomOffset}px 0px`

  const options = {
    root: null, // 使用浏览器视口，用于检查目标的可见性
    rootMargin: rootMargin,
    threshold: props.threshold,
  }

  const callback = (entries: any, observer: any) => {
    entries[0].isIntersecting ? doPlay() : doPause()
    // console.log(
    //   `${id.value} is intersecting:`,
    //   entries[0].isIntersecting,
    //   `intersectionRatio:`,
    //   entries[0].intersectionRatio
    // )
  }

  vObserver.value = new IntersectionObserver(callback, options)
  vObserver.value.observe(target)
}

onMounted(async () => {
  await nextTick()
  initVideo()
  createObserver()
})

onUnmounted(() => {
  vObserver.value?.disconnect()
  // console.log('Observer Disconnected~')
})
</script>

<style scoped lang="scss">
:deep(.xgplayer-progress-btn)::before {
  content: '';
  width: 0;
  height: 0;
}
:deep(.xgplayer-start) {
  width: 80px !important;
  height: 80px !important;
}

.newki-video-box {
  position: relative;
  z-index: 10;
  width: 100%;
  height: 100%;
  border-radius: 16px;
  overflow: hidden;

  .video-box {
    // border-radius: 16px;
  }
}

.muted-icon {
  @include flex();
  position: absolute;
  top: 12px;
  right: 12px;
  z-index: 10;
  $size: 32px;
  width: $size;
  height: $size;
  background-color: rgba(0, 0, 0, 0.35);
  border-radius: 50%;
  cursor: pointer;

  :deep(.nuxt-icon) {
    @include flex();
    svg {
      margin: 0;
    }
  }
}
</style>
