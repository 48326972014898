<template>
  <van-popup
    v-model:show="showPopUp"
    teleport="body"
    :close-on-click-overlay="false"
    :style="{
      transform: 'translateY(-70%)',
    }"
  >
    <div class="close-icon" @click="close">
      <van-icon name="close" color="#ffffff" size="30" />
    </div>
    <div id="newkiPopup">
      <div class="content">
        <div class="title">{{ t('chat.addAsk') }}</div>
        <div class="contact-item">
          <div class="phone">
            <NewkiSvg name="whatapp" :size="26" filled />
            <span>+85269949995</span>
          </div>
          <div class="btn" @click="copyText('whatapp')">{{ t('common.copy') }}</div>
        </div>
        <div class="contact-item">
          <div class="phone">
            <NewkiSvg name="wechat" :size="26" filled />
            <span>+85366949995</span>
          </div>
          <div class="btn" @click="copyText('wechat')">{{ t('common.copy') }}</div>
        </div>
        <div class="title last-title">{{ t('chat.loginAsk') }}</div>
        <div class="contact-item">
          <div class="phone">
            <NewkiSvg name="newki-logo" :size="26" filled />
            <span>{{ t('chat.newkiAsk') }}</span>
          </div>
          <div class="btn" @click="chat">{{ t('common.login') }}</div>
        </div>
      </div>
    </div>
  </van-popup>
  <LoginDialog ref="loginDialog" @callBack="callBack" />
</template>

<script setup lang="ts">
import { useClipboard } from '@vueuse/core'
import { showToast } from 'vant'
import { getPlatForm } from '~/utils/getPlatForm'
import LoginDialog from '~/components/LoginDialog.vue'
import { ref, computed } from 'vue'

const { t } = useI18n()
const { copy } = useClipboard({ legacy: true })
const { $jsBridge }: any = useNuxtApp()
const loginDialog = ref<InstanceType<typeof LoginDialog> | null>(null)
const emit = defineEmits(['update:modelValue'])

const props = defineProps({
  modelValue: {
    type: Boolean,
    required: true,
  },
})

const showPopUp = computed({
  get() {
    return props.modelValue
  },
  set(value) {
    emit('update:modelValue', value)
  },
})

function copyText(type: string) {
  const url: any = {
    whatapp: {
      text: '69949995',
      url: 'https://api.whatsapp.com/send/?phone=85369949995',
    },
    wechat: {
      text: '69949995',
      url: 'https://work.weixin.qq.com/ca/cawcdeb3985d4e947c',
    },
  }

  try {
    copy(url[type].text)
    showToast(t('common.copySuccess'))
  } catch (e) {
    showToast(t('common.copyError'))
  }

  if (getPlatForm() === 'App') {
    $jsBridge.call('openInSystemBrowserSync', {
      url: url[type].url,
    })
  } else {
    window.open(url[type].url)
  }
  showPopUp.value = false
}

function open() {
  showPopUp.value = true
}

function close() {
  showPopUp.value = false
}

function chat() {
  showPopUp.value = false
  loginDialog.value?.showModal()
}

function callBack() {
  routerPush('chat')
}

defineExpose({
  open,
})
</script>

<style scoped lang="scss">
#newkiPopup {
  width: 329px;
  height: 332px;
  background-image: url(@/assets/images/chat/dialogBg.png);
  background-size: 100% 100%;

  .content {
    padding: 114px 41px 0 41px;

    .title {
      display: inline-block;
      padding: 3px 10px;
      border-radius: 999px;
      background-color: #ffe8b2;
      color: #513302;
      margin-bottom: 8px;
    }
    .last-title {
      margin-top: 10px;
    }
    .contact-item {
      display: flex;
      justify-content: space-between;
      margin-bottom: 6px;

      .phone {
        display: flex;
        color: $text-color-assist;
        align-items: center;
      }

      .btn {
        padding: 0 14px;
        background-color: $primary;
        font-size: 16px;
        font-weight: 500;
        color: $white;
        border-radius: 999px;
        line-height: 1;
        display: flex;
        align-items: center;

        &:hover {
          cursor: pointer;
        }
      }
    }
  }
}
.close-icon {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 20px;
}
.van-popup {
  margin: 0 auto;
  background: transparent;
  max-width: 100%;
}
</style>
