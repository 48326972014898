<template>
  <!-- Slider main container -->
  <div :class="props.name" class="swiper">
    <!-- Additional required wrapper -->
    <div class="swiper-wrapper">
      <div class="swiper-slide" v-for="item in props.dataList" @click.stop="onSlideClick(item)">
        <a :href="onOneItemUrl(item)" @click.prevent="onSlideClick(item)">
          <img :src="useOSSImage(item.picturePath, { dpr: 3, q: 100 })" alt="" />
        </a>
      </div>
    </div>
    <!-- If we need pagination -->
    <div class="swiper-pagination" v-if="option.pagination"></div>
    <!-- If we need navigation buttons -->
    <div
      :class="{ 'nav-button': option.pagination, disabled: isBeginning }"
      class="swiper-button-prev"
      v-if="option.navigation"
    >
      <div class="icon-left">
        <newki-svg name="goods-left" :filled="true" :size="16" />
      </div>
    </div>
    <div
      :class="{ 'nav-button': option.pagination, disabled: isEnd }"
      class="swiper-button-next"
      v-if="option.navigation"
    >
      <div class="icon-right">
        <newki-svg name="goods-right" :filled="true" :size="16" />
      </div>
    </div>
    <!-- If we need scrollbar -->
    <div class="swiper-scrollbar" v-if="option.scrollbar"></div>
  </div>
</template>

<script setup lang="ts">
import { onMounted } from 'vue'

// import { Swiper, SwiperSlide } from 'swiper/vue';
import Swiper from 'swiper'
import { Navigation, Pagination, Scrollbar, A11y, Autoplay, Mousewheel, EffectCoverflow } from 'swiper/modules'
import type { SwiperModule } from 'swiper/types/shared.d.ts'

const { isMobile } = useDevice()

import 'swiper/css'
// import 'swiper/css/navigation';
import 'swiper/css/pagination'
import 'swiper/css/scrollbar'

// Swiper 实例
const swiper = ref({})

const emit = defineEmits(['onTypePicked', 'onSlideChange', 'onActiveIndexChange'])

const props = defineProps({
  name: {
    type: String,
    required: true,
    default: '.swiper',
  },
  dataList: {
    type: Array,
    required: true,
    default: () => [],
  },
  wData: {
    type: Object,
    required: false,
    default: () => {},
  },
})

// 当前激活的 index
const activeIndex: Ref<number> = ref(0)

// 是否第一个
const isBeginning: Ref<boolean> = ref(true)

// 是否最后一个
const isEnd: Ref<boolean> = ref(false)

const option: any = ref({
  centeredSlides: true,
  preloadImages: false,
  grabCursor: true,
  observeParents: true,
  observer: true,
  slidesPerView: 'auto',

  effect: 'coverflow',
  coverflowEffect: isMobile
    ? {
        depth: 30,
        modifier: 1,
        rotate: 0,
        scale: 0.9,
        slideShadows: false,
        stretch: 118,
      }
    : {
        depth: 10,
        modifier: 1,
        rotate: 0,
        scale: 0.85,
        slideShadows: false,
        stretch: 120,
      },

  // Optional parameters
  modules: [Navigation, Pagination, Scrollbar, A11y, Autoplay, Mousewheel, EffectCoverflow],

  // Navigation arrows
  navigation: isMobile
    ? false
    : {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },

  // If we need pagination
  pagination: {
    el: '.swiper-pagination',
  },

  // And if we need scrollbar
  scrollbar: false,
  on: {
    init: () => {
      // console.log('swiper initialized');
    },
    click: (swiper: any) => {
      emit('onTypePicked', swiper.clickedIndex)
    },
    slideChange: (swiper: any) => {
      emit('onSlideChange', swiper.activeIndex)
    },
    activeIndexChange: (swiper: any) => {
      emit('onActiveIndexChange', swiper)
      activeIndex.value = swiper.activeIndex
      isBeginning.value = swiper.isBeginning
      isEnd.value = swiper.isEnd
    },
  },
})

const buildDotColor = () => {
  let id = props.wData?.id ?? props.wData?.key
  let dom: HTMLElement | null = document.querySelector(`.${props.name}${id}`)
  dom?.style.setProperty('--swiper-theme-color', props.wData.dotColor)
  dom?.style.setProperty('--swiper-pagination-color', props.wData.dotColor)
}

const initSwiper = () => {
  swiper.value = new Swiper(`.${props.name}`, option.value)
}

const onSlideClick = (item: any) => {
  // console.log("onSlideClick:", item)

  routerPush('post-detail-id', {
    params: {
      id: item.id,
    },
  })
}

const onOneItemUrl = (item: any) => {
  return routerNuxtLink('post-detail-id', {
    params: {
      id: item.id,
    },
  })
}

onMounted(() => {
  initSwiper()
  buildDotColor()
})
</script>

<style scoped lang="scss">
$p-h: 30px;

.swiper {
  position: relative;
  z-index: 12;
  width: 100%;
  padding-bottom: $p-h;
  --swiper-theme-color: #00aeeb;
  --swiper-pagination-color: #00aeeb;
  --swiper-navigation-size: 12px;

  &:hover {
    :deep(.swiper-button-next),
    :deep(.swiper-button-prev) {
      &.nav-button {
        display: block;
      }
      &.nav-button.disabled {
        opacity: 0.3;
        cursor: default;
      }
    }
  }

  // 不支持悬停的设备如手机、平版电脑：隐藏按钮
  @media (hover: none) {
    :deep(.swiper-button-next),
    :deep(.swiper-button-prev) {
      &.nav-button {
        display: none;
      }
    }
  }

  .swiper-wrapper {
    width: 100%;
    height: unset;

    .swiper-slide {
      position: relative;
      width: 60%;
      height: 0;
      padding-bottom: calc(60% * 1.25);
      border-radius: 14px;
      // border: 2px solid $white;
      box-shadow: $shadow-md;
      background-color: $white;
      @include cursor();

      * {
        user-select: none;
      }

      img {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 10;
        display: block;
        object-fit: cover;
        object-position: center;
        border-radius: 14px;
        width: 100%;
        height: 100%;
      }
    }
  }

  :deep(.swiper-pagination) {
    @include flex();
    height: $p-h;
    bottom: 0;
  }

  :deep(.swiper-button-next) {
    right: 0;
    left: unset;
  }

  :deep(.swiper-button-prev) {
    left: 0;
    right: unset;
  }

  :deep(.swiper-button-next),
  :deep(.swiper-button-prev) {
    position: absolute;
    top: 0;
    bottom: 0;
    z-index: 100;
    margin: auto 0;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background-color: rgba(0, 0, 0, 0.35);
    box-shadow: $shadow-md;

    &.nav-button {
      display: none;
      transform: translateY(-9px);
    }

    .icon-left,
    .icon-right {
      @include flex();
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 10;
      cursor: pointer;
    }

    &::after {
      display: none;
    }
  }
}
</style>
