<template>
  <!-- Slider main container -->
  <div :class="props.name" class="swiper">
    <!-- Additional required wrapper -->
    <div class="swiper-wrapper">
      <div class="swiper-slide" v-for="item in items" @click="onSlideClick(item)">
        <img :src="item.goodsPicture" alt="" />
        <div class="name">{{ item.goodsName }}</div>
      </div>
    </div>
    <!-- If we need pagination -->
    <div class="swiper-pagination" v-if="props.option.pagination"></div>
    <!-- If we need navigation buttons -->
    <div
      :class="{ 'nav-button': props.option.pagination, disabled: isBeginning }"
      class="swiper-button-prev"
      v-if="props.option.navigation"
    >
      <div class="icon-left">
        <NewkiSvg name="chevron-left" :filled="true" :size="16" />
      </div>
    </div>
    <div
      :class="{ 'nav-button': props.option.pagination, disabled: isEnd }"
      class="swiper-button-next"
      v-if="props.option.navigation"
    >
      <div class="icon-right">
        <NewkiSvg name="chevron-right" :filled="true" :size="16" />
      </div>
    </div>
    <!-- If we need scrollbar -->
    <div class="swiper-scrollbar" v-if="props.option.scrollbar"></div>
  </div>
</template>

<script setup lang="ts">
import { onMounted } from 'vue'

// import { Swiper, SwiperSlide } from 'swiper/vue';
import Swiper from 'swiper'
import { Navigation, Pagination, Scrollbar, A11y, Autoplay, Mousewheel, FreeMode } from 'swiper/modules'

import 'swiper/css'
import 'swiper/css/pagination'
import 'swiper/css/scrollbar'

// Swiper 实例
const swiper = ref({})

const emit = defineEmits(['onTypePicked', 'onSlideChange', 'onActiveIndexChange'])

const props = defineProps({
  name: {
    type: String,
    required: true,
    default: '.swiper',
  },
  option: {
    type: Object,
    required: false,
    default: {},
  },
  items: {
    type: Array,
    required: true,
    default: [],
  },
})

// 当前激活的 index
const activeIndex: Ref<number> = ref(0)

// 是否第一个
const isBeginning: Ref<boolean> = ref(true)

// 是否最后一个
const isEnd: Ref<boolean> = ref(false)

const option: any = ref({
  loop: false,
  watchSlidesProgress: true,
  slidesPerView: 'auto',
  centeredSlides: false,
  preloadImages: false,
  modules: [Navigation, Pagination, Scrollbar, A11y, Autoplay, Mousewheel, FreeMode],
  freeMode: {
    enabled: true,
    sticky: false,
  },
  // Navigation arrows
  navigation: props.option.navigation
    ? {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      }
    : false,

  // If we need pagination
  pagination: props.option.pagination
    ? {
        el: '.swiper-pagination',
      }
    : false,

  // And if we need scrollbar
  scrollbar: props.option.scrollbar
    ? {
        el: '.swiper-scrollbar',
      }
    : false,
  on: {
    init: async (swiper: any) => {
      // console.log(`${props.name} Init Done`)
    },
    click: (swiper: any) => {
      emit('onTypePicked', swiper.clickedIndex)
    },
    slideChange: (swiper: any) => {
      emit('onSlideChange', swiper)
    },
    activeIndexChange: (swiper: any) => {
      emit('onActiveIndexChange', swiper)
      activeIndex.value = swiper.activeIndex
      isBeginning.value = swiper.isBeginning
      isEnd.value = swiper.isEnd
    },
  },
})

const initSwiper = () => {
  swiper.value = new Swiper(`.${props.name}`, option.value)
}

const onSlideClick = (item: any) => {
  // console.log(item)
  routerPush('product-skuId-contentId', { params: { skuId: item.skuId } })
}

onMounted(() => {
  initSwiper()
})
</script>

<style scoped lang="scss">
.swiper {
  --swiper-theme-color: #aace37;
  --swiper-pagination-color: #aace37;
  --swiper-navigation-size: 12px;

  .swiper-wrapper {
    @include flex(row, flex-start, flex-start);
    .swiper-slide {
      width: 120px;
      // height: 120px;
      border-radius: 14px;
      // margin: 0 8px;
      @include cursor();

      & + .swiper-slide {
        margin-left: 8px;
      }

      img {
        width: 120px;
        height: 120px;
        object-fit: cover;
        object-position: center;
        border-radius: 14px;
        box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.05);
      }

      .name {
        @include line;
        font-size: 13px;
        line-height: 16px;
        margin: 10px 0;
        color: $text-color-assist;
      }
    }
  }

  :deep(.swiper-pagination) {
    bottom: 0;
  }

  :deep(.swiper-button-next) {
    right: 0;
    left: unset;
    border-radius: 10px 0 0 10px;
  }
  :deep(.swiper-button-prev) {
    left: 0;
    right: unset;
    border-radius: 0 10px 10px 0;
  }

  :deep(.swiper-button-next),
  :deep(.swiper-button-prev) {
    position: absolute;
    bottom: 50px;
    z-index: 100;
    width: 20px;
    height: 20px;
    background-color: rgba(0, 0, 0, 0.5);
    box-shadow: $shadow-md;

    &.nav-button {
      transform: translateY(-9px);
      display: none;

      &:hover {
        display: block;
        transition: opacity 0.2s ease;

        &.disabled {
          opacity: 0.3;
          cursor: default;
        }
      }
    }

    .icon-left,
    .icon-right {
      @include flex();
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 10;
      color: $white;
    }

    &::after {
      display: none;
    }
  }
}
</style>
