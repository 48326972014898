<template>
  <div class="claendar-box">
    <div class="newki-entry" :class="{ show: showEntry }">
      <button @click="goContentChannel()">{{ t('newki.entry') }}</button>
    </div>
    <div class="box-style" :style="marginStyle">
      <div class="content-box">
        <div class="ftofs-data">
          <div class="data-time">
            <div class="year">
              <span>{{ yearText }}</span>
              <span>{{ monthEn }}</span>
            </div>
            <div class="month">{{ monthCn }}{{ t('newki.month') }}</div>
          </div>
          <div class="banner-slogan">
            <div>{{ t('newki.monthly') }}</div>
            <div>{{ t('newki.monthlyYet') }}</div>
          </div>
        </div>
        <div class="recommended">
          <div class="home-cal">
            <div>{{ t('newki.monthlyAdd') }}</div>
            <div>{{ t('newki.fit') }}</div>
          </div>
          <div class="cal-text">{{ props.wData.innerData?.description }}</div>
        </div>
      </div>
      <van-swipe
        class="swipe-list"
        :autoplay="3000"
        :show-indicators="false"
        :touchable="false"
        @change="onSlideChange"
        v-if="props.wData.innerData?.imageList.length"
      >
        <van-swipe-item v-for="item in props.wData.innerData?.imageList">
          <div class="img-box" v-if="useOSSImage(item, { dpr: 3, q: 80 })">
            <!-- <img :src="useOSSImage(item, { dpr: 3, q: 90 })" alt="" /> -->
            <NewkiImage
              :src="item"
              :width="appWidth"
              :height="appWidth * 1.78"
              :rules="{ newkiId: 'Calendar', dpr: 3, q: 80 }"
            >
              <template #loading>
                <div></div>
              </template>
            </NewkiImage>
          </div>
        </van-swipe-item>
      </van-swipe>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, watch, onMounted, computed } from 'vue'
import dayjs from 'dayjs'
import { useStyle } from './renderHooks'
import type { IActionType } from '@/types/IActionType'
// import 'dayjs/locale/zh'
import { useBroswerStore } from '~/store/broswer'
const { appWidth } = storeToRefs(useBroswerStore())

const { t } = useI18n()

const yearText = ref('')
const monthEn = ref('')
const monthCn = ref('')

const props = defineProps({
  wData: {
    type: Object,
    required: false,
    default: {},
  },
})

const actionData: Ref<IActionType | null> = ref(null)

const showEntry: Ref<Boolean> = ref(false)

// 组件样式：padding
const widgetStyle: any = useStyle(props.wData).widgetStyle

// 组件样式：margin
const marginStyle = computed(() => {
  if (widgetStyle) {
    return {
      marginTop: widgetStyle.value.paddingTop,
      marginRight: widgetStyle.value.paddingRight,
      marginBottom: widgetStyle.value.paddingBottom,
      marginLeft: widgetStyle.value.paddingLeft,
    }
  }
})

const initDate = (date: string = props.wData?.date) => {
  const mMap: Record<number, string> = {
    1: '一',
    2: '二',
    3: '三',
    4: '四',
    5: '五',
    6: '六',
    7: '七',
    8: '八',
    9: '九',
    10: '十',
    11: '十一',
    12: '十二',
  }

  yearText.value = dayjs(date).format('YYYY')
  monthEn.value = dayjs(date).format('MMM')?.toLocaleUpperCase()
  monthCn.value = mMap[Number(dayjs(date).locale('zh-hk').format('M'))]
}

const onSlideChange = (index: number) => {
  initActionData(props.wData.items, index)
}

const goContentChannel = (_actionData: IActionType = actionData.value!) => {
  useContentAction(_actionData)
}

const initActionData = (item: any = props.wData.items, index: number = 0) => {
  actionData.value = {
    actionCode: item[index].actionCode,
    actionContent: item[index].actionContent,
    actionContentCode: item[index].actionContentCode,
  }
}

watch(
  () => props.wData,
  val => {
    initDate()
    initActionData()
  },
  { immediate: true, deep: true }
)

watch(
  () => actionData.value,
  val => {
    if (actionData.value?.actionCode) {
      showEntry.value = true
    } else {
      showEntry.value = false
    }
  },
  { immediate: true, deep: true }
)
</script>

<style scoped lang="scss">
.newki-entry {
  // position: absolute;
  // top: 0;
  // right: 0;
  // bottom: 0;
  // left: 0;
  @include cursor();
  position: fixed;
  top: 12px;
  right: 12px;
  z-index: 100;
  display: none;

  &.show {
    display: block;
  }

  button {
    @include flex();
    height: 22px;
    padding: 2px 6px;
    font-size: 12px;
    color: $white;
    background-color: rgba(0, 0, 0, 0.35);
    border-radius: 12px;
    line-height: 22px;
    border: none;
    @include cursor();
  }
}

.claendar-box {
  position: relative;
  z-index: 12;
  width: 100%;
  padding-bottom: 178%;

  .box-style {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 11;
  }

  .content-box {
    @include flex(column, flex-end, center);
    gap: 14px;
    width: 100%;
    height: 100%;
    padding: 14px;
    background-color: rgba(0, 0, 0, 0.2);

    .ftofs-data {
      @include flex(row, flex-start, center);
      gap: 12px;
      position: relative;
      z-index: 12;
      width: 100%;

      color: $white;

      .data-time {
        @include flex(column, center, center);
        position: relative;
        z-index: 10;
        width: 120px;
        height: 90px;
        background: rgba($primary, 0.8);
        border-radius: 20px;
        border: 1px solid $white;
        padding: 0 10px;

        .year {
          @include flex();
          gap: 5px;
          font-size: 14px;
          color: $white;
          padding: 1px 4px 5px;
          border-bottom: 1px solid rgba($white, 0.6);
        }

        .month {
          font-size: 32px;
          font-weight: bold;
          color: $white;
        }
      }

      .banner-slogan {
        div:first-child {
          font-size: 26px;
          font-weight: 900;
          color: $white;
        }

        div:last-child {
          font-size: 29px;
          font-weight: 400;
          color: $white;
        }
      }
    }

    .recommended {
      @include flex(row, flex-start, center);
      position: relative;
      z-index: 12;
      width: 100%;
      height: 110px;
      background: rgba($secondary, 0.8);
      border-radius: 20px;
      border: 1px solid $white;
      padding: 15px 15px 15px 5px;
      color: $white;

      .home-cal {
        width: 72px;
        height: 100%;

        div:first-child {
          font-size: 20px;
          line-height: 23px;
          font-weight: 700;
          color: $white;
          text-align: center;
        }

        div:last-child {
          font-size: 53px;
          line-height: 62px;
          font-weight: 700;
          color: $white;
          text-align: center;
        }
      }

      .cal-text {
        @include line(3);
        flex: 1;
        padding-left: 12px;
        border-left: 1px solid $white;
        font-size: 16px;
        line-height: 24px;
        font-weight: 500;
        color: $white;
      }
    }
  }

  .swipe-list {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 10;

    .img-box {
      width: 100%;
      height: 100%;

      img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      :deep(.van-image) {
        img {
          display: block;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      :deep(.van-van-image) {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;

        img {
          display: block;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }
  }
}
</style>
