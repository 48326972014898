import { $axios } from '../index'

/**
 * @description: 獲取宣傳頁詳情
 */
export const apiGetAdvertisementPage = (code: string | number) => {
  return $axios()
    .post(`:goods/app/advertisementPage/${code}`)
    .then((res: any) => {
      return res
    })
    .catch(() => {
      return null
    })
}

/**
 * @description: 獲取可用的列表
 */
export const apiGetAvailable = (timestamp: number = 0, data: any) => {
  return $axios()
    .post(`:goods/app/skuGroup/getAvailable?timestamp=${timestamp}`, data)
    .then((res: any) => {
      return res.data
    })
    .catch(() => {
      return null
    })
}

/**
 * @description: 分页查询商品組商品
 */
export const apiGetSkuGroup = (data: any, pageNum: number = 1, pageSize: number = 20, timestamp: number = 0) => {
  return $axios()
    .post(`:goods/app/skuGroup/querySku?pageNum=${pageNum}&pageSize=${pageSize}&timestamp=${timestamp}`, data)
    .then((res: any) => {
      return res.data
    })
    .catch(() => {
      return null
    })
}
