<template>
  <div class="product-container" @click="jump">
    <NewkiImage class="product-img" :src="params.img" :width="200" :height="200" />
    <div class="product-info">
      <div class="product-name">{{ params.name }}</div>
      <div class="product-attr">{{ params.attr }}</div>
    </div>
  </div>
</template>

<script setup>
import { routerPush } from '~/composables/routerPush'

const props = defineProps({
  params: {
    type: Object,
    required: true,
  },
})

const emit = defineEmits(['close'])

function jump() {
  emit('close', 'card-cancel')
  routerPush('product-skuId-contentId', { params: { skuId: +props.params.skuId } })
}
</script>

<style scoped lang="scss">
.product-container {
  width: 200px;
  border-radius: 12px;
  background-color: #fff;
  display: flex;
  flex-direction: column;

  .product-img {
    width: 200px;
    height: 200px;

    :deep(.van-image__img) {
      border-top-left-radius: 12px;
      border-top-right-radius: 12px;
    }
  }

  .product-info {
    padding: 8px;
    .product-name {
      font-size: 15px;
      color: $text-color-default;
      @include line(2);
    }
    .product-attr {
      margin-top: 6px;
      font-size: 13px;
      color: $text-color-assist;
    }
  }
}
</style>
