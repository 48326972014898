export const useImStore = defineStore('im', () => {
  const newMessage = ref<any>({}) // 新消息
  const specialMessage = ref<any>({}) // 發送訂單、商品消息
  const chatPopup = ref<boolean>(false)
  const unRead = ref<number>(0)

  function setChatPopup(status: boolean): void {
    chatPopup.value = status
  }

  function setNewMessage(data: any) {
    newMessage.value = data
  }

  function clearNewMessage() {
    newMessage.value = {}
  }

  function setUnRead(data: number) {
    unRead.value = data
  }

  function addUnRead() {
    unRead.value++
  }

  function setSpecialMessage(data: any) {
    specialMessage.value = data
  }

  function clearSpecialMessage() {
    specialMessage.value = {}
  }

  return {
    newMessage,
    specialMessage,
    setNewMessage,
    chatPopup,
    unRead,
    setChatPopup,
    clearNewMessage,
    setSpecialMessage,
    clearSpecialMessage,
    setUnRead,
    addUnRead,
  }
})
