export const useCurrentSelectedAreaCodeStore = defineStore('currentSelectedAreaCode', () => {
  // 用戶是否手動選擇了 areaCode
  const isUserSelected: Ref<boolean> = ref(false)

  const setUserSelected = (flag: boolean) => {
    isUserSelected.value = flag
  }

  return {
    isUserSelected,
    setUserSelected,
  }
})
