<template>
  <div class="skip-loop" :class="{ fill: props.fill }">
    <div class="line" :class="{ paused: animationPlayState === 'paused', running: animationPlayState === 'running' }">
      <div></div>
      <div></div>
      <div></div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'

const props = defineProps({
  fill: {
    type: Boolean,
    default: true,
    required: false,
  },
  status: {
    type: String,
    default: 'ended',
    required: false,
  },
})

const animationPlayState: Ref<'running' | 'paused'> = computed(() => {
  return props.status === 'play' ? 'running' : 'paused'
})
</script>

<style scoped lang="scss">
.skip-loop {
  @include flex(column, center, center);
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.35);
  border-radius: 6px;

  &.fill {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 10;
  }

  .line {
    @include flex(row, center, flex-end);
    gap: 4px;
    // width: 34px;
    height: 34px;

    &.paused {
      div {
        &:nth-child(1),
        &:nth-child(2),
        &:nth-child(3) {
          animation-play-state: paused;
        }
      }
    }

    &.running {
      div {
        &:nth-child(1),
        &:nth-child(2),
        &:nth-child(3) {
          animation-play-state: running;
        }
      }
    }

    div {
      width: 4px;
      background-color: #fff;
      border-radius: 4px;
      $duration: 0.6s;

      &:nth-child(1) {
        height: 6px;
        animation: skip1 $duration ease-in-out 0.2s infinite alternate;

        @keyframes skip1 {
          from {
            height: 6px;
          }

          to {
            height: 20px;
          }
        }
      }

      &:nth-child(2) {
        height: 30px;
        animation: skip2 $duration ease-in-out 0s infinite alternate;

        @keyframes skip2 {
          from {
            height: 30px;
          }

          to {
            height: 16px;
          }
        }
      }

      &:nth-child(3) {
        height: 18px;
        animation: skip3 $duration ease-in-out 0.1s infinite alternate;

        @keyframes skip3 {
          from {
            height: 18px;
          }

          to {
            height: 6px;
          }
        }
      }
    }
  }
}
</style>
