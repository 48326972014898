<template>
  <van-field
    v-model="smsCode"
    class="input-field"
    name="smsInput"
    :placeholder="t('login.inputSmsCode')"
    :rules="[{ validator: validatorInput, trigger: 'onChange' }]"
    @end-validate="endValidate"
  >
    <template #extra>
      <div class="extra-content">
        <div class="divider"></div>
        <span class="sms-text" :class="{ 'sms-text-error': !props.isValidPhone }" @click="getCode">{{ smsText }}</span>
      </div>
    </template>
  </van-field>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { useMemberStore } from '~/store/member'
import { showToast } from 'vant'
import { getSmsCode } from '~/service/api/common'
import { useLocalStorage } from '@vueuse/core'
import { SmsCodeEnum } from '~/enum/SmsCode'

const props = defineProps({
  isValidPhone: {
    type: Boolean,
    required: false,
    default: false,
  },
  // 是否需要勾選用戶協議
  needAgreeAgreement: {
    type: Boolean,
    required: false,
    default: true,
  },
  // 操作類型
  type: {
    type: String,
    required: true,
    default: 'login',
    validator(value: string) {
      return ['login', 'register', 'setPwd', 'findPwd', 'setNickname', 'setPhone', 'setAvatar', 'bindPhone'].includes(
        value
      )
    },
  },
})

const emit = defineEmits(['agreementShake', 'login'])
const { t } = useI18n()
const operationType = computed(() => {
  switch (props.type) {
    case 'login':
      return SmsCodeEnum.login
    case 'register':
      return SmsCodeEnum.register
    case 'setPwd':
      return SmsCodeEnum.setPwd
    case 'findPwd':
      return SmsCodeEnum.findPwd
    case 'setNickname':
      return SmsCodeEnum.setNickname
    case 'setPhone':
      return SmsCodeEnum.setPhone
    case 'setAvatar':
      return SmsCodeEnum.setAvatar
    case 'bindPhone':
      return SmsCodeEnum.bindPhone
    default:
      return SmsCodeEnum.login
  }
})

let smsCode = ref('')
let smsText = ref(t('login.getSmsCode'))
const smsLength = 6
let isCountDown = ref(false)
let countDownTime = 60
// 是否同意用戶協議
const { isAgreeAgreement, areaCode, mobile } = storeToRefs(useMemberStore())

const getCode = async () => {
  // 驗證是否同意用戶協議
  if (props.needAgreeAgreement && !isAgreeAgreement.value) {
    showToast(`${t('login.agreementTip')}${t('login.ServiceAgreement')}${t('login.PrivacyAgreement')}`)
    emit('agreementShake')
    return false
  }

  // 驗證手機號是否正確
  if (!props.isValidPhone) {
    showToast(`${t('login.inputRightPhoneNumber')}`)
    return false
  }

  // 是否正在獲取驗證碼，防止重複點擊
  if (isCountDown.value) {
    return false
  }
  isCountDown.value = true

  // 獲取驗證碼
  let data = await getSmsCode(areaCode.value, mobile.value, operationType.value)
  const apiTimeSeconds = Number(data.timeSeconds)
  console.log('data:', data)

  // 設置倒計時
  let time = 0
  const errorNum = useLocalStorage('login-error', 0).value
  // 如果api有返回到倒计时，就使用api倒计时
  if (apiTimeSeconds > 0) {
    time = apiTimeSeconds
  } else {
    if (errorNum > 5) {
      time = (errorNum - 5) * 3600
    } else {
      time = countDownTime
    }
  }

  let timer = setInterval(() => {
    if (time === 0) {
      resetCountDown()
      clearInterval(timer)
      return
    }
    smsText.value = `${time}${t('login.seconds')}`
    time--
  }, 1000)
}

const resetCountDown = () => {
  smsText.value = t('login.getSmsCode')
  isCountDown.value = false
}

const validatorInput = (val: any, rule: any) => {
  if (!val) {
    rule.message = t('login.inputSmsCode')
    return false
  }

  if (val.length !== smsLength) {
    rule.message = t('login.inputSmsCode')
    return false
  }

  return true
}

const cleanCode = () => {
  smsCode.value = ''
}

const endValidate = (params: any) => {
  const { status } = params
  if (status === 'passed') {
    emit('login')
  }
}

defineExpose({
  smsCode,
  cleanCode,
})
</script>

<style scoped lang="scss">
.input-field {
  border: 1px solid $border-color;
  border-radius: 8px;
  margin-top: 26px;
  overflow: unset;

  .van-field__value {
    overflow: unset;
  }
  :deep(.van-field__error-message) {
    position: absolute;
    bottom: -36px;
    left: -10px;
  }
}

.extra-content {
  display: flex;
  align-items: center;

  .divider {
    width: 2px;
    height: 16px;
    margin-right: 10px;
    background-color: $border-color;
  }
  .sms-text {
    color: $primary;

    &:hover {
      cursor: pointer;
    }
  }
  .sms-text-error {
    color: $primary-opacity-60;
  }
}
</style>
