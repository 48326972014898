import { Locale } from 'vant'
import zhHK from 'vant/es/locale/lang/zh-HK'
import zhCN from 'vant/es/locale/lang/zh-CN'
import enUS from 'vant/es/locale/lang/en-US'

export const changLanguage = (language: string) => {
  const nuxtApp: any = useNuxtApp()

  // 根據nuxt.config的i18n code配置
  switch (language) {
    case 'zh-HK':
      nuxtApp.$i18n.setLocale('zh-HK')
      Locale.use('zh-HK', zhHK)
      break
    case 'zh-CN':
      nuxtApp.$i18n.setLocale('zh-CN')
      Locale.use('zh-CN', zhCN)
      break
    case 'en':
      nuxtApp.$i18n.setLocale('en')
      Locale.use('en-US', enUS)
      break
    default:
      nuxtApp.$i18n.setLocale('zh-HK')
      Locale.use('zh-HK', zhHK)
      break
  }
}
