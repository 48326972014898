export const useActivityStore = defineStore('activity', () => {
  const normalActivityTemplateMap = ref<any>({})
  const normalActivityShowAnimation = ref(false)
  const normalActivityRefreshLog = ref(false)
  const templateBCouponCode = ref('')
  // 批量換購是否成功
  const exchangeBatchStatus = ref(false)

  function setNormalActivityTemplateMap(data: any[]) {
    for (let i = 0; i < data.length; i++) {
      normalActivityTemplateMap.value[data[i].code] = data[i]
      if (data[i].resourcesDetails && data[i].resourcesDetails.length) {
        normalActivityTemplateMap.value[data[i].code].resourceFilePath = data[i].resourcesDetails[0].filePath
      }
    }
  }

  function setNormalActivityShowAnimation(data: boolean) {
    normalActivityShowAnimation.value = data
  }

  function setNormalActivityRefreshLog(data: boolean) {
    normalActivityRefreshLog.value = data
  }

  function setTemplateBCouponCode(code: string) {
    templateBCouponCode.value = code
  }

  // 設置批量換購
  function setExchangeBatchStatus(flag: boolean) {
    exchangeBatchStatus.value = flag
  }

  return {
    normalActivityTemplateMap,
    setNormalActivityTemplateMap,
    normalActivityShowAnimation,
    setNormalActivityShowAnimation,
    normalActivityRefreshLog,
    setNormalActivityRefreshLog,
    templateBCouponCode,
    setTemplateBCouponCode,
    exchangeBatchStatus,
    setExchangeBatchStatus,
  }
})
