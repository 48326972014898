import { changLanguage } from '@/composables/changLanguage'
import { apiSiteQuery, apiAreaCodeQuery, apiLanguageQuery } from '@/service/api/common'
import type { IString } from '@/types/IString'
import type { ISite } from '@/types/ISite'
import { useMemberStore } from '@/store/member'
import { showFailToast } from 'vant'
import { apiGetAppConfig } from '~/service/api/common'
import type { CONFIG } from '@/types/ISystem'
import { watchEffect } from 'vue'

type areaCodeType = {
  name: string
  rule: RegExp
  flag: string
  isDefault: string | number
}
interface IAreaCode {
  [key: number]: areaCodeType
}

export const useSystemStore = defineStore('system', () => {
  const { t } = useI18n()
  const { setAreaCode } = useMemberStore()
  // 页面是否可见（是否最小化浏览器、切换到别的 tab）
  const pageVisible = ref(false)
  // 是否顯示站點選擇彈窗
  const showSiteSelect = ref(false)
  // 是否顯示多語言選擇彈窗
  const showLanguageSelect = ref(false)
  // 站點列表
  const siteList = ref<any[]>([])
  // 多語言列表
  const languageList = ref<any[]>([])
  // 交收方式
  const sendWay = ref<any[]>([])
  // 區號列表
  const areaCodeMap = ref<IAreaCode>({})
  // 當前站點
  const currentSite = ref('')
  // 當前站點默認區號
  const defaultAreaCode = ref('')
  // 當前站點 ICP
  const icpCode = ref('')
  // 當前語言
  const currentLanguage = ref('')
  // 視頻是否自動播放
  const autoPlay = ref(false)
  // 是否顯示loading
  const loading = ref(false)
  // loading 的统计次数，用于复制测试
  const loadingTrueCount = ref(0)
  const loadingFalseCount = ref(0)
  // 未登录时显示聊天提示框
  const chatDialog = ref(false)
  // 是否显示聊天框
  const chatPopup = ref(false)
  // 系統配置
  const config = ref<CONFIG>({
    thirdPartCode: '',
    region: '',
    ip: '',
  })
  // 是否PC模式
  const isPC = ref(false)

  // cookie過期時間6個月
  const maxAge = 60 * 60 * 24 * 31 * 6

  // 圖片格式字典
  const picDict = ref([
    { code: '01', name: 'bmp' },
    { code: '02', name: 'jpg' },
    { code: '03', name: 'jpeg' },
    { code: '04', name: 'png' },
    { code: '05', name: 'ico' },
    { code: 'F001', name: 'mp4' },
    { code: 'F002', name: 'gif' },
  ])

  // 站點選擇
  function toggleSiteSelectShow(flag: boolean = !showSiteSelect.value) {
    showSiteSelect.value = flag
  }

  // 站點選擇
  function changeSiteSelect(site: string) {
    if (site) {
      currentSite.value = site
      // 存储到cookie里面，用于无匹配站点时使用
      useCookie('newki-site', { maxAge }).value = site
    }
  }

  watchEffect(() => {
    siteList.value.forEach((item: any) => {
      if (item.value == currentSite.value && icpCode.value == '') {
        icpCode.value = item.icpCode
      }
    })
  })

  // 語言選擇
  function changeLanguageSelect(flag: boolean, language?: string, site?: string) {
    showLanguageSelect.value = flag
    if (language && site) {
      currentLanguage.value = language
      changLanguage(language)
      // 存储到cookie里面，用于无匹配站点时使用,存儲為key-value的形式，每個站點都有默認語言
      const obj: IString = {}
      obj[site] = language
      useCookie('newki-language', { maxAge }).value = JSON.stringify(obj)
    }
  }

  // 設置站點列表
  function setSiteList(list: []) {
    siteList.value = []
    list.forEach((item: ISite) => {
      siteList.value.push({
        text: item.name,
        value: item.id,
        code: item.code,
        defaultLanguageCode: item.defaultLanguageCode,
        defaultLanguageId: item.defaultLanguageId,
        defaultLanguageName: item.defaultLanguageName,
        sendWay: item.settlementWay,
        frontDisplay: item.frontDisplay,
        isDefault: item.isDefault,
        status: item.status,
        icpCode: item.icpCode,
      })
    })
  }

  // 設置多語言列表
  function setLanguageList(list: []) {
    languageList.value = []
    list.forEach((item: any) => {
      languageList.value.push({
        text: item.languageName,
        value: item.languageCode,
        languageId: item.languageId,
      })
    })
  }

  // 設置區號列表
  function setAreaCodeList(list: []) {
    areaCodeMap.value = {}
    list.forEach((item: any) => {
      areaCodeMap.value[item.areaCode] = {
        name: item.name,
        rule: item.rule || '',
        flag: item.banner,
        isDefault: item.isDefault,
      }
      if (item.isDefault === '1') {
        setAreaCode(item.areaCode)
        defaultAreaCode.value = item.areaCode
      }
    })
  }

  // 站點查詢
  async function querySite() {
    const data = await apiSiteQuery()
    if (data?.list) {
      // console.log('apiSiteQuery:', data)
      setSiteList(data.list)
      return Promise.resolve(data)
    } else {
      showFailToast(t('site.areaCodeError'))
      return Promise.reject(data)
    }
  }

  // 查詢站點語言
  async function querySiteLanguage(siteId: string) {
    const data = await apiLanguageQuery(siteId)
    if (data) {
      setLanguageList(data.list)
    }
  }

  async function getSystemConfig() {
    const data = await apiGetAppConfig()
    if (data) {
      config.value = data
      initAnalyze()
    }
  }

  function initAnalyze() {
    const { $analyze }: any = useNuxtApp()
    $analyze.init()
  }

  // 查詢站點區號
  async function querySiteAreaCode(siteId: string) {
    const data = await apiAreaCodeQuery(siteId)
    if (data) {
      setAreaCodeList(data.list)
    }
  }

  // 設置交收方式
  function setSendWay(data: string) {
    if (data) {
      sendWay.value = JSON.parse(data)
    } else {
      sendWay.value = JSON.parse('{}')
    }
    useCookie('newki-sendWay', { maxAge }).value = data
  }

  function updateLoadingState(state: boolean, duration: number = 20 * 1000) {
    if (isServer()) return false

    loading.value = state
    // 默认 20s 的超时后关闭
    const t = setTimeout(() => {
      loading.value = false
      clearTimeout(t)
    }, duration)

    // 添加统计数据，辅助测试
    if (state) {
      loadingTrueCount.value++
      const count = {
        t: loadingTrueCount.value,
        f: loadingFalseCount.value,
      }
      try {
        sessionStorage.setItem('loadingCount', JSON.stringify(count))
      } catch (err) {
        console.log('UpdateLoadingState Error:', err)
      }
    }
    if (!state) {
      loadingFalseCount.value++
      const count = {
        t: loadingTrueCount.value,
        f: loadingFalseCount.value,
      }
      try {
        sessionStorage.setItem('loadingCount', JSON.stringify(count))
      } catch (err) {
        console.log('UpdateLoadingState Error:', err)
      }
    }
  }

  function updateChatDialog(state: boolean) {
    chatDialog.value = state
  }

  function updateChatPopup(state: boolean) {
    chatPopup.value = state
  }

  function setPageVisibility(state: boolean = !pageVisible.value) {
    pageVisible.value = state
  }

  function setPC(flag: boolean) {
    isPC.value = flag
  }

  return {
    showSiteSelect,
    showLanguageSelect,
    siteList,
    languageList,
    setSiteList,
    areaCodeMap,
    currentSite,
    currentLanguage,
    icpCode,
    setLanguageList,
    changeSiteSelect,
    toggleSiteSelectShow,
    changeLanguageSelect,
    querySite,
    querySiteLanguage,
    querySiteAreaCode,
    autoPlay,
    sendWay,
    setSendWay,
    picDict,
    loading,
    updateLoadingState,
    defaultAreaCode,
    getSystemConfig,
    config,
    pageVisible,
    setPageVisibility,
    chatDialog,
    chatPopup,
    updateChatDialog,
    updateChatPopup,
    isPC,
    setPC,
  }
})
