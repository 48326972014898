export enum OrderStatus {
  cancel = '0', // 取消
  payment = '10', // 待付款
  paySure = '11', // 支付確認中
  payError = '12', // 支付異常
  payNotAll = '13', // 部分付款
  handle = '20', // 門店備貨中
  storageHandle = '21', // 倉庫備貨中
  waitTake = '30', // 待取貨
  waitReceive = '31', // 待收貨
  finish = '40', // 已完成
}

export enum OrderStatusType {
  payment = '10', // 待付款
  handle = '20', // 待處理
  settlement = '30', // 待交收
  comment = '40', // 待評論
  other = '0', // 其他
}

export enum PayStatus {
  paid = '1', // 已付款
  confirming = '2', // 支付確認中
  error = '3', // 支付異常
  partial = '4', // 部分付款
  unpaid = '5', // 未付款
}

export enum OrderVipType {
  normal = '0', // 直接購買
  addon = '1', // 訂單加購
  activity_exchange = '3', // 活動兌換
  activity_give = '4', // 活動贈送
}
