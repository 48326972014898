<template>
  <Nuxt-icon
    :name="props.name"
    :filled="props.filled"
    :style="{ fontSize: `${px2rem(props.size)}rem`, color: props.color }"
  />
</template>

<script setup>
const props = defineProps({
  name: {
    type: String,
    required: true,
  },
  size: {
    type: Number,
    required: false,
    default: 14,
  },
  filled: {
    type: Boolean,
    required: false,
    default: false,
  },
  color: {
    type: String,
    required: false,
    default: '#64748b',
  },
})
</script>

<style scoped></style>
