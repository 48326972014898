<template>
  <div class="item-box">
    <!-- <div class="title" :class="{ success: type === 'success' }">{{ title }}</div> -->
    <!-- <div class="bg-box">
      <img class="text" width="159" height="36" :src="textImgUrl" alt="" />
      <img class="color" width="375" height="62" :src="bgImgUrl" alt="" />
    </div> -->
    <div class="content-box">
      <slot></slot>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, watch } from 'vue'
import NewkiText from '~/assets/images/common/newki-text.svg'
import FamilyText from '~/assets/images/common/family-text.svg'
import ReviewText from '~/assets/images/common/review-text.svg'
import InfoText from '~/assets/images/common/info-text.svg'
import ShareText from '~/assets/images/common/share-text.svg'
import ShopText from '~/assets/images/common/shop-text.svg'

import BlueBg from '~/assets/images/common/primary-section.svg'
import GreenBg from '~/assets/images/common/success-section.svg'

const UrlList: any = {
  newki: NewkiText,
  family: FamilyText,
  review: ReviewText,
  share: ShareText,
  info: InfoText,
  shop: ShopText,
}

const BgUrlList: any = {
  primary: BlueBg,
  success: GreenBg,
}

// 文字圖片
const textImgUrl = ref('')
// 背景圖片
const bgImgUrl = ref('')

const props = defineProps({
  title: {
    type: String,
    required: false,
    default: '新奇站',
  },
  text: {
    type: String,
    required: false,
    default: 'newki',
  },
  type: {
    type: String,
    required: false,
    default: 'primary', // primary、success
  },
  padding: {
    type: String,
    required: false,
    default: '14px 0',
  },
})

watch(
  () => props.text,
  val => {
    if (val) {
      textImgUrl.value = UrlList[props.text]
    }
  },
  { immediate: true }
)

watch(
  () => props.type,
  val => {
    if (val) {
      bgImgUrl.value = BgUrlList[props.type]
    }
  },
  { immediate: true }
)
</script>

<style scoped lang="scss">
.item-box {
  @include flex(column, flex-start, center);
  background-size: cover;
  background-position: center center;

  .title {
    color: $primary;
    font-size: 20px;
    font-weight: 600;
    line-height: 1;

    &.success {
      color: $secondary;
    }
  }

  .bg-box {
    position: relative;
    z-index: 10;
    width: 100%;
    margin-top: 8px;

    .text {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: 11;
      display: block;
      width: 159px;
      height: 36.5px;
      margin: auto;
    }

    .color {
      display: block;
      width: 100%;
      height: auto;
    }
  }

  .content-box {
    width: 100%;
    height: auto;
  }
}
</style>
