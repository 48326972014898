<template>
  <div class="productList" :class="{ row: wData.layout === 'Horizontal' }" :style="widgetStyle">
    <template v-for="item in wData?.productList" :key="item.skuId">
      <NewkiGoodsItem
        :imgSize="imgSize"
        :layout="wData.layout"
        :imgSrc="item.skuImage"
        :goodsSpec="item.saleAttr"
        :goodsName="item.skuName"
        :price="item.retailPrice"
        :retailPrice="item.retailPrice"
        :vipPrice="item.memberPrice"
        :box="true"
        @onClick="goDetail(item.skuId)"
        v-if="item.skuId"
      >
        <template #action>
          <NewkiCollect :mallSkuId="item.skuId" :isCollected="item.collect" />
        </template>
      </NewkiGoodsItem>
    </template>
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted, computed } from 'vue'
import NewkiGoodsItem from '~/components/NewkiGoodsItem.vue'
import { useStyle } from './renderHooks'
import { useMemberStore } from '~/store/member'

const imgSize: Ref<number | undefined> = ref(undefined)

const props = defineProps({
  wData: {
    type: Object,
    required: true,
  },
})
const { memberInfo } = storeToRefs(useMemberStore())
const isVip = computed(() => {
  return memberInfo.value.payMember
})

// 组件样式
const widgetStyle: any = useStyle(props.wData).widgetStyle
const initSize = () => {
  let screenWidth = document.body?.getBoundingClientRect().width

  let padding = props.wData?.widgetStyle?.paddingLeft + props.wData?.widgetStyle?.paddingRight

  let _imgSize: number = (167 / 375) * (screenWidth - padding) - 4
  imgSize.value = _imgSize
}

const goDetail = (id: number | string) => {
  routerPush('product-skuId-contentId', { params: { skuId: +id } })
}

onMounted(() => {
  props.wData.layout === 'Horizontal' ? initSize() : ''
})
</script>

<style scoped lang="scss">
.productList {
  @include flex(column, flex-start, flex-start);
  width: 100%;
  padding: 16px 12px 0;
  // margin-top: 16px;
  background-color: $white;
  @include cursor();

  &.row {
    // @include flex(row, flex-start, flex-start);
    // flex-wrap: wrap;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 4px;

    :deep(.goods-item-container) {
      width: unset;
      margin-bottom: 10px;
    }
  }

  :deep(.goods-item-container) {
    margin-bottom: 10px;
    width: 100%;
  }

  :deep(.van-image) {
    img {
      object-fit: contain;
    }
  }
}
</style>
