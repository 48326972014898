<template>
  <div class="item-box" :style="widgetStyle">
    <div class="pic-list picture-window">
      <NuxtLink class="img-viewer" :style="images5ModeStyle" :to="useContentActionNuxtLink(wData.items[activeIndex])">
        <div class="lg-img">
          <img :src="lgImageUrl" alt="" v-if="lgImageUrl" />
          <img :src="HolderImage" alt="" v-else />
        </div>
      </NuxtLink>
      <div class="img-list">
        <div
          class="img-item"
          :class="{ active: index === activeIndex }"
          v-for="(item, index) in wData.items"
          @click.stop="activeViewerImage(index)"
        >
          <div class="small-img">
            <div class="item-image">
              <img :src="item.serverPath" alt="" v-if="item.serverPath" />
              <img :src="HolderImage" alt="" v-else />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, watch, onMounted, nextTick } from 'vue'
import HolderImage from '~/assets/images/empty/normal.svg'
import { useStyle } from './renderHooks'

const props = defineProps({
  wData: {
    type: Object,
    required: false,
    default: () => {},
  },
})

// 组件样式
const widgetStyle: any = useStyle(props.wData).widgetStyle

const activeIndex: Ref<number> = ref(0)
const images5ModeStyle = ref({})

const lgImageUrl = ref(props.wData.extraData.lgImageUrl)

const calcImage5Mode = (wData: any = props.wData) => {
  if (wData.items.length >= 5) {
    images5ModeStyle.value = {
      paddingBottom: '125%',
    }
  } else {
    images5ModeStyle.value = {
      paddingBottom: '',
    }
  }
}

const activeViewerImage = (index: number = 0) => {
  activeIndex.value = index
  lgImageUrl.value = props.wData.items[index].serverPath
}

onMounted(() => {
  calcImage5Mode()
  activeViewerImage()
})
</script>

<style scoped lang="scss">
.item-box {
  position: relative;
  z-index: 10;
  background-size: cover;
  background-position: center center;

  .pic-list {
    padding: 1px;

    &.picture-window {
      display: grid;
      grid-template-columns: 80% 20%;
      color: initial;

      .img-viewer {
        position: relative;
        z-index: 10;
        width: 100%;
        height: 0;
        padding-bottom: 100%;
        transition: paddingTop 0.2s ease;

        .lg-img {
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          z-index: 10;
          border-radius: 12px;
          overflow: hidden;

          img {
            display: inline-block;
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
      }

      .img-list {
        @include flex(column, space-evenly, flex-end);
        width: 100%;

        .img-item {
          width: 84%;
          padding-bottom: 84%;
          position: relative;
          z-index: 10;
          border-radius: 6px;
          overflow: hidden;
          cursor: pointer;

          &.active {
            box-shadow: 0 0 2px 2px $primary;
          }

          .small-img {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            z-index: 10;
            // border: 1px solid #ddd;
            border-radius: 6px;
            overflow: hidden;

            .item-image {
              width: 100%;
              height: 100%;

              img {
                display: inline-block;
                width: 100%;
                height: 100%;
                object-fit: cover;
              }
            }
          }
        }
      }
    }
  }
}
</style>
