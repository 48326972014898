<template>
  <div class="pc-back">
    <span>© {{ new Date().getFullYear() }} 新奇站 版權所有</span>
    <a href="https://beian.miit.gov.cn/" class="icp" target="_blank" rel="noopener noreferrer" v-if="icpCode">
      / {{ icpCode }}
    </a>
  </div>
</template>

<script setup lang="ts">
import { useSystemStore } from '~/store/system'
const { icpCode } = storeToRefs(useSystemStore())
</script>

<style scoped lang="scss">
.pc-back {
  position: fixed;
  font-size: 13px;
  // @include flex(column, center, center);
  @include flex();
  gap: 4px;
  height: 50px;
  padding: 0 20px;
  right: calc(50% - 200px);
  bottom: 0;
  transform: translateX(100%);
  color: $text-color-assist;

  .icp {
    font-size: 13px;
    color: $text-color-assist;
    transition: color 0.2s ease;

    &:hover {
      color: #000;
    }
  }
}
</style>
