import type { routerParams } from '@/types/router'

export const routerNuxtLink = (name: string, params?: routerParams) => {
  let url = ''
  const siteCode = useRoute()?.meta?.siteCode
  const i18nCode = useRoute()?.meta?.i18nCode

  let hasMiddleLine = false
  if (name.indexOf('-') >= 0) {
    name = name.replaceAll('-', '/')
    hasMiddleLine = true
  }
  if (!siteCode && !i18nCode) {
    url = `/${name}`
  } else if (siteCode && !i18nCode) {
    url = `/${siteCode}/${name}`
  } else {
    url = `/${siteCode}/${i18nCode}/${name}`
  }

  if (params?.query) {
    const query: any = params.query
    url = url + '?'
    Object.keys(query).forEach(key => {
      if (hasMiddleLine == true) {
        url = url.replaceAll(key, query[key])
      } else {
        url = url + '&' + key + '=' + query[key]
      }
    })
    // 去除第一个参数多余的 &
    url = url.replace('?&', '?')
  }

  if (params?.params) {
    const theParams: any = params.params
    url = url.indexOf('?') > 0 ? url : url + '?'
    Object.keys(theParams).forEach(key => {
      if (hasMiddleLine == true) {
        url = url.replaceAll(key, theParams[key])
      } else {
        url = url + '&' + key + '=' + theParams[key]
      }
    })
  }
  return url
}
