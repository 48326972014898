<template>
  <svg
    width="56.000008"
    height="56.000015"
    viewBox="0 0 56 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <defs />
    <ellipse id="Ellipse 187" cx="28.000000" cy="28.000015" rx="28.000004" ry="28.000008" fill="#FFFFFF" />
    <ellipse
      id="Ellipse 187"
      cx="28.000000"
      cy="28.000015"
      rx="27.000004"
      ry="27.000008"
      stroke="#00AEEB"
      stroke-width="2.000000"
    />
    <mask
      id="mask1394_1336"
      mask-type="alpha"
      maskUnits="userSpaceOnUse"
      x="2.000000"
      y="2.000000"
      width="52.000000"
      height="52.000000"
    >
      <circle id="Ellipse 185" cx="28.000000" cy="28.000000" r="26.000000" fill="#FFFFFF" />
    </mask>
    <g mask="url(#mask1394_1336)">
      <path
        id="Rectangle 494"
        d="M22.4276 50.3299L34.0581 50.3299C34.6251 50.3299 34.9938 49.7332 34.7403 49.2261L34.328 48.4016C34.1988 48.1432 33.9348 47.98 33.6459 47.98L22.8398 47.98C22.5509 47.98 22.2869 48.1432 22.1577 48.4016L21.7455 49.2261C21.4919 49.7332 21.8606 50.3299 22.4276 50.3299L22.4276 50.3299Z"
        fill-rule="evenodd"
        fill="#000000"
      />
      <path
        id="Ellipse 158"
        d="M19.4341 45.3364C19.4341 42.5786 23.3791 40.343 28.2456 40.343C33.1121 40.343 37.0572 42.5786 37.0572 45.3364C37.0572 48.0942 33.1121 50.3299 28.2456 50.3299C23.3791 50.3299 19.4341 48.0942 19.4341 45.3364L19.4341 45.3364Z"
        fill-rule="evenodd"
        fill="#00AEEB"
      />
      <path
        id="Ellipse 155"
        d="M6.5083 26.2439C6.5083 14.8883 16.2395 5.6827 28.2435 5.6827C40.2475 5.6827 49.9787 14.8883 49.9787 26.2439C49.9787 37.5996 40.2475 46.8052 28.2435 46.8052C16.2395 46.8052 6.5083 37.5996 6.5083 26.2439L6.5083 26.2439Z"
        fill-rule="evenodd"
        fill="#00AEEB"
      />
      <path
        id="Subtract"
        d="M11.3198 39.147C15.3041 43.8176 21.4048 46.805 28.2432 46.805C40.2472 46.805 49.9784 37.5994 49.9784 26.2438C49.9784 20.1932 47.2157 14.7531 42.8186 10.9906C45.8283 14.5188 47.6304 19.0073 47.6304 23.8939C47.6304 35.2496 37.8992 44.4552 25.8952 44.4552C20.2872 44.4552 15.1752 42.446 11.3198 39.147L11.3198 39.147Z"
        fill-rule="evenodd"
        fill="#1D97D5"
      />
      <path
        id="Ellipse 161 (边框)"
        d="M10.9895 39.3471C14.1784 43.1076 18.2378 45.536 23.1675 46.6322L23.333 45.8878C18.5702 44.8286 14.6495 42.4839 11.5711 38.8538C8.45012 35.1734 6.88962 30.9701 6.88962 26.2439C6.88962 20.6756 8.97189 15.9216 13.1364 11.982C17.307 8.03668 22.3427 6.06403 28.2435 6.06403C34.1443 6.06403 39.18 8.03668 43.3505 11.982C47.5151 15.9216 49.5974 20.6756 49.5974 26.2439C49.5974 30.9882 48.0261 35.2042 44.8835 38.8918C41.783 42.5301 37.8384 44.8697 33.05 45.9106L33.212 46.6559C38.1681 45.5786 42.2521 43.1554 45.464 39.3865C48.728 35.5564 50.36 31.1755 50.36 26.2439C50.36 20.4566 48.1982 15.5179 43.8746 11.4279C39.5571 7.34354 34.3467 5.30136 28.2435 5.30136C22.1403 5.30136 16.9299 7.34354 12.6124 11.4279C8.28877 15.518 6.12698 20.4566 6.12698 26.2439C6.12698 31.1567 7.74782 35.5244 10.9895 39.3471L10.9895 39.3471Z"
        fill-rule="evenodd"
        fill="#000000"
      />
      <path
        id="Ellipse 162"
        d="M37.0572 31.8248C37.0572 36.8183 33.4366 45.3365 28.2456 45.3365C23.0547 45.3365 19.4341 36.8183 19.4341 31.8248C19.4341 27.7692 23.0547 25.069 28.2456 25.069C33.4366 25.069 37.0572 27.7692 37.0572 31.8248L37.0572 31.8248Z"
        fill-rule="evenodd"
        fill="#A9CD36"
      />
      <path
        id="Ellipse 162 (边框)"
        d="M21.2291 40.828C23.2122 44.3421 25.551 46.0992 28.2456 46.0992C30.9402 46.0992 33.2791 44.3421 35.2622 40.828C36.9673 37.8066 37.8198 34.8055 37.8198 31.8248C37.8198 29.5539 36.8998 27.7186 35.0597 26.319C33.2956 24.9772 31.0242 24.3063 28.2456 24.3063C25.467 24.3063 23.1957 24.9772 21.4316 26.319C19.5915 27.7186 18.6714 29.5539 18.6714 31.8248C18.6714 34.8055 19.524 37.8066 21.2291 40.828L21.2291 40.828M37.0572 31.8248C37.0572 36.8183 33.4366 45.3365 28.2456 45.3365C23.0547 45.3365 19.4341 36.8183 19.4341 31.8248C19.4341 27.7692 23.0547 25.069 28.2456 25.069C33.4366 25.069 37.0572 27.7692 37.0572 31.8248L37.0572 31.8248Z"
        fill-rule="evenodd"
        fill="#000000"
      />
      <path
        id="Ellipse 156"
        d="M15.9091 46.5113C15.9091 44.4024 17.6187 42.6928 19.7275 42.6928C21.8363 42.6928 23.5458 44.4024 23.5458 46.5113C23.5458 48.6202 21.8363 50.3298 19.7275 50.3298C17.6187 50.3298 15.9091 48.6202 15.9091 46.5113L15.9091 46.5113Z"
        fill-rule="evenodd"
        fill="#00AEEB"
      />
      <path
        id="Subtract"
        d="M16.7535 48.9071C17.4534 49.7748 18.5254 50.3299 19.727 50.3299C21.8358 50.3299 23.5453 48.6203 23.5453 46.5114C23.5453 45.3042 22.9851 44.2278 22.1105 43.528C22.639 44.1832 22.9554 45.0165 22.9554 45.9238C22.9554 48.0327 21.2458 49.7423 19.137 49.7423C18.2354 49.7423 17.4067 49.4298 16.7535 48.9071L16.7535 48.9071Z"
        fill-rule="evenodd"
        fill="#1D97D5"
      />
      <path
        id="Ellipse 159 (边框)"
        d="M21.4249 50.3539C21.9448 50.1239 22.4 49.8008 22.7906 49.3846C22.9367 49.2288 22.9291 48.9917 22.7734 48.8456C22.6177 48.6994 22.3805 48.707 22.2344 48.8627C21.9146 49.2036 21.5419 49.4682 21.1164 49.6564C20.6762 49.8512 20.2132 49.9485 19.7275 49.9485C19.2611 49.9485 18.8152 49.8586 18.3897 49.6786C17.9786 49.5047 17.6144 49.2591 17.2971 48.9418C16.9799 48.6245 16.7343 48.2603 16.5604 47.8492C16.3804 47.4236 16.2904 46.9777 16.2904 46.5114C16.2904 46.045 16.3804 45.5991 16.5604 45.1736C16.7343 44.7624 16.9799 44.3982 17.2971 44.0809C17.6144 43.7636 17.9786 43.5181 18.3897 43.3441C18.8152 43.1642 19.2611 43.0742 19.7275 43.0742C20.1615 43.0742 20.5787 43.1525 20.9789 43.309C21.3656 43.4603 21.7142 43.6755 22.0246 43.9546C22.3336 44.2324 22.584 44.5549 22.7758 44.9219C22.9725 45.2984 23.0948 45.6979 23.1426 46.1206C23.1665 46.3328 23.3521 46.4807 23.5643 46.4567C23.7765 46.4327 23.9243 46.2471 23.9004 46.0349C23.8419 45.518 23.6924 45.0293 23.4517 44.5687C23.2175 44.1204 22.9117 43.7266 22.5345 43.3875C22.1554 43.0466 21.7295 42.7837 21.2567 42.5987C20.7672 42.4073 20.2574 42.3115 19.7275 42.3115C19.158 42.3115 18.6131 42.4216 18.0927 42.6417C17.5902 42.8542 17.1452 43.1542 16.7579 43.5416C16.3705 43.929 16.0705 44.374 15.858 44.8765C15.6379 45.3969 15.5278 45.9419 15.5278 46.5114C15.5278 47.0808 15.6379 47.6258 15.858 48.1462C16.0705 48.6488 16.3705 49.0937 16.7579 49.4811C17.1452 49.8685 17.5902 50.1685 18.0927 50.381C18.6131 50.6012 19.158 50.7112 19.7275 50.7112C20.3206 50.7112 20.8865 50.5921 21.4249 50.3539L21.4249 50.3539Z"
        fill-rule="evenodd"
        fill="#000000"
      />
      <path
        id="Ellipse 157"
        d="M32.9424 46.5113C32.9424 44.4024 34.652 42.6928 36.7608 42.6928C38.8696 42.6928 40.5791 44.4024 40.5791 46.5113C40.5791 48.6202 38.8696 50.3298 36.7608 50.3298C34.652 50.3298 32.9424 48.6202 32.9424 46.5113L32.9424 46.5113Z"
        fill-rule="evenodd"
        fill="#00AEEB"
      />
      <path
        id="Subtract"
        d="M34.3705 43.5344C33.845 44.1886 33.5306 45.0195 33.5306 45.9239C33.5306 48.0328 35.2402 49.7424 37.349 49.7424C38.2543 49.7424 39.086 49.4274 39.7405 48.9009C39.0406 49.7722 37.9664 50.3299 36.762 50.3299C34.6532 50.3299 32.9436 48.6203 32.9436 46.5114C32.9436 45.3078 33.5004 44.2343 34.3705 43.5344L34.3705 43.5344Z"
        fill-rule="evenodd"
        fill="#1D97D5"
      />
      <path
        id="Ellipse 160 (边框)"
        d="M35.0401 50.3539C34.5202 50.1239 34.065 49.8008 33.6744 49.3846C33.5283 49.2288 33.5359 48.9917 33.6916 48.8456C33.8473 48.6994 34.0845 48.707 34.2306 48.8627C34.5505 49.2036 34.9231 49.4682 35.3486 49.6564C35.7888 49.8512 36.2518 49.9485 36.7376 49.9485C37.2039 49.9485 37.6498 49.8586 38.0753 49.6786C38.4864 49.5047 38.8506 49.2591 39.1679 48.9418C39.4852 48.6245 39.7307 48.2603 39.9046 47.8492C40.0846 47.4236 40.1746 46.9777 40.1746 46.5114C40.1746 46.045 40.0846 45.5991 39.9046 45.1736C39.7307 44.7624 39.4852 44.3982 39.1679 44.0809C38.8506 43.7636 38.4864 43.5181 38.0753 43.3441C37.6498 43.1642 37.2039 43.0742 36.7376 43.0742C36.3035 43.0742 35.8864 43.1525 35.4861 43.309C35.0994 43.4603 34.7508 43.6755 34.4404 43.9546C34.1314 44.2324 33.881 44.5549 33.6892 44.9219C33.4925 45.2984 33.3702 45.6979 33.3225 46.1206C33.2985 46.3328 33.1129 46.4807 32.9007 46.4567C32.6886 46.4327 32.5407 46.2471 32.5647 46.0349C32.6231 45.518 32.7726 45.0293 33.0133 44.5687C33.2476 44.1204 33.5533 43.7266 33.9305 43.3875C34.3096 43.0466 34.7355 42.7837 35.2083 42.5987C35.6979 42.4073 36.2076 42.3115 36.7376 42.3115C37.307 42.3115 37.8519 42.4216 38.3724 42.6417C38.8749 42.8542 39.3198 43.1542 39.7072 43.5416C40.0946 43.929 40.3945 44.374 40.607 44.8765C40.8272 45.3969 40.9372 45.9419 40.9372 46.5114C40.9372 47.0808 40.8272 47.6258 40.607 48.1462C40.3945 48.6488 40.0946 49.0937 39.7072 49.4811C39.3198 49.8685 38.8749 50.1685 38.3724 50.381C37.8519 50.6012 37.307 50.7112 36.7376 50.7112C36.1444 50.7112 35.5786 50.5921 35.0401 50.3539L35.0401 50.3539Z"
        fill-rule="evenodd"
        fill="#000000"
      />
      <path
        id="Rectangle 493"
        d="M22.4895 49.7426L33.9963 49.7426C34.6843 49.7426 35.2167 50.3457 35.1314 51.0285L34.2007 58.4739C34.153 58.8556 33.8286 59.142 33.444 59.142L23.0417 59.142C22.6571 59.142 22.3327 58.8556 22.285 58.4739L21.3543 51.0285C21.269 50.3457 21.8014 49.7426 22.4895 49.7426L22.4895 49.7426Z"
        fill-rule="evenodd"
        fill="#A9CD36"
      />
      <path
        id="Subtract"
        d="M22.2235 57.967L22.2869 58.4737C22.3346 58.8554 22.659 59.1418 23.0436 59.1418L33.4459 59.1418C33.8305 59.1418 34.1549 58.8554 34.2026 58.4737L35.1333 51.0283C35.2186 50.3455 34.6862 49.7424 33.9981 49.7424C33.9971 49.7791 33.9625 49.8162 33.9578 49.8535L33.0272 57.2989C32.9795 57.6806 32.655 57.967 32.2704 57.967L22.2235 57.967Z"
        fill-rule="evenodd"
        fill="#98B22A"
      />
      <path
        id="Rectangle 495 (边框)"
        d="M21.3543 51.0285C21.269 50.3457 21.8014 49.7426 22.4895 49.7426L33.9963 49.7426C34.6843 49.7426 35.2167 50.3457 35.1314 51.0285L34.2007 58.4739C34.153 58.8556 33.8286 59.142 33.444 59.142L23.0417 59.142C22.6571 59.142 22.3327 58.8556 22.285 58.4739L21.3543 51.0285M22.4895 50.5056L33.9963 50.5056C34.1108 50.5056 34.206 50.5486 34.2818 50.6345C34.3577 50.7204 34.3885 50.8202 34.3743 50.9338L33.4437 58.379L23.0421 58.379L22.1115 50.9338C22.0973 50.8202 22.1281 50.7204 22.2039 50.6345C22.2797 50.5486 22.3749 50.5056 22.4895 50.5056L22.4895 50.5056Z"
        fill-rule="evenodd"
        fill="#000000"
      />
      <path
        id="Ellipse 166"
        d="M10.034 20.0756C10.034 16.6689 12.7955 13.9072 16.2021 13.9072C19.6086 13.9072 22.3702 16.6689 22.3702 20.0756C22.3702 23.4823 19.6086 26.2439 16.2021 26.2439C12.7955 26.2439 10.034 23.4823 10.034 20.0756L10.034 20.0756Z"
        fill-rule="evenodd"
        fill="#231815"
      />
      <path
        id="Ellipse 166 (边框)"
        d="M9.81628 17.3775C9.45297 18.2365 9.27132 19.1358 9.27132 20.0756C9.27132 21.0153 9.45297 21.9147 9.81628 22.7737C10.167 23.603 10.662 24.3373 11.3013 24.9765C11.9405 25.6158 12.6748 26.1109 13.5041 26.4616C14.363 26.8249 15.2624 27.0066 16.202 27.0066C17.1417 27.0066 18.0411 26.8249 18.9 26.4616C19.7293 26.1109 20.4636 25.6158 21.1028 24.9765C21.7421 24.3373 22.2371 23.603 22.5878 22.7737C22.9511 21.9147 23.1328 21.0153 23.1328 20.0756C23.1328 19.1358 22.9511 18.2365 22.5878 17.3775C22.2371 16.5481 21.7421 15.8139 21.1028 15.1746C20.4636 14.5353 19.7293 14.0403 18.9 13.6895C18.0411 13.3262 17.1417 13.1445 16.202 13.1445C15.2624 13.1445 14.363 13.3262 13.5041 13.6895C12.6748 14.0403 11.9405 14.5353 11.3013 15.1746C10.662 15.8139 10.167 16.5481 9.81628 17.3775L9.81628 17.3775M10.034 20.0756C10.034 16.6689 12.7955 13.9072 16.202 13.9072C19.6086 13.9072 22.3701 16.6689 22.3701 20.0756C22.3701 23.4823 19.6086 26.2439 16.202 26.2439C12.7955 26.2439 10.034 23.4823 10.034 20.0756L10.034 20.0756Z"
        fill-rule="evenodd"
        fill="#FFFFFF"
      />
      <path
        id="Ellipse 165"
        d="M11.208 20.0757C11.208 17.3179 13.4435 15.0822 16.2012 15.0822C18.9589 15.0822 21.1944 17.3179 21.1944 20.0757C21.1944 22.8335 18.9589 25.0691 16.2012 25.0691C13.4435 25.0691 11.208 22.8335 11.208 20.0757L11.208 20.0757Z"
        fill-rule="evenodd"
        fill="#231815"
      />
      <path
        id="Ellipse 165 (边框)"
        d="M11.208 20.0757C11.208 17.3179 13.4435 15.0822 16.2012 15.0822C18.9589 15.0822 21.1944 17.3179 21.1944 20.0757C21.1944 22.8335 18.9589 25.0691 16.2012 25.0691C13.4435 25.0691 11.208 22.8335 11.208 20.0757L11.208 20.0757M11.9516 21.8708C11.7101 21.2997 11.5893 20.7014 11.5893 20.0757C11.5893 19.4499 11.7101 18.8516 11.9516 18.2805C12.1849 17.7288 12.5144 17.2401 12.9401 16.8144C13.3658 16.3887 13.8544 16.0592 14.4062 15.8258C14.9772 15.5843 15.5755 15.4635 16.2012 15.4635C16.8269 15.4635 17.4252 15.5843 17.9962 15.8258C18.548 16.0592 19.0366 16.3887 19.4623 16.8144C19.888 17.2401 20.2175 17.7288 20.4508 18.2805C20.6923 18.8516 20.8131 19.4499 20.8131 20.0757C20.8131 20.7014 20.6923 21.2997 20.4508 21.8708C20.2175 22.4225 19.888 22.9112 19.4623 23.3369C19.0366 23.7626 18.548 24.0921 17.9962 24.3255C17.4252 24.567 16.8269 24.6878 16.2012 24.6878C15.5755 24.6878 14.9772 24.567 14.4062 24.3255C13.8544 24.0921 13.3658 23.7626 12.9401 23.3369C12.5144 22.9112 12.1849 22.4225 11.9516 21.8708L11.9516 21.8708Z"
        fill-rule="evenodd"
        fill="#FFFFFF"
      />
      <path
        id="Ellipse 163"
        d="M12.3834 17.7258C12.3834 16.5902 13.304 15.6696 14.4395 15.6696C15.575 15.6696 16.4955 16.5902 16.4955 17.7258C16.4955 18.8613 15.575 19.7819 14.4395 19.7819C13.304 19.7819 12.3834 18.8613 12.3834 17.7258L12.3834 17.7258Z"
        fill-rule="evenodd"
        fill="#FFFFFF"
      />
      <path
        id="Ellipse 164"
        d="M16.4968 20.9568C16.4968 20.308 17.0228 19.7819 17.6717 19.7819C18.3206 19.7819 18.8466 20.308 18.8466 20.9568C18.8466 21.6057 18.3206 22.1318 17.6717 22.1318C17.0228 22.1318 16.4968 21.6057 16.4968 20.9568L16.4968 20.9568Z"
        fill-rule="evenodd"
        fill="#FFFFFF"
      />
      <path
        id="Ellipse 166"
        d="M34.1162 20.0757C34.1162 16.669 36.8777 13.9073 40.2843 13.9073C43.6908 13.9073 46.4524 16.669 46.4524 20.0757C46.4524 23.4824 43.6908 26.2441 40.2843 26.2441C36.8777 26.2441 34.1162 23.4824 34.1162 20.0757L34.1162 20.0757Z"
        fill-rule="evenodd"
        fill="#231815"
      />
      <path
        id="Ellipse 166 (边框)"
        d="M33.8985 17.3776C33.5352 18.2366 33.3535 19.136 33.3535 20.0757C33.3535 21.0154 33.5352 21.9148 33.8985 22.7738C34.2492 23.6031 34.7442 24.3374 35.3835 24.9767C36.0227 25.616 36.757 26.111 37.5863 26.4617C38.4452 26.8251 39.3446 27.0067 40.2843 27.0067C41.224 27.0067 42.1233 26.8251 42.9823 26.4617C43.8115 26.111 44.5458 25.616 45.185 24.9767C45.8243 24.3374 46.3193 23.6031 46.67 22.7738C47.0333 21.9148 47.215 21.0154 47.215 20.0757C47.215 19.136 47.0333 18.2366 46.67 17.3776C46.3193 16.5483 45.8243 15.814 45.185 15.1747C44.5458 14.5354 43.8115 14.0404 42.9823 13.6896C42.1233 13.3263 41.224 13.1447 40.2843 13.1447C39.3446 13.1447 38.4452 13.3263 37.5863 13.6896C36.757 14.0404 36.0227 14.5354 35.3835 15.1747C34.7442 15.814 34.2492 16.5483 33.8985 17.3776L33.8985 17.3776M34.1162 20.0757C34.1162 16.669 36.8777 13.9073 40.2843 13.9073C43.6908 13.9073 46.4524 16.669 46.4524 20.0757C46.4524 23.4824 43.6908 26.2441 40.2843 26.2441C36.8777 26.2441 34.1162 23.4824 34.1162 20.0757L34.1162 20.0757Z"
        fill-rule="evenodd"
        fill="#FFFFFF"
      />
      <path
        id="Ellipse 165"
        d="M35.2902 20.0756C35.2902 17.3178 37.5257 15.0822 40.2834 15.0822C43.0411 15.0822 45.2766 17.3178 45.2766 20.0756C45.2766 22.8334 43.0411 25.069 40.2834 25.069C37.5257 25.069 35.2902 22.8334 35.2902 20.0756L35.2902 20.0756Z"
        fill-rule="evenodd"
        fill="#231815"
      />
      <path
        id="Ellipse 165 (边框)"
        d="M35.2902 20.0756C35.2902 17.3178 37.5257 15.0822 40.2834 15.0822C43.0411 15.0822 45.2766 17.3178 45.2766 20.0756C45.2766 22.8334 43.0411 25.069 40.2834 25.069C37.5257 25.069 35.2902 22.8334 35.2902 20.0756L35.2902 20.0756M36.0338 21.8707C35.7923 21.2997 35.6715 20.7013 35.6715 20.0756C35.6715 19.4499 35.7923 18.8515 36.0338 18.2805C36.2671 17.7287 36.5966 17.24 37.0223 16.8144C37.448 16.3887 37.9367 16.0592 38.4884 15.8258C39.0594 15.5843 39.6577 15.4635 40.2834 15.4635C40.9091 15.4635 41.5075 15.5843 42.0785 15.8258C42.6302 16.0592 43.1189 16.3887 43.5445 16.8144C43.9702 17.24 44.2997 17.7287 44.533 18.2805C44.7746 18.8515 44.8953 19.4499 44.8953 20.0756C44.8953 20.7013 44.7746 21.2997 44.533 21.8707C44.2997 22.4225 43.9702 22.9112 43.5445 23.3369C43.1189 23.7625 42.6302 24.0921 42.0785 24.3254C41.5074 24.5669 40.9091 24.6877 40.2834 24.6877C39.6577 24.6877 39.0594 24.567 38.4884 24.3254C37.9367 24.0921 37.448 23.7625 37.0223 23.3369C36.5966 22.9112 36.2671 22.4225 36.0338 21.8707L36.0338 21.8707Z"
        fill-rule="evenodd"
        fill="#FFFFFF"
      />
      <path
        id="Ellipse 163"
        d="M36.4657 17.7259C36.4657 16.5903 37.3862 15.6698 38.5217 15.6698C39.6572 15.6698 40.5777 16.5903 40.5777 17.7259C40.5777 18.8615 39.6572 19.782 38.5217 19.782C37.3862 19.782 36.4657 18.8615 36.4657 17.7259L36.4657 17.7259Z"
        fill-rule="evenodd"
        fill="#FFFFFF"
      />
      <path
        id="Ellipse 164"
        d="M40.579 20.9568C40.579 20.308 41.105 19.7819 41.7539 19.7819C42.4028 19.7819 42.9288 20.308 42.9288 20.9568C42.9288 21.6057 42.4028 22.1318 41.7539 22.1318C41.105 22.1318 40.579 21.6057 40.579 20.9568L40.579 20.9568Z"
        fill-rule="evenodd"
        fill="#FFFFFF"
      />
    </g>
  </svg>
</template>

<script setup lang="ts"></script>

<style scoped lang="scss"></style>
