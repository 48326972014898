<template>
  <div class="one-line-widget">
    <div class="box-inner">
      <div class="avatar-box">
        <NewkiImage :key="avatar" v-if="avatar" :src="avatar" :width="36" :height="36" radius="50%" />
        <img v-else :src="AvatarHolder" :width="36" :height="36" />
      </div>
      <div class="content-container">
        <slot name="ext"></slot>
        <div class="content-box">
          <NewkiSvg class="quote-l" :color="props.color" name="quote-l" />
          <div class="text-body">{{ text }}</div>
          <NewkiSvg class="quote-r" :color="props.color" name="quote-r" />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue'
import NewkiImage from '~/components/NewkiImage.vue'
import AvatarHolder from '~/assets/images/common/newki-logo.svg'

const props = defineProps({
  avatar: {
    type: String,
    required: false,
    default: '',
  },
  text: {
    type: String,
    required: false,
    default: '',
  },
  color: {
    type: String,
    required: false,
    default: '#efeff4',
  },
})
</script>

<style scoped lang="scss">
.one-line-widget {
  width: 100%;
  padding: 0 12px;

  .box-inner {
    @include flex(row, flex-start, flex-start);
    gap: 10px;

    .avatar-box {
      @include flex();
      width: 36px;
      height: 36px;
      background-color: #e1e1e1;
      border-radius: 50%;

      .img {
        border-radius: 50%;
      }
    }

    .content-container {
      @include flex(column, flex-start, flex-start);
    }

    .content-box {
      position: relative;
      z-index: 10;
      padding: 10px 26px;
      border-radius: 12px;
      background-color: $white;
      box-shadow: 0px 4px 10px -1px rgba(0, 0, 0, 0.1), 0px 2px 4px -2px rgba(0, 0, 0, 0.1);

      .text-body {
        @include line(2);
        word-break: break-all;
      }

      .quote-l {
        @include flex();
        position: absolute;
        top: 10px;
        left: 10px;
        z-index: 10;
        width: 12px;
        height: 8px;

        :deep(svg) {
          width: 12px;
          height: 8px;
          margin: 0;
        }
      }

      .quote-r {
        @include flex();
        position: absolute;
        bottom: 10px;
        right: 10px;
        z-index: 10;
        width: 12px;
        height: 8px;

        :deep(svg) {
          width: 12px;
          height: 8px;
          margin: 0;
        }
      }
    }
  }
}
</style>
