import { useSystemStore } from '@/store/system'
export default defineNuxtPlugin((nuxtApp: any) => {
  nuxtApp.hook('app:created', (vueApp: any) => {
    vueApp.$nuxt.$i18n.onLanguageSwitched = (oldLocale: any, newLocale: any) => {
      // 主要用於瀏覽器後退按鈕導致的語言改變
      const { currentSite, currentLanguage, changeLanguageSelect } = useSystemStore()
      if (currentLanguage !== newLocale) {
        changeLanguageSelect(false, newLocale, currentSite)
      }
    }
  })
})
