export default defineAppConfig({
  service: {
    // mall: 'yuako-mall',
    mall: 'mall',
    // mall: 'ycmMall',
    member: 'member',
    support: 'support',
    search: 'search',
    goods: 'goods',
    home: 'homes',
  },
})
