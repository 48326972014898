<template>
  <van-field
    class="input-field"
    name="pwdInput"
    v-model="password"
    :type="showPwd ? 'input' : 'password'"
    :placeholder="t('login.inputPwd')"
    :rules="[{ required: true, message: t('login.inputPwd') }]"
  >
    <template #extra>
      <div class="extra-content">
        <NewkiSvg :name="showPwd ? 'eyes-open' : 'eyes-close'" color="#64748B" @click="changePwdDisplay" />
        <template v-if="props.showFindPwd">
          <div class="divider"></div>
          <span class="find-pwd" @click="findPwd">{{ t('login.findPwd') }}</span>
        </template>
      </div>
    </template>
  </van-field>
</template>

<script setup lang="ts">
const { t } = useI18n()
const props = defineProps({
  showFindPwd: {
    type: Boolean,
    required: false,
    default: false,
  },
})

let password = ref('')
let showPwd = ref(false)

const changePwdDisplay = () => {
  showPwd.value = !showPwd.value
}

const findPwd = () => {
  routerPush('retrievePwd')
}

defineExpose({
  password,
})
</script>

<style scoped lang="scss">
.input-field {
  border: 1px solid $border-color;
  border-radius: 8px;
  margin-top: 26px;
  overflow: unset;

  .van-field__value {
    overflow: unset;
  }
  :deep(.van-field__error-message) {
    position: absolute;
    bottom: -36px;
    left: -10px;
  }
}

.extra-content {
  display: flex;
  align-items: center;

  .divider {
    width: 2px;
    height: 16px;
    margin: 0 10px;
    background-color: $border-color;
  }
  .find-pwd {
    color: $primary;

    &:hover {
      cursor: pointer;
    }
  }
}
</style>
