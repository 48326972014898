<template>
  <div class="van-hairline--surround tag" :class="[type, textSize]" :style="[styleObj]">
    {{ props.text }}
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'

const props = defineProps({
  text: {
    type: String,
    required: true,
  },
  type: {
    type: String,
    default: 'default',
    validator(value: string) {
      return ['default', 'waring', 'success', 'danger', 'promotion', 'vip'].includes(value)
    },
  },
  size: {
    type: String,
    default: 'sm',
    validator(value: string) {
      return ['sm', 'md', 'lg'].includes(value)
    },
  },
  width: {
    type: [String, Number],
    required: false,
    default: 0,
  },
  height: {
    type: [String, Number],
    required: false,
    default: 0,
  },
})

const styleObj = computed(() => {
  if (!props.width || !props.height) return {}
  return {
    width: `${props.width}px`,
    height: `${props.height}px`,
  }
})

const type = computed(() => {
  return {
    info: props.type === 'default',
    waring: props.type === 'waring',
    success: props.type === 'success',
    danger: props.type === 'danger',
    promotion: props.type === 'promotion',
    vip: props.type === 'vip',
  }
})

const textSize = computed(() => {
  return {
    sm: props.size === 'sm',
    md: props.size === 'md',
    lg: props.size === 'lg',
  }
})
</script>

<style scoped lang="scss">
.tag {
  height: 22px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 4px 6px 3px;
  border-radius: 4px;
  line-height: $lineNone;

  &:after {
    border-radius: 8px;
  }
}
.info {
  background-color: $primary-50;
  color: $info-text !important;

  &:after {
    border-color: $info-text;
  }
}
.waring {
  background-color: $waring-bg;
  color: $waring-text !important;

  &:after {
    border-color: $waring-text;
  }
}
.success {
  background-color: $success-bg;
  color: $success-text !important;

  &:after {
    border-color: $success-text;
  }
}
.danger {
  background-color: $danger-bg;
  color: $danger-text !important;

  &:after {
    border-color: $danger-text;
  }
}
.promotion {
  background-color: $discount-tinge;
  color: $price !important;

  &:after {
    border-color: $price;
  }
}
.vip {
  background-color: $vip-bg-tinge;
  color: $vip-text2 !important;

  &:after {
    border-color: $vip-border;
  }
}
.sm {
  font-size: $text-sm !important;
}
.md {
  font-size: $text-md !important;
}
.lg {
  font-size: $text-lg !important;
}
</style>
