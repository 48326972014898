<template>
  <van-popup class="newki-preview-widget" teleport="body" v-model:show="show">
    <div class="preview-box" @click.stop="toggleShow(false)">
      <div class="preview-swiper swiper">
        <div class="swiper-wrapper">
          <NuxtLink class="swiper-slide" v-for="item in data" :to="useContentActionNuxtLink(item)">
            <img :src="item.serverPath" alt="" />
          </NuxtLink>
        </div>
      </div>
    </div>
    <div class="image-indicator" @click.stop="toggleShow(false)">{{ activeIndex + 1 }}/{{ data.length }}</div>
  </van-popup>
</template>

<script setup lang="ts">
import { computed } from 'vue'

import Swiper from 'swiper'
import {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Autoplay,
  Mousewheel,
  EffectCube,
  EffectCoverflow,
} from 'swiper/modules'

import 'swiper/css'
import 'swiper/css/pagination'
import 'swiper/css/scrollbar'
import 'swiper/css/effect-cube'
import 'swiper/css/autoplay'
import 'swiper/css/effect-coverflow'

const emit = defineEmits(['update:modelValue', 'onSlideChange'])

const props = defineProps({
  modelValue: {
    type: Boolean,
    required: true,
  },
  data: {
    type: Array as PropType<any>,
    required: true,
    default() {
      return []
    },
  },
  positionIndex: {
    type: Number,
    required: false,
    default: 0,
  },
})

// Swiper 实例
const swiper = ref({})

const initSwiper = () => {
  swiper.value = new Swiper(`.preview-swiper`, {
    loop: false,
    watchSlidesProgress: true,
    slidesPerView: 'auto',
    centeredSlides: true,
    initialSlide: props.positionIndex,
    modules: [Navigation, Pagination, Scrollbar, A11y, Autoplay, Mousewheel, EffectCube, EffectCoverflow],
    on: {
      init: () => {},
      slideChange: (swiper: any) => {
        activeIndex.value = swiper.activeIndex
        emit('onSlideChange', swiper.activeIndex)
      },
    },
  })
}

const show = computed({
  get() {
    return props.modelValue
  },
  set(value) {
    emit('update:modelValue', value)
  },
})

const activeIndex = ref(props.positionIndex)

watch(
  show,
  async val => {
    activeIndex.value = props.positionIndex
    if (val) {
      await nextTick()
      initSwiper()
    }
  },
  { immediate: true }
)

const toggleShow = (isShow: boolean = !show.value) => {
  show.value = isShow
}
</script>

<style scoped lang="scss">
.newki-preview-widget {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  transform: unset;
  max-width: unset;
  background-color: rgba(0, 0, 0, 0);

  .preview-box {
    @include flex();
    width: 100%;
    height: 100%;
    overflow: hidden;
    .preview-swiper {
      @include flex(column, center, center);
      width: 100%;
      &.swiper {
        width: 100%;
        height: auto;

        .swiper-wrapper {
          width: 100%;
          height: 100%;
          .swiper-slide {
            width: 100%;
            // max-height: 60vh;
            max-height: calc(100vh - 100px);
            margin: auto 0;
            background-color: $white;
            @include cursor();

            img {
              display: block;
              width: 100%;
              height: 100%;
              max-height: calc(100vh - 100px);
              object-fit: contain;
            }
          }
        }
      }
    }
  }
}

.image-indicator {
  @include flex();
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10;
  color: #fff;
  padding: 10px;
}
</style>
