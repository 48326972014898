<template>
  <van-popup
    class="newki-loading"
    v-model:show="show"
    round
    :style="{ padding: '12px 14px', backgroundColor: '#0000008c' }"
    :overlay-style="{ backgroundColor: 'transparent' }"
    :close-on-click-overlay="false"
    :close-on-popstate="true"
    teleport="body"
  >
    <div class="icon-container">
      <NewkiSvg class="animation-blue" name="newki-loading-blue" color=" " :size="36" />
      <NewkiSvg class="animation-green" name="newki-loading-green" color=" " :size="36" />
    </div>
    <div class="loading-text">{{ props.text }}</div>
  </van-popup>
</template>

<script setup lang="ts">
const emit = defineEmits(['update:modelValue'])

const props = defineProps({
  modelValue: {
    type: Boolean,
    required: true,
  },
  text: {
    type: String,
    required: false,
    default: 'Loading...',
  },
})

const show = computed({
  get() {
    return props.modelValue
  },
  set(value) {
    emit('update:modelValue', value)
  },
})

function open() {
  show.value = true
}

function close() {
  show.value = false
}

defineExpose({
  open,
  close,
})
</script>

<style scoped lang="scss">
.icon-container {
  display: flex;
  justify-content: center;
}
.loading-text {
  margin-top: 10px;
  color: $white;
  font-size: 14px;
  text-align: center;
}
$time: 2s;
.animation-blue {
  animation: anBlue $time ease infinite alternate;
}

.animation-green {
  animation: anGreen $time ease infinite alternate;
}

@keyframes anBlue {
  0% {
    color: #00aebb;
  }
  25% {
    color: #33beef;
  }
  50% {
    color: #66cef3;
  }
  75% {
    color: #99dff7;
  }
  100% {
    color: #00aebb;
  }
}

@keyframes anGreen {
  0% {
    color: #cce287;
  }
  25% {
    color: #c4dd73;
  }
  50% {
    color: #bbd85f;
  }
  75% {
    color: #aace37;
  }
  100% {
    color: #cce287;
  }
}
</style>
