<template>
  <div
    class="newki-price-container"
    :style="{
      textDecoration: `${props.through ? 'line-through' : 'none'}`,
      color: `${props.color ? props.color : priceColor}`,
    }"
  >
    <span v-if="isPromotion"> - </span>
    <span
      class="currey"
      :style="{
        fontSize: `${px2rem(props.curreySize)}rem`,
        fontWeight: `${props.bold ? 500 : 400}`,
        marginRight: `${props.distance ? px2rem(props.distance) : 0}rem`,
      }"
      >{{ props.currey }}</span
    >
    <span
      class="price"
      :style="{
        fontSize: `${px2rem(props.priceSize)}rem`,
        fontWeight: `${props.bold ? 500 : 400}`,
        lineHeight: `${props.priceLineHeight ? `${px2rem(props.priceLineHeight)}rem` : 1}`,
      }"
      >{{ numberFormat(props.price) }}</span
    >
  </div>
</template>

<script setup lang="ts">
import { useColorsStore } from '~/store/colors'

const { priceColor } = storeToRefs(useColorsStore())

const props = defineProps({
  // 價格
  price: {
    type: [String, Number],
    required: true,
  },
  // 貨幣符號
  currey: {
    type: String,
    required: false,
    default: '$',
  },
  // 顏色
  color: {
    type: String,
    required: false,
    default: '',
  },
  // 是否加粗
  bold: {
    type: Boolean,
    required: false,
    default: false,
  },
  // 删除线
  through: {
    type: Boolean,
    required: false,
    default: false,
  },
  // 價格大小
  priceSize: {
    type: Number,
    required: false,
    default: 15,
  },
  priceLineHeight: {
    type: Number,
    required: false,
    default: 0,
  },
  // 貨幣符號大小
  curreySize: {
    type: Number,
    required: false,
    default: 15,
  },
  // 货币符号和价格之间的距离
  distance: {
    type: Number,
    required: false,
    default: 0,
  },
  // 是否負數
  isPromotion: {
    type: Boolean,
    required: false,
    default: false,
  },
})
</script>

<style scoped lang="scss">
.newki-price-container {
  display: inline-flex;
  align-items: flex-end;
  line-height: 1;
}
</style>
