export const useColorsStore = defineStore('colors', () => {
  const primary = ref({
    '50': '#00aeeb',
    '100': '#cceffb',
    '150': '#b3e7f9',
    '200': '#99dff7',
    '250': '#80d7f5',
    '300': '#66cef3',
    '350': '#4dc6f1',
    '400': '#33beef',
    '450': '#1ab6ed',
    '500': '#00aeeb',
    '550': '#009dd4',
    '600': '#008bbc',
    '650': '#007aa5',
    '700': '#00688d',
    '750': '#005776',
    '800': '#00465e',
    '850': '#003446',
    '900': '#00232f',
    '950': '#001117',
    default: '#00aeeb',
    gradual: 'linear-gradient(180deg, #33beef 0%, #00aeeb 98%)',
    bg: '#cceffb',
    opacity: '#00aeeb99',
  })

  const secondary = ref({
    '50': '#f7faeb',
    '100': '#eef5d7',
    '150': '#e6f0c3',
    '200': '#ddebaf',
    '250': '#d5e79b',
    '300': '#cce287',
    '350': '#c4dd73',
    '400': '#bbd85f',
    '450': '#b3d34b',
    '500': '#aace37',
    '550': '#99b932',
    '600': '#88a52c',
    '650': '#779027',
    '700': '#667c21',
    '750': '#55671c',
    '800': '#445216',
    '850': '#333e10',
    '900': '#22290b',
    '950': '#111505',
    default: '#aace37',
    gradual: 'linear-gradient(180deg, #bbd85f 0%, #aace37 100%)',
  })

  const info = ref({
    text: '#009dff',
    bg: '#ccebff',
    border: '#009dff',
  })

  const waring = ref({
    text: '#ff9d00',
    bg: '#ffebcc',
    border: '#ff9d00',
  })

  const success = ref({
    text: '#aace37',
    bg: '#ddebaf',
    border: '#aace37',
  })

  const danger = ref({
    text: '#ff3a3d',
    bg: '#ffd8d8',
    border: '#ff3a3d',
    bgDark: '#ff3a3d',
  })

  const discount = ref({
    text: '#8f3537',
    tinge: '#ffebeb',
    bg: 'linear-gradient(270deg, #fd6622 0%, #ff3a3d 100%);',
    border: 'linear-gradient(270deg, #fd6622 0%, #ff3a3d 100%)',
  })

  const vip = ref({
    bg: 'linear-gradient(270deg, #ffc735 0%, #ffe350 100%)',
    border: 'linear-gradient(270deg, #ffc735 0%, #ffe350 100%)',
    text: '#aa6800',
    text2: '#b67e23',
    tinge: '#fffae2',
    back: 'linear-gradient(132deg, #4d5c76 0%, #1f2734 100%)',
    gray: 'linear-gradient(132deg, #1e2034 0%, #4d5b75 100%)',
    borderOrange: '#f59e0b',
  })

  const priceColor = ref('#ff3a3d')

  const bg = ref('#f6f6f6')

  const toast = ref('#00000099')

  const placeholder = ref('#cbd5e1')

  const border = ref('#ebf0ff')

  const mask = ref('#00000059')

  const importance = ref('#000000')

  const textDefault = ref('#111827')

  const textAssist = ref('#64748b')

  return {
    primary,
    secondary,
    info,
    waring,
    success,
    danger,
    discount,
    vip,
    bg,
    toast,
    placeholder,
    border,
    mask,
    importance,
    textDefault,
    textAssist,
    priceColor,
  }
})
